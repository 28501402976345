section.services {
  background-color: #000000;
  width: 100%;
  display: flex;
  overflow-x: hidden;
  position: relative;
  max-height: 100vh;
  padding-top: 30px;
  height: 100% !important;

  &-welcome {
    padding-top: 0;
    min-height: 100%;
    height: 32vw !important;
    margin: 30px 0;

    @media (max-width: 1170px) {
      display: none;
    }

    .services-box-content {
      .box-content-title {
        font-size: 18px;
        padding: 14px;
      }
    }
  }
}

.services-box {
  position: relative;
  overflow: hidden;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  padding: 40px;
  transition: opacity 1s ease, padding .4s ease;
  width: 50%;

  .link-more {
    position: absolute;
    bottom: 50px;
    z-index: 99;
    font-weight: 500;
    color: #F9F8F8;
    border-bottom: 1px dashed #a5a4a4;
    border-top: none;
    border-left: none;
    border-right: none;
    outline: none;
    background: transparent;
    opacity: 0;
    font-size: 16px;
    transition: opacity 0.5s ease;
    padding: 0;
  }

  &:before,
  &:after {
    content: '';
    display: block;
    width: calc(100% - 36px);
    height: calc(100% - 36px);
    max-width: 15px;
    max-height: 15px;
    z-index: 5;
    position: absolute;
    transition: max-width .2s linear .2s, max-height .2s linear .2s;
  }

  &:before {
    border-top: 1px solid #fff;
    border-left: 1px solid #fff;
    top: 18px;
    left: 18px;
  }

  &:after {
    border-bottom: 1px solid #fff;
    border-right: 1px solid #fff;
    bottom: 18px;
    right: 18px;
  }

  &:hover {
    padding: 0;

    &:before,
    &:after {
      max-width: calc(100% - 36px);
      max-height: calc(100% - 36px);
    }

    .services-box-video-wrapper {
      max-width: 100%;
      max-height: 100%;

      &:before {
        max-width: 50vw;
        max-height: 150vh;
      }

      .services-box-video {
        padding: 0;
      }
    }

    .services-box-content {
      max-width: 80%;

      .box-content-text-wrapper {
        display: flex;
        opacity: 1;

        .box-content-text,
        .box-content-button {
          opacity: 1;
          z-index: 3;
        }
      }
    }

    .link-more {
      opacity: 1;
    }
  }

  .services-box-video-wrapper {
    width: 100%;
    min-height: 100%;
    height: 200px;
    overflow: hidden;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      display: block;
      z-index: 3;
      opacity: .55;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      background-color: #000;
      width: 50vw;
      height: 150vh;
      margin-top: 0;
      max-width: 320px;
      transition: max-width .3s ease, max-height .3s ease;
      max-height: 105px;
    }

    .services-box-poster {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .services-box-video {
      height: 100%;
      width: 100%;
      z-index: 2;
      transition: padding .4s ease;
      overflow: hidden;
      object-fit: cover;

      @include media-breakpoint-down(md){
        display: none;
      }
    }
  }

  .services-box-content {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    max-width: 300px;

    .box-content-title {
      z-index: 3;
      font-weight: 200;
      font-size: 24px;
      max-width: 280px;
      margin: 0;
      text-transform: uppercase;
      transition: margin-top .4s ease, opacity .3s ease;
    }

    .box-content-text-wrapper {
      display: none;
      opacity: 0;
      flex-direction: column;
      align-items: center;

      .box-content-text {
        font-weight: 200;
        font-size: 16px;
        line-height: 1.5;
        margin-top: 10px;
        margin-bottom: 0;
      }

      .box-content-button {
        font-size: 12px;
        height: 34px;
        width: auto;
        padding-left: 10px;
        padding-right: 10px;
        margin-top: 17px;
        z-index: 1237;
        position: relative;
      }
    }
  }

  .services-box-link {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 6;

    &:focus {
      background-color: rgba(255, 255, 255, 0.2);
    }
  }
}

.mob-services-box {
  width: 100%;
  background: #000;
  position: relative;
  background-size: cover;
  min-height: 50vh;

  .link-more {
    font-weight: 500;
    color: #F9F8F8;
    border-bottom: 1px dashed #dbdada;
    font-size: 16px;
    margin-top: 25px;
    border-top: none;
    border-left: none;
    border-right: none;
    outline: none;
    background: transparent;
    padding: 0;
  }

  .hide-content-box {
    transform: translateY(300%);

    &.visible {
      transform: translateY(-1px);
    }
  }

  &:before {
    content: '';
    background: rgba(#000, .3);
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  &:first-child {
    background-image: url('/static/images/services-mob_2.webp');
  }

  &:nth-child(2) {
    background-image: url('/static/images/services-mob_1.webp');
  }

  .mob-services-box-content {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5vw 5vw;

    .content-wrapper {
      width: 100%;
      height: 100%;
      border: 1px solid #fff;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 15px;
      min-height: 40vh;
      color: #fff;

      @include media-breakpoint-down(sm) {
        justify-content: space-around;
      }

      .content-title {
        font-size: 40px;

        @include media-breakpoint-down(sm) {
          @include interpolate(font-size, 320px, 767px, 30px, 60px)
        }
      }

      .content-bottom {
        display: flex;
        flex-direction: column;
        align-items: center;

        .content-text {
          margin-top: 30px;
          font-size: 18px;

          @include media-breakpoint-down(sm) {
            @include interpolate(margin-top, 320px, 767px, 20px, 145px);
            @include interpolate(font-size, 320px, 767px, 16px, 30px)
          }
        }

        .content-button {
          width: 80%;
          margin-top: 16px;
          display: inline-flex;
          justify-content: center;
          align-items: center;
        }
      }

      .content-title,
      .content-text {
        font-weight: 200;
        text-align: center;
        margin: 0;
      }
    }
  }
}

.mob-services.main-section {
  .hide-content-box {
    transform: translateY(300%);
    height: 100%;
    transition: all 1s ease;

    &.visible {
      transform: translateY(0);
    }
  }
}


.bottom {
  &_content {
    display: flex;

    &.mobile {
      flex-direction: column;
    }
  }

  &_item {
    width: 50%;
    padding: 15px 20px 20px;

    .mobile & {
      width: 100%;
    }

    h1, h2 {
      color: #e27826;
      font-weight: 500;
      margin: 0 0 20px;
      font-size: 24px;
    }

    h3 {
      font-size: 22px;
      font-weight: 600;
      color: #f9f8f8;
      margin: 0 0 20px;
    }

    h4 {
      color: #e27826;
      font-size: 20px;
      font-weight: 600;
      margin: 0 0 20px;
      line-height: 24px;
    }

    li, p, span {
      font-size: 16px;
      color: #dbdada;
      font-weight: 400;
      line-height: 26px;
      margin-bottom: 20px;
    }
  }
}
