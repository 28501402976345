// styles for section facts
.expertise-internal {
  section.facts {
    .facts-list {
      display: flex;
      flex-wrap: wrap;

      a {
        text-decoration: underline;
      }

      .list-item {
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;

        @include media-breakpoint-down(sm) {
          display: inline-block;
        }

        .list-item-layout {

          .item-heading {
            display: flex;
            align-items: center;
            color: $accent;

            .heading-img {
              span {
                font-size: 40px;
                margin-right: 20px;
                color: $accent;
              }
            }

            .heading-title {
              margin: 0;
              font-size: 20px;
              font-weight: 600;
            }
          }

          .item-description {
            margin-top: 1rem;
          }
        }
      }
    }
  }
}
