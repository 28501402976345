.contacts {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  position: relative;
  overflow-y: hidden;

  &_map {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;

    .map {
      width: 100%;
      height: 100%;
    }
  }

  &_content {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: auto;
  }

  &_bottom {
    margin: 15px 0;
    display: flex;
    justify-content: flex-end;

    .addr-box {
      padding: 10px 20px;
    }
  }
}

.page-content__wrapper.page-content__wrapper__contacts {
  overflow-y: hidden;
}

.addr-boxes__wrapper {
  max-width: 900px;
  margin: 0 auto;

  h1 {
    font-size: 30px;
    text-align: center;
    text-transform: uppercase;
    font-weight: 600;
    margin: 15px 0;
    position: relative;
  }
}

.addr-boxes {
  display: flex;
  justify-content: space-between;
}

.addr-box {
  width: calc((100% / 3) - 20px);
  margin: 0 10px;
  padding: 20px;
  border: 1px solid rgba(#e57f17, 0.5);
  background-color: rgba(#1f1f1f, 0.8);
  position: relative;
  z-index: 2;
  cursor: pointer;
  transition: background-color 0.3s ease, border-color 0.3s ease;

  &.active,
  &:hover {
    .addr-box__img {
      filter: grayscale(0);
    }
  }

  &_departments:hover,
  &_departments.active {
    background-color: #1f1f1f;
    border-color: #e57f17;

    .addr-box__addr,
    .addr-box__tel {
      color: #fff;
    }

    .addr-box__email {
      color: #e27826;
      border-color: #e27826;
    }

    .addr-box__img {
      filter: grayscale(0);
    }
  }

  &_rep {
    width: 350px;
    display: flex;
    flex-direction: column;

    &:hover,
    &.active {
      background-color: #1c1c1c;
      border-color: #e57f17;
    }
  }

  &__links {
    margin-left: 20px;
  }

  &__title {
    font-weight: 200;
    font-size: 22px;
    margin: 0;
  }

  &__item_rep {
    display: flex;
    align-items: center;
    margin-top: 26px;
  }

  &__addr {
    margin-bottom: 0;
    font-size: 16px;
    line-height: 1.2;
    color: rgba(#fff, 0.5);
    transition: color 0.3s ease;

    &:first-of-type {
      margin-top: 26px;
    }
  }

  &__tel,
  &__email {
    font-size: 18px;
    display: block;
    font-weight: 200;
    transition: color 0.3s ease;
    line-height: 1.2;

    &:focus,
    &:active {
      text-decoration: none;
    }
  }

  &__tel {
    margin-top: 15px;
    color: rgba(#fff, 0.5);

    &:focus,
    &:active {
      color: rgba(#fff, 0.5);
    }

    &_rep {
      margin-top: 0;

      &:hover {
        color: #fff;
      }
    }
  }

  &__email {
    color: rgba(#e27826, 0.5);
    display: inline-block;
    border-bottom: 1px solid rgba(#e27826, 0.5);
    transition: border-color 0.3s ease, color 0.3s ease;

    &:focus,
    &:active {
      color: rgba(#e27826, 0.5);
    }

    &_rep {
      &:hover {
        color: #e27826;
      }
    }
  }

  &__img {
    width: 100%;
    max-height: 125px;
    object-fit: cover;
    filter: grayscale(80%);
    transition: filter 0.3s ease;

    &_sm {
      max-height: 42px;
      max-width: 73px;
    }
  }

  &__footer {
    margin-top: 20px;

    .upper {
      text-transform: uppercase;
    }

    .content {
      color: #DBDADA;
    }

    .info {
      font-size: 10px;
      margin-bottom: 5px;

      &:last-child {
        margin: 0;
      }
    }

    p > span {
      &:first-child {
        width: 110px;
        display: inline-block;
        color: #999;
      }

      &:last-child {
        color: #DBDADA;
      }
    }
  }
}

.contacts-mob {
  background: #1f1f1f;
  width: 100%;
  height: 100%;

  .contacts-mob-slider {
    margin-bottom: 0;
  }

  &-slider-item__content {
    width: 100%;
    max-width: 300px;
    position: relative;
    padding: 0 20px;
    margin: 56px auto 0;
  }

  &__addr {
    font-size: 4vw;
    font-weight: 700;
    margin-bottom: 0;
    line-height: 1.2;
    display: flex;
    justify-content: space-between;
    color: #fff;

    &:active,
    &:focus,
    &:hover {
      color: #fff;
      outline: none;
    }

    &:first-of-type {
      border-bottom: 1px solid #fff;
      margin-top: 20px;
    }
  }

  &__img {
    width: 100%;
  }

  &__tel,
  &__email {
    display: block;
    font-size: 4.8vw;

    &:active,
    &:focus,
    &:hover {
      outline: none;
      text-decoration: none;
    }
  }

  &__tel {
    margin-top: 30px;
    color: #fff;

    &:active,
    &:focus,
    &:hover {
      color: #fff;
    }
  }

  &__email {
    color: #e27826;

    &:active,
    &:focus,
    &:hover {
      color: #e27826;
    }
  }
}

.business_info {
  display: flex;

  span {
    font-size: 10px;

    &:first-child {
      color: #999;
      min-width: 110px;
    }
  }
}
