
.icon {
  position: absolute;
  top: 70%;
  left: 70%;
  transform: translateX(-50%) translateY(-50%);
}


.menu_circle {
  pointer-events: none;
}

.menu--circle {
  position: absolute;
  right: 0;
  z-index: 100;
  width: 243.33333333333334px;
  height: 243.33333333333334px;
  background-color: transparent;
  overflow: hidden;
  transform: rotate(90deg);
  display: none;

  @include media-breakpoint-down(sm) {
    display: block;
  }

  @include media-breakpoint-down(xs) {
    display: none;
  }
}

.circle-close {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 20px;
  font-weight: bold;
  opacity: 0;
  transition: opacity .5s ease;
}

.menu__active {
  position: relative;
}

.menu__toggle {
  z-index: 11;
  position: absolute;
  top: -115px;
  left: -115px;
  width: 200px;
  height: 200px;
  background-color: transparent;
  border-radius: 50%;;
  cursor: pointer;
  transition: transform 1.3s, border-width .3s  ;
  pointer-events: auto;


}

.menu__listings {
  z-index: 10;
  position: absolute;
  top: -200px;
  left: -200px;
  width: 380px;
  height: 380px;
  border-radius: 50%;
  transform: scale(0.5) rotate(150deg);
  transition: transform 1s, opacity 1s .4s ;
}

.menu__arrow {
  visibility: hidden;
  position: absolute;
}

.menu__active input[type="radio"] {
  display: none;
}
.menu__arrow input[type="radio"] {
  position: fixed;
  top: -99999px;
  left: -99999px;
}

.menu__arrow ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.menu__arrow--top {
  top: 0.5em;
  display: none;
  left: 220px;
}

.menu__arrow--top .arrow {
  transform: rotate(-45deg);
}

.menu__arrow--left {
  top: 220px;
  left: 0.5em;
}

.menu__arrow--left .arrow {
  transform: rotate(-135deg);
}

.arrow {
  width: 20px;
  height: 20px;
  border-right: 6.666666666666667px solid #fff;
  border-top: 6.666666666666667px solid #fff;
  border-radius: 3px;
  transition: border-color 0.3s;
  cursor: pointer;
  pointer-events: auto;
}

.arrow:hover {
  border-color: #ff947f;
  transition: border-color 0.3s;
}

.circle {
  position: relative;
  padding: 0;
  margin: 0;
  height: 95%;
  width: 95%;
  background-color: transparent;
  border-radius: 50%;
  transform: rotate(108deg);
  overflow: hidden;
  list-style: none;

  &:after {
    position: absolute;
    padding: 0;
    margin: 0;
    content: '';
    height: 100%;
    width: 100%;
    border: 200px solid rgba( white, .5);
    border-radius: 50%;
    transform: rotate(108deg);
    overflow: hidden;
    list-style: none;
    z-index: 0;
    transition:  border-width 1s .3s ease;
  }
}

.circle-item {
  width: 35px;
  height: 35px;
}

.circle li {
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  z-index: 5;
  height: 50%;
  transform-origin: 0 100%;
}

.circle li .placeholder {
  position: absolute;
  left: -100%;
  padding-top: 1.5em;
  width: 200%;
  height: 200%;
  text-align: center;
  transform: skewY(54deg) rotate(18deg);
}

.circle li .placeholder .upside {
  transform: rotate(180deg);
}

.circle li .placeholder a {
  text-decoration: none;
  pointer-events: auto;

  span {
    color: #fff;
    font-size: 36px;
  }
}

.circle li .placeholder button {
  pointer-events: auto;
}

.circle li .placeholder .button {
  font-size: 2.3em;
  background-color: transparent;
  border: none;
  color: #392338;
  cursor: pointer;
}

.circle-share {
  color: #000;
  font-size: 22px;
  margin-top: -10px;
  transition: opacity .5s ease;
}

.circle li:nth-child(1) {
  transform: rotate(25deg) skewY(-54deg);
  background-color: transparent;
}

.circle li:nth-child(2) {
  transform: rotate(6.3deg) skewY(-54deg);
  background-color: transparent;
}

.circle li:nth-child(3) {
  transform: rotate(-12.76deg) skewY(-54deg);
  background-color: transparent;
}

.circle li:nth-child(4) {
  transform: rotate(-32deg) skewY(-54deg);
  background-color: transparent;
}




#menu__active {
  position: fixed;
  top: -99999px;
  left: -99999px;
}

#menu__active:checked ~ label .menu__listings {
  transform: rotate(10deg) scale(1);
  transition: transform 1s;
  opacity: 1;
}

#menu__active:checked ~ label .menu__toggle {
  background-color: transparent;
  transition: transform 1s;
}

#menu__active:checked ~ label .hamburger {
  border-color: transparent;
  transition: border-color 0.333333333333333s;
}

#menu__active:checked ~ label .circle-close {
  opacity: 1;
  transition: opacity .5s ease .3s;
}

#menu__active:checked ~ label .circle:after {
  border-width:  80px;
  transition:  border-width 0s ;
}

#menu__active:checked ~ label .circle-share {
  opacity: 0;
  transition: opacity 0s ease ;
}

#menu__active:checked ~ label .hamburger:after {
  top: -2px;
  border-color: #fff;
  transform: translateX(-50%) rotate(45deg);
  transition-property: top, transform;
  transition-duration: 0.25s, 1s;
}

#menu__active:checked ~ label .hamburger:before {
  bottom: -2px;
  border-color: #fff;
  transform: translateX(-50%) rotate(-45deg);
  transition-property: bottom, transform;
  transition-duration: 0.25s, 1s;
}

#menu__active:checked ~ label .button:hover {
  color: #c1264e;
}

#menu__active:checked ~ label .menu__arrow {
  visibility: visible;
  transition: all 1s 1.111111111111111s;
}

#menu__active:checked ~ label .menu__arrow--top label {
  display: none;
}

#menu__active:checked ~ label .menu__arrow--top label[for="degree--up-0"] {
  display: block;
}

#menu__active:checked ~ label #degree--up-0:checked ~ .menu__listings {
  transform: rotate(116deg);
}

#menu__active:checked ~ label #degree--up-0:checked ~ .menu__arrow--top label {
  display: none;
}

#menu__active:checked ~ label #degree--up-0:checked ~ .menu__arrow--top label[for="degree--up-1"] {
  display: block;
}

#menu__active:checked ~ label #degree--up-1:checked ~ .menu__listings {
  transform: rotate(224deg);
}

#menu__active:checked ~ label #degree--up-1:checked ~ .menu__listings ~ .menu__arrow--top label {
  display: none;
}

#menu__active:checked ~ label #degree--up-1:checked ~ .menu__listings ~ .menu__arrow--top label[for="degree--up-2"] {
  display: block;
}