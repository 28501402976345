.not-found {
  background-color: #000;
  width: 100%;
  height: 100vh;
  background-image: url('/static/images/not-found_desktop.webp');
  background-size: 100% auto;
  background-position: center bottom;
  background-repeat: no-repeat;
  overflow: hidden;

  @include media-breakpoint-down(sm) {
    background-image: url('/static/images/not-found_mobile.webp'), url('/static/images/not-found_orange.webp');
    background-position: center 80%, center;
    height: 100vh !important;
    background-size: calc(100% - 40px) auto, cover;
  }

  &__content {
    margin-top: 12vh;

    @include media-breakpoint-down(sm) {
      top: 14%;
      position: absolute;
      margin-top: 0;
      width: 100%;
    }
  }
  
  &__text {
    font-weight: 200;
    font-size: 40px;
    text-align: center;
    background-color: rgba(#000, .5);
    margin-bottom: 0;
    margin-top: 0;
    line-height: 1.2;

    @include media-breakpoint-down(sm) {
      background-color: transparent;
      font-size: 9.3vw;
    }
  }
}