// styles for section testimonials
.technology {
  section.testimonials {
    background-color: #fff;

    > .section-title {
      color: #000;

      @include media-breakpoint-down(sm) {
        font-size: calc(4.9217vw + 10.25056px);
      }
    }

    section.testimonials-slider {
      padding: 0;

      @include media-breakpoint-down(sm) {
        padding: 1rem 0 3rem;
      }
    }
  }
}
