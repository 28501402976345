// styles for section why-we-are
.technology {
  section.steps {

    .content-block {
      z-index: 1;
      position: relative;
    }

    a {
      color: $accent;
      text-decoration: underline;
    }

    .bg-list {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      padding: 0;
      margin: 0;

      @include media-breakpoint-down(sm) {
        flex-direction: column;
      }

      & > li {
        padding: 0 15px;
        counter-increment: bg-item;
        position: relative;
        width: 33.3333%;
        padding-top: 50px;

        @include media-breakpoint-down(sm) {
          width: 100%;
        }

        &:after {
          content: counter(bg-item);
          position: absolute;
          left: 13.5%;
          font-size: 300px;
          color: rgba(255, 255, 255, .05);
          font-weight: bold;
          font-style: italic;
          top: -110px;
        }

        .item-title {
          max-width: 250px;
          width: 100%;
          display: block;
          position: relative;
          margin: 0 auto 25px;

          @include media-breakpoint-down(sm) {
            margin: 0 auto 25px auto;
          }

          img {
            width: 100%;
            height: auto;
          }

          span {
            position: absolute;
            padding: 5px;
            top: 5px;
            bottom: 33%;
            left: 9px;
            right: 7px;
            color: $brand-primary;
            font-size: 16px;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            @include media-breakpoint-down(md) {
              font-size: 12px;
            }

            @include media-breakpoint-down(sm) {
              font-size: 16px;
            }
          }
        }

        .item-description {
          font-size: 20px;
          max-width: 400px;
          width: 100%;
          text-align: center;
          margin: 0 auto;
        }

        .simple-icon-list {
          padding: 0;
          margin: 20px 0 0 0;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          align-items: center;
          justify-content: center;

          li {
            padding: 0 5px;
          }
        }

        .icon-etsy {
          font-size: 22px;
          color: #e5e2df;
        }

        .icon-yahoo {
          color: #e5e2df;
          font-size: 17px;
        }
      }
    }
  }
}
