.breadcrumbs-wrapper~.popup__wrapper {
  margin-top: 0;
}

.breadcrumbs-wrapper ~ div {
  margin-top: 50px;

  a {
    &:focus,
    &:hover,
    &:active,
    &:focus:active {
      text-decoration: underline;
    }
  }

  > * {
    font-size: 18px;
  }

  li a {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  ul {
    list-style-type: none;
  }
  & > ul {

    & > li {

      padding: 10px 0;

      & > a {
        font-size: 18px;
        color: #e27826;
      }

      span ~ ul ~ ul, span ~ ul {
        * {
          font-size: 16px;
          opacity: .7;
        }
      }
      ul {
        padding-top: 5px;
        padding-bottom: 5px;

        & > li > a {
          color: #dedede;
          font-size: 16px;
        }
      }
    }
  }
}

.breadcrumbs_landing_page ~ div {
  margin-top: 0;
}