.technology section.content-hero {
  position: relative;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 15px 25%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left center;
  margin-top: 70px;

  @include media-breakpoint-down(md) {
    padding: 15px 23%;
  }

  p {
    font-size: 18px;
  }

  @include media-breakpoint-down(sm) {
    padding: 30px 15px 0 15px;
    margin-top: 40px;
  }

  .content-hero-block {
    z-index: 2;
    position: relative;
    text-align: center;

    @include media-breakpoint-down(sm) {
      margin-bottom: 40px;
      margin-top: 20px;
      text-align: left;
    }

    a {
      @include media-breakpoint-down(sm) {
        width: 100%;
      }
    }
  }

  .img-logo {
    position: absolute;
    top: 30px;
    left: 50px;
    z-index: 1;

    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  .img-right,
  .img-left {
    position: absolute;
    max-width: 30%;

    @include media-breakpoint-down(sm) {
      position: relative;
      bottom: auto;
      left: auto !important;
      right: auto !important;
      max-width: 300px;
      height: auto !important;
    }
  }

  .img-left {
    left: 0;
    bottom: 0;
  }
}
.technology.hirephpdeveloper {
  section.content-hero {
    .img-right {
      right: -50px;
      bottom: 0;

      @include media-breakpoint-down(sm) {
        transform: translate(50px, 0);
      }
    }
  }
}

.technology.hireangulardeveloper {
  section.content-hero {
    background-color: #181D29;
    background-image: url(/static/images/angular/hero-bg.webp);

    @include media-breakpoint-down(sm) {
     background-image: none;
    }

    .img-right {
      right: 50px;
      max-width: 300px;

      @include media-breakpoint-down(sm) {
        bottom: 20px;
      }
    }

    .img-left {
      display: none;

      @include media-breakpoint-down(sm) {
        display: block;
      }
    }
  }
}

.technology.hireflutterdeveloper {
  section.content-hero {
    background-color: #1E7FC9;
    background-image: url(/static/images/flutter/hero-bg.webp);

    @include media-breakpoint-down(sm) {
     background-image: none;
    }

    .img-right {
      right: 50px;

      @include media-breakpoint-down(sm) {
        bottom: 20px;
      }
    }
    .img-left {
      display: none;

      @include media-breakpoint-down(sm) {
        display: block;
      }
    }
  }
}
