.expertise-internal section.content-hero {
  position: relative;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 15px 25%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left center;
  overflow: hidden;
  margin-top: 70px;

  @media screen and (max-width: 1300px) {
    padding: 15px 30%;
  }

  @include media-breakpoint-down(md) {
    padding: 15px 23%;
  }

  p {
    font-size: 20px;
  }

  @include media-breakpoint-down(sm) {
    padding: 30px 15px 0 15px;
    margin-top: 40px;
  }

  .content-hero-block {
    z-index: 2;
    position: relative;
    text-align: center;

    @include media-breakpoint-down(sm) {
      margin-bottom: 40px;
      margin-top: 40px;
    }

    p {
      @media screen and (max-width: 1600px) {
        font-size: 16px;
        line-height: 1.6;
      }
    }

    a {
      @include media-breakpoint-down(sm) {
        width: 100%;
      }
    }
  }

  .img-logo {
    position: absolute;
    top: 30px;
    left: 50px;
    z-index: 1;

    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  .img-right,
  .img-left {
    position: absolute;
    max-width: 30%;

    @include media-breakpoint-down(sm) {
      position: relative;
      bottom: auto;
      left: auto !important;
      right: auto !important;
      max-width: 300px;
      height: auto !important;
    }
  }

  .img-left {
    left: 0;
    bottom: 0;
  }
}

.expertise-internal.digital-healthcare {
  section.content-hero {
    background-color: #0D4E8A;

    @include media-breakpoint-down(md) {
      padding: 120px 20px 150px;
    }

    @include media-breakpoint-down(sm) {
      background-image: none;
    }

    .img-right,
    .img-left {
      max-width: 35%;

      @include media-breakpoint-down(sm) {
        max-width: none;
        position: absolute;
      }
    }

    .img-left {
      @include media-breakpoint-down(sm) {
        max-height: 25%;
        left: 0 !important;
      }
    }

    .img-right {
      right: 0;
      top: 0;

      @include media-breakpoint-down(sm) {
        right: 0 !important;
      }
    }

    .content-hero-block {
      @include media-breakpoint-down(sm) {
        margin-top: 100px;
      }
    }
  }
}

.expertise-internal.business-automation {
  section.content-hero {
    background: radial-gradient(49.86% 1231.34% at 49.86% 50.61%, #0D7276 0%, #130829 100%);

    .img-right,
    .img-left {
      max-width: 50%;
      top: 0;

      @include media-breakpoint-down(sm) {
        max-width: none;
        position: absolute;
        height: 50% !important;
      }
    }

    .img-left {
      @include media-breakpoint-down(sm) {
        top: -10%;
      }
    }

    .img-right {
      right: 0;

      @include media-breakpoint-down(sm) {
        top: initial;
        bottom: -25%;
      }
    }

    .content-hero-block {
      @include media-breakpoint-down(sm) {
        margin-bottom: 200px;
        margin-top: 200px;
      }
    }
  }
}

.expertise-internal.advertising-marketing {
  section.content-hero {
    overflow: visible;
    background: linear-gradient(101.36deg, #005AAA 0.6%, #45007F 98.28%);

    .img-right,
    .img-left {
      max-width: 50%;

      @include media-breakpoint-down(sm) {
        max-width: none;
        position: absolute;
      }
    }

    .img-left {
      bottom: -30px;

      @include media-breakpoint-down(sm) {
        left: 0 !important;
        top: -30px;
      }
    }

    .img-right {
      right: 0;
      bottom: -50px;

      @include media-breakpoint-down(sm) {
        right: 0 !important;
      }
    }

    .content-hero-block {
      @include media-breakpoint-down(sm) {
        margin-bottom: 300px;
        margin-top: 300px;
      }
    }
  }
}

.expertise-internal.hospitality-tourism {
  section.content-hero {
    background: linear-gradient(100.69deg, #0CD2F0 0.14%, #000A7F 99.64%);

    @include media-breakpoint-down(md) {
      padding: 120px 20px 150px;
    }

    .img-right,
    .img-left {
      @include media-breakpoint-down(sm) {
        position: absolute;
      }
    }

    .img-left {
      bottom: auto;
      left: 30px;
      max-height: 250px;

      @media screen and (max-width: 1600px) {
        max-height: 180px;
      }

      @include media-breakpoint-down(md) {
        bottom: 0;
      }

      @include media-breakpoint-down(sm) {
        max-height: 150px;
        bottom: auto;
        top: 10px;
      }
    }

    .img-right {
      right: 30px;
      max-height: 270px;

      @media screen and (max-width: 1600px) {
        max-height: 180px;
      }

      @include media-breakpoint-down(md) {
        bottom: 0;
      }

      @include media-breakpoint-down(sm) {
        max-height: 150px;
      }
    }
  }
}

.expertise-internal.wellness-fitness-development {
  section.content-hero {
    background: linear-gradient(100.69deg, #E1158F 0.14%, #FFA658 99.64%);

    @include media-breakpoint-down(md) {
      padding: 120px 20px 150px;
    }

    .img-right,
    .img-left {
      max-width: 50%;

      @include media-breakpoint-down(sm) {
        position: absolute;
      }
    }

    .img-left {
      bottom: 10px;
      left: 50px;
      max-height: 200px;

      @media screen and (max-width: 1600px) {
        left: 20px;
        max-height: 150px;
      }

      @include media-breakpoint-down(md) {
        max-height: 110px;
      }

      @include media-breakpoint-down(sm) {
        top: 25px;
      }
    }

    .img-right {
      right: 50px;
      bottom: 10px;
      max-height: 280px;

      @media screen and (max-width: 1600px) {
        max-height: 200px;
        right: 20px;
      }

      @include media-breakpoint-down(md) {
        max-height: 150px;
      }
    }
  }
}

.expertise-internal.iot {
  section.content-hero {
    background: linear-gradient(100.69deg, #6F1ABA 0.14%, #9A554B 47.82%, #FFC736 99.64%);;

    @include media-breakpoint-down(md) {
      padding: 120px 20px 150px;
    }

    .img-right,
    .img-left {
      max-width: 50%;

      @include media-breakpoint-down(sm) {
        position: absolute;
      }
    }

    .img-left {
      bottom: 6px;
      left: 90px;
      max-height: 230px;

      @media screen and (max-width: 1600px) {
        left: 40px;
        max-height: 180px;
      }

      @include media-breakpoint-down(md) {
        max-height: 150px;
      }

      @include media-breakpoint-down(sm) {
        top: 10px;
      }
    }

    .img-right {
      right: 40px;
      max-height: 270px;

      @media screen and (max-width: 1600px) {
        max-height: 190px;
        right: 20px;
      }

      @include media-breakpoint-down(md) {
        bottom: 10px;
        max-height: 150px;
      }

      @include media-breakpoint-down(sm) {
        bottom: 20px;
      }
    }

    .content-hero-block {

      p {
        margin-bottom: 30px;
      }

      a {
        min-width: 200px;
      }
    }
  }
}

.expertise-internal.hipaa-landing {
  .landing_wrap {
    padding-left: 40px;
    padding-right: 40px;
    @media all and (max-width: 768px) {
      padding: 0 15px;
    }

    &_top {
      @media all and (max-width: 768px) {
        margin-top: 170px;
      }
    }
  }

  .tabs {
    .tab {
      a {
        &:hover {
          text-decoration: none;
        }
      }
    }
  }

  .page-title {
    padding: 0 15px;
    margin-top: 40px;
  }

  section.content-hero {
    display: flex;
    background: #2C3462;
    @media all and (max-width: 768px) {
      padding: 40px 15px;
    }

    img {
      top: 50%;
      transform: translateY(-50%);

      &.img-left {
        left: 2.8vw;
        width: 20%;
      }

      &.img-right {
        right: 2.8vw;
        width: 20%;
      }

      @media all and (max-width: 768px) {
        top: 0;
        transform: none;
        &.img-left {
          width: auto;
        }
        &.img-right {
          width: auto;
        }
      }
    }

    p {
      font-weight: 600;
      font-size: 18px;
      line-height: 1.22;
      margin-bottom: 0;
    }

    .btn {
      padding: 10px 45px !important;

      span {
        font-weight: 600;
      }

      @media all and (max-width: 768px) {
        width: auto;
      }
    }
  }

}
