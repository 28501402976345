//VIDEO ICON IN OPERA
body ~ div {
  display: none !important;
}

a {
  &:focus,
  &:hover,
  &:active,
  &:focus:active {
    outline: none;
    text-decoration: none;
  }
}

.cache-image {
  visibility: hidden;
  opacity: 0;
  height: 0;
  width: 0;
  position: absolute;
}

.page-content__wrapper {
  height: 100%;
  width: 100%;
  position: relative;
  overflow: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;

  &_blog {
    overflow-y: hidden;

    @include media-breakpoint-down(md) {
      overflow-y: auto;
    }

    &-internal {
      height: auto;
    }
  }

  @include media-breakpoint-down(sm) {
    overflow-y: hidden;
  }
}

html {
  height: 100%;

  @include media-breakpoint-down(sm) {
    height: auto;
  }
}

body {
  color: #fff;
  overflow-x: hidden;
  width: 100vw;
  min-height: 90vh !important;
  height: 100%;
  overflow-y: auto;
  background-color: #000 !important;
  font-family: 'Montserrat', sans-serif;

  @include media-breakpoint-down(sm) {
    height: auto;
  }

  &::-webkit-scrollbar {
    width: 10px;
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $accent;
  }

  &::-webkit-scrollbar-track {
    width: 30px;
    background-color: black;
  }

  @include media-breakpoint-down(sm) {
    overflow-y: auto;
  }
}

.title {
  font-weight: 200;
  font-size: 25px;
}

.flex-container {
  display: flex;

  &_column-md {
    @include media-breakpoint-down(md) {
      flex-direction: column;
      align-items: center;
    }
  }

  &_column-sm {
    @include media-breakpoint-down(sm) {
      flex-direction: column;
      align-items: center;
    }
  }
}

.firstLetter {
  &-pseudo {
    &:first-letter {
      /* Style for "L" */
      color: #e27826;
      font-family: 'Philosopher', serif;
      @include interpolate(font-size, 768px, 1920px, 44px, 56px);
      vertical-align: bottom;
      font-weight: 700;
      line-height: 0.98;
      padding-right: 3px;
      text-transform: uppercase;
    }
  }
}

button.btn {
  display: block;
}

a.btn {
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn {
  width: 136px;
  height: 47px;
  min-height: 35px;
  padding: 0;
  text-transform: uppercase;
  font-size: 14px;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.23);
  color: #9a4d10;
  background-color: $accent;
  background-image: repeating-radial-gradient(
    ellipse farthest-corner at center center,
    rgb(240, 166, 54) 0%,
    rgb(226, 121, 38) 100%
  );
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 0 5px 3px #904d18;
  transition: box-shadow 0.3s ease, color 0.3s ease, background-color 0.3s ease;

  &:hover,
  &:focus,
  &:active,
  &:focus:active {
    box-shadow: 0 0 8px 5px #904d18;
    outline: none;
    color: #9a4d10;
    background-image: repeating-radial-gradient(
      ellipse farthest-corner at center center,
      rgb(240, 166, 54) 0%,
      rgb(226, 121, 38) 100%
    );
  }

  &_outline {
    background: transparent;
    color: #fff;
    box-shadow: none;
    border-radius: 0;
    border: 1px solid #fff;
    transition: color 0.3s ease, border-color 0.3s ease, background-color 0.3s ease;

    &:hover {
      outline: none;
      background: #fff;
      color: $accent;
      box-shadow: none;
      border-radius: 0;
      border: 1px solid #fff;
    }

    &:focus,
    &:active,
    &:focus:active {
      outline: none;
      background: transparent;
      color: #fff;
      box-shadow: none;
      border-radius: 0;
      border: 1px solid #fff;
    }
  }

  &_discuss {
    width: 140px;
    height: 40px;
    font-weight: 600;
    line-height: 1.214;
    border-radius: 3px;
    border: 2px solid #e27826;
    background: transparent;
    color: #fff;
    box-shadow: none;
  }

  &_discuss-form {
    width: auto;
    padding: 12px 22px;
    border-radius: 3px;
    color: #ffffff;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: 14px;
    border: 2px solid #e27826;
    letter-spacing: 0.02em;
    background-image: linear-gradient(269.72deg, #d96600 0.19%, #f39f33 99.7%);
    box-shadow: none;

    &:hover {
      color: #ffffff;
      box-shadow: none;
      background: rgba(226, 120, 38, 0.2);
    }
  }

  &_readmore {
    position: relative;
    display: inline-block;
    text-decoration: underline;
    font-weight: bold;
    @include interpolate(font-size, 320px, 767px, 18px, 36px);

    &:focus,
    &:active,
    &:hover {
      color: #fff;
      background-color: transparent;
    }
  }
}

.clearfix:after {
  content: '';
  display: table;
  clear: both;
}

.fullwidth {
  width: 100% !important;
}

.align-center {
  align-items: center !important;
}

.page-content {
  display: flex;
  align-items: stretch;
  width: 100vw;
  height: 100%;
  overflow: hidden;
  background-color: #000;
}

.main-section {
  height: auto !important;
  min-height: 100% !important;
}

.main-section__content {
  width: 100%;
  height: 100%;

  @include media-breakpoint-down(sm) {
    padding-top: 90px;
  }

  &--simple {
    font-weight: 400;
    font-family: Calibri, sans-serif;
    font-size: 14px;
    line-height: 1.6;

    a {
      color: $btn-default-color;

      &:hover {
        color: darken($btn-default-color, 20%);
      }
    }

    p {
      margin-bottom: 0.5em;
    }

    ul {
      margin-bottom: 0.5em;
    }

    .h1,
    .h2,
    .h3,
    .h4 {
      font-weight: 100;
      margin-top: 2em;
      margin-bottom: 0.5em;
    }

    .h1,
    .h2,
    .h4 {
      color: lighten($btn-default-color, 20%);
    }

    .h1 {
      font-size: 21px;
    }

    .h2 {
      font-size: 18px;
    }

    .h3 {
      font-size: 15px;
    }

    .h4 {
      font-size: 14px;
      font-style: italic;
    }
  }
}

.breadcrumbs-wrapper + .blog-int .main-section__content {
  @include media-breakpoint-down(sm) {
    padding-top: 160px;
  }
}

.c-accent {
  color: $accent;
}

.underlined {
  text-decoration: underline;
}

.c-white {
  color: #fff;
}

.uppercase {
  text-transform: uppercase;
}

.scroll-nice {
  @include media-breakpoint-up(md) {
    &::-webkit-scrollbar {
      width: 7px;
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $accent;
    }
  }

  &_white {
    @include media-breakpoint-up(md) {
      &::-webkit-scrollbar {
        width: 5px;
        background: transparent;
      }

      &::-webkit-scrollbar-thumb {
        background-color: rgba(#fff, 0.5);
      }
    }
  }
}

.gradient_default {
  background-image: linear-gradient(to left, #d96600 0%, #f39f33 100%);
}

.mb30 {
  margin-bottom: 30px;
}

.mt30 {
  margin-top: 30px;
}

.hidden-desktop {
  display: none;

  @include media-breakpoint-down(sm) {
    display: block;
  }
}

a.c-accent.underlined {
  transition: color 0.3s ease;

  &:focus,
  &:hover,
  &:active,
  &:focus:active {
    color: darken($accent, 10%);
  }
}

.btn.active,
.btn:active {
  outline: none !important;
}

.hide-content-box {
  padding: 60px 35px;
  background: #161616;
  position: absolute;
  z-index: 999;
  top: 0;
  height: 100vh;
  overflow: auto;
  transform: translateY(100%);
  transition: all 0.5s ease;

  @include media-breakpoint-down(sm) {
    position: fixed;
  }

  &.visible {
    transform: translateY(0);
  }

  @include media-breakpoint-down(sm) {
    padding: 30px 20px 50px;
  }

  h1, h2 {
    color: #E27826;
    font-weight: 500;
    margin: 0 0 20px;
    font-size: 24px;

    @include media-breakpoint-down(md) {
      max-width: 90%;
    }
  }

  p, span, li {
    font-size: 16px;
    color: #DBDADA;
    font-weight: 400;
    line-height: 26px;
    margin-bottom: 20px;
  }

  h3 {
    font-size: 22px;
    font-weight: 600;
    color: #F9F8F8;
    margin: 0 0 20px;
  }

  h4 {
    color: #e27826;
    font-size: 20px;
    font-weight: 600;
    margin: 0 0 20px;
    line-height: 24px;
  }
}