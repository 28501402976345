.cookies-popup {
	position: fixed;
	bottom: 0;
	left: 50%;
	z-index: 999;
	transform: translateX(-50%);
	display: flex;
	align-items: center;
	width: 100%;
	max-width: 1595px;
	padding: 29px 30px;
	background-image: linear-gradient(269.72deg, #D96600 0.19%, #F39F33 99.7%);

	@media screen and (max-width: 767px) {
		flex-wrap: wrap;
	}

	&__visual {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;
		width: 220px;
		height: 100%;
		background-repeat: no-repeat;
		background-size: contain;
		background-position: center;

		@media screen and (max-width: 1023px) {
			display: none;
		}
	}

	&__text {
		margin: 0 30px 0 190px;
		color: #fff;
		font-weight: 600;
		font-size: 18px;
		line-height: 22px;

		@media screen and (max-width: 1023px) {
			font-size: 16px;
			margin-left: 0;
		}

		@media screen and (max-width: 767px) {
			width: 100%;
			margin: 0 0 20px;
		}
	}

	&__link {
		color: #fff;
		text-decoration: underline;
		transition: color .3s;
	}

	&__btn {
		flex: 0 0 220px;
	}
}
