// styles for section timeline
section.timeline-section {

  .section-title {
    color: #000;
  }

  .timeline {
    margin: 0;
    padding: 0 15px;
    overflow: hidden;

    @include media-breakpoint-down(sm) {
      overflow: visible;
    }

    &-section {
      background: url("/static/images/php/bg.png");
      background-size: contain;
    }

    li {
      position: relative;
      font-size: 18px;
      padding: 20px 20px 20px 150px;
      counter-increment: item;
      overflow: hidden;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: left;

      &:before,
      &:after {
        content: '';
        position: absolute;
        display: block;
      }

      &:before {
        content: '';
        position: absolute;
        left: 30px;
        top: 0;
        width: 0;
        height: 100%;
        border-left: 1px solid $brand-primary;

        @include media-breakpoint-down(sm) {
          display: none;
        }
      }

      &:after {
        content: '0' counter(item);
        color: #fff;
        font-size: 24px;
        top: 50%;
        left: 0;
        transform: translate(0, -50%);
        text-align: center;
        vertical-align: middle;
        line-height: 60px;
        width: 60px;
        height: 60px;
        border-radius: 60px;
        background: $brand-primary;

        @include media-breakpoint-down(sm) {
          top: 20px;
          left: 50%;
          transform: translate(-50%, 0);
          z-index: 2;
        }
      }

      &:nth-child(9) {
        & ~ li {
          &:after {
            content: counter(item);
          }
        }
      }

      &:first-child {
        &:before {
          top: 50%;
        }
      }

      &:last-child {
        &:before {
          top: -50%;
        }
      }

      &:nth-child(2n+1) {
        justify-content: center;
      }

      &:nth-child(2),
      &:nth-child(4n + 2) {
        justify-content: flex-end;
      }

      &:nth-child(1),
      &:nth-child(4n) {
        justify-content: flex-start;
      }

      @include media-breakpoint-down(md) {
        padding-left: 100px;
      }

      @include media-breakpoint-down(sm) {
        justify-content: center !important;
      }

      @include media-breakpoint-down(sm) {
        overflow: visible;
        padding: 0;
        margin-bottom: 30px;
      }

      p {
        position: relative;
        padding: 0;
        min-height: 260px;
        max-width: 340px;
        border: 1px solid $brand-primary;
        margin: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        box-shadow: 0 5px 25px rgba(0, 0, 0, .35);
        background: #fff;

        @include media-breakpoint-down(sm) {
          max-width: 320px;
          width: 100%;
          height: auto;
        }

        span {
          padding: 15px 60px;
          position: relative;
          z-index: 2;
          text-align: center;
          background: #fff;

          @include media-breakpoint-down(sm) {
            padding: 100px 15px 20px 15px;
          }
        }

        &:before,
        &:after {
          content: '';
          position: absolute;
          display: block;
        }

        &:before {
          width: 100vw;
          right: 0;
          top: 50%;
          height: 0;
          border-top: 1px solid $brand-primary;

          @include media-breakpoint-down(sm) {
            display: none;
          }
        }
      }
    }
  }
}
