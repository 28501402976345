// styles for section report
.technology {
  .report {
    position: relative;
    overflow: hidden;

    .section-title {
      color: #000;
    }

    .report-bg-1 {
      width: 310px;
      height: 310px;
    }

    .report-bg-1,
    .report-bg-2,
    .report-bg-3 {
      position: absolute;
      background-size: contain;
      background-repeat: no-repeat;
    }

    .report-chart {
      max-width: 800px;
      width: 100%;
      margin: 0 auto;

      img {
        max-width: 100%;
        height: auto;
      }
    }
  }
}

.technology.hirephpdeveloper {
  .bg-elephants {
    .report-bg-1 {
      left: 7%;
      top: 55%;
      background-image: url(/static/images/php/logo_php.png);
    }

    .report-bg-2 {
      width: 310px;
      height: 310px;
      left: 70%;
      top: 30%;
      background-image: url(/static/images/php/logo_php.png);
    }

    .report-bg-3 {
      width: 206px;
      height: 206px;
      left: 12%;
      top: 5%;
      background-image: url(/static/images/php/logo_php.png);
    }
  }
}


.technology.hireangulardeveloper {
  .bg-angular {
    .report-bg-1 {
      left: 7%;
      top: 40%;
      background-image: url(/static/images/angular/angular-logo.png);
      opacity: 0.1;
    }

    .report-bg-2 {
      width: 140px;
      height: 140px;
      right: 10%;
      top: 20%;
      background-image: url(/static/images/angular/angular-logo.png);
      opacity: 0.1;
    }
  }
}

.technology.hireflutterdeveloper {
  .bg-flutter {
    .report-bg-1 {
      left: 7%;
      top: 40%;
      background-image: url(/static/images/flutter/flutter-shadow.png);
    }

    .report-bg-2 {
      width: 140px;
      height: 140px;
      right: 10%;
      top: 20%;
      background-image: url(/static/images/flutter/flutter-shadow.png);
    }
  }
}
