$accent: #e27826;
$orbit-start-point: translate(156px, -20px), translate(-125px, - 45px),  translate(-20px, 156px), translate(40px, -156px), translate(-156px, 40px), translate(130px, 88px), translate(-110px, 70px), translate(-50px, -156px), translate(110px, -70px), translate(-121px, -20px), translate(120px, 40px);

$design-box-color:  #232323;
$brand-primary: $accent;

//COMPUTED
$design-box-padding: 42px;
$design-box-padding-sm: 20px;

$grid-gutter-width: 30px;
$grid-columns: 12;
$grid-breakpoints: (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px);
$container-max-widths: (sm: 540px, md: 720px, lg: 960px, xl: 1170px);

$gutter: $grid-gutter-width;

$btn-font-weight: normal;
$btn-default-color: #ec7800;
$btn-default-bg: #fff0e1;
$btn-default-border: transparent;
$btn-link-disabled-color: #dedede;
$btn-border-radius-base: 5px;
$padding-base-horizontal: 40px;
$padding-base-vertical: 10px;
@import "slick_vars";
