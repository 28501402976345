@import "../../mixins/bs_breakpoints.scss";

.additional-section {
    padding: 60px 50px 40px;
    background: #161616;
    background-image: url("/static/images/company.webp");
    background-size: 100%;
    background-repeat: no-repeat;

    @include media-breakpoint-down(md) {
        padding: 30px 20px 50px;
    }

    h1 {
        color: #E27826;
        font-size: 32px;
        font-weight: 600;
        margin: 0 0 20px;
        text-transform: uppercase;
    }

    h2 {
        font-size: 24px;
        color: #fff;
        margin: 0 0 20px;
        text-transform: uppercase;
        font-weight: 500;
    }

    p, li, span {
        color: #E2E2E2;
        font-size: 16px;
        line-height: 26px;
    }

    a {
        color: #E27826;
    }

    ul {
        list-style-type: disc;
        padding-left: 25px;
    }

    .text-block {
        margin-bottom: 50px;

        @include media-breakpoint-down(md) {
           margin-bottom: 30px;
        }

        &:last-child {
            margin: 0;

            > p {
                margin: 0;
            }
        }
    }

    .text_title {
        color: #E27826;
        font-weight: bold;
        display: inline-flex;
        margin-bottom: 0;
        font-size: 16px;
    }
}

.home-section__additional-buttons {

    @include media-breakpoint-down(md) {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
        margin: 10px 0 20px;
    }

    a {
        color: #E27826;
        font-size: 16px;
        font-weight: 500;
        border-bottom: 1px #DBDADA dashed;
        padding-bottom: 4px;

        &:first-child {
            margin-right: 35px;
        }
    }
}

.scroll-back-btn {
    width: 64px;
    height: 64px;
    display: none;
    position: fixed;
    bottom: 20px;
    right: 30px;
    z-index: 99;
    border: none;
    outline: none;
    border-radius: 3px;
    padding: 0;

    &_wrap {
        border-radius: 2px;
        padding: 1px;
        width: 100%;
        height: 100%;
        position: relative;
        background: linear-gradient(70deg, #222222 0%,#2c2c2c 4%,#e7841b 40%,#2c2c2c 66%,#222222 100%);
    }

    &_inner {
        background: linear-gradient(101.87deg, #313131 6.37%, #242424 88.98%);
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 2px;
    }

    span {
        transform: rotate(90deg) translateX(0);
        display: inline-block;
        color: #e27826;
    }
}

.global-additional-buttons {
    position: absolute;
    bottom: 35px;

    a {
        color: #F9F8F8;
        font-size: 16px;
        font-weight: 500;
        border-bottom: 1px #DBDADA dashed;
        padding-bottom: 5px;

        &:first-child {
            margin-right: 30px;
        }
    }
}

.text_italic {
    font-style: italic;
}
