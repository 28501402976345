.chatgpt {
    &_typing {
        display: none;
        margin: 10px 0;

        &.visible {
            display: flex;
            align-items: center;
        }

        .chatgpt_message {
            display: flex;
            align-items: center;
            justify-content: center;

            span {
                display: flex;
                width: 4px;
                height: 4px;
                border-radius: 50%;
                background: #999999;
                margin: 0 4px;

                &:first-child {
                    animation: 1s linear 0s infinite typing;
                }

                &:nth-child(2) {
                    animation: 1s linear .8s infinite typing;
                }

                &:last-child {
                    animation: 1s linear 1.6s infinite typing;
                }
            }
        }
    }

    &_item {
        display: flex;
        align-items: flex-start;
        width: 100%;
        margin: 10px 0;
    }

    &_time {
        color: #252525;
        font-size: 10px;
        margin-top: 5px;
    }

    &_icon {
        border: 2px solid #FD9F28;
        border-radius: 50%;
    }

    &_message {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .chatgpt_text {
            display: inline-block;
            margin: 0;
            min-height: 35px;
            padding: 10px 15px;
        }

        a {
            word-break: break-word;
        }



        &.bot_message {
            margin-left: 8px;

            .chatgpt_text,
            .chatgpt_dots {
                background: #F1F1F1;
                border-radius: 0px 10px 10px 10px;
                color: #252525;
            }

            .chatgpt_dots {
                display: flex;
                align-items: center;
                min-height: 35px;
                padding: 10px 15px;
            }
        }

        &.user_message {
            align-items: flex-end;
            margin-left: auto;

            .chatgpt_text {
                background: #FD9F28;
                border-radius: 10px 0px 10px 10px;
                color: #fff;
                align-items: flex-end;  
            }
        }
    }

    &_action {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        background: linear-gradient(269.72deg, #D96600 0.19%, #F39F33 99.7%);
        border-radius: 3px;
        padding: 13px;
        margin-left: 20px;

        img {
            width: 28px;
        }
    }

    &_modal {
        position: fixed;
        width: 100%;
        max-width: 340px;
        height: 100%;
        max-height: 540px;
        background-color: #fff;
        right: 35px;
        top: calc(50% - 270px);
        z-index: 999999;
        transform: translateX(400px);
        transition: .3s;
        display: flex;
        flex-direction: column;
        border-radius: 10px;

        @include media-breakpoint-down(xs) {
            max-height: inherit;
            max-width: inherit;
            border-radius: 0;
            right: 0;
            top: 0;
            transform: translateX(100%);

        }

        &.open {
            transform: translateX(0);
        }

        .modal {
            &_head {
                padding: 15px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                background-color: #FD9F28;
                border-radius: 10px 10px 0 0;

                @include media-breakpoint-down(xs) {
                    border-radius: 0;
                }
            }

            &_avatar {
                display: flex;
                align-items: center;
            }

            &_close {
                display: none;

                @include media-breakpoint-down(xs) {
                    display: block;
                }
            }

            &_title {
                margin-left: 10px;
                font-size: 12px;
            }

            &_content {
                flex-grow: 1;
                padding: 15px;
                display: flex;
                flex-direction: column;
                overflow: hidden;
                overflow-y: auto;
            }

            &_day {
                display: flex;
                justify-content: center;
                color: #252525;
            }

            &_action {
                padding: 10px;
                display: flex;
                border-top: 1px solid #DBDADA;
            }

            &_field {
                flex-grow: 1;
                border: none;
                background: #F1F1F1;
                border-radius: 2px;
                padding: 6px 8px;
                color: #252525;

                &:focus,
                &:focus-visible {
                    border: none;
                    outline: none;
                }
            }

            &_send {
                margin-left: 15px;
                border: none;
                background-color: transparent;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                transition: .3s;

                svg {
                    fill: #FD9F28;
                }

                &:disabled {
                    svg {
                        fill: #999;
                    }

                    &:hover {
                        background: transparent;
                    }
                }

                &:hover {
                    background: #FFF3E4;

                    @include media-breakpoint-down(sm) {
                        background: transparent;
                    }
                }
            }
        }
    }
}

@keyframes typing {
    0% {
      transform: translateY(0);
    }
    50% {
        transform: translateY(-4px);
    }
    100% {
      transform: translateY(0);
    }
  }