// styles for section facts
.expertise-internal {
  section.intro {
    .text-note {
      color: $accent;
    }

    .intro-text-list {
      display: flex;
      flex-wrap: wrap;

      .list-item {
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;

        .item-text {
          .text-title {
            @include media-breakpoint-down(sm) {
              margin: 1rem 0;
            }
          }
        }
      }
    }

    &.hipaa-intro {
      padding-bottom: 0;
      width: 100%;

      .list-item {
        margin-bottom: 0;
        margin-top: 0;
        @media all and (max-width: 768px) {
          padding: 0;
        }
      }

      p:last-child {
        margin-bottom: 0;

        @include media-breakpoint-down(md) {
          margin-bottom: 10px;
        }
      }
    }
  }
}

