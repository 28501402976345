@import "../../mixins/bs_breakpoints.scss";

.design__fonts {
  overflow: hidden;

  .img-wrap {
    margin-top: 4rem;

    img {
      max-width: 100%;
      height: auto;
    }
  }

  .fonts-2 {
    float: right;

    @include media-breakpoint-down(md) {
      float: none;
    }
  }
}
