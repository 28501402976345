.form-subscribe {
  padding: 6px 20px;
  margin: 20px auto;
  overflow: hidden;
  position: relative;

  .flex-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include media-breakpoint-down(lg) {
      flex-direction: column;
    }
  }

  .agree-wrapper {
    text-align: center;

    @include media-breakpoint-down(lg) {
      margin-top: 15px;
    }
  }

  @include media-breakpoint-down(lg) {
    padding: 20px 20px 40px;
    max-width: 320px;
  }

  @include media-breakpoint-down(sm) {
    max-width: 320px;
  }

  .form-title {
    text-align: center;
    font-size: 18px;
    margin: 10px 6px;

    @include media-breakpoint-down(lg) {
      margin: 10px 0;
    }

    @include media-breakpoint-down(sm) {
      font-size: 18px;
    }
  }

  .field-subscriptions-email {
    margin: 10px 20px;
    flex-grow: 1;

    @include media-breakpoint-down(lg) {
      margin: 10px 0;
      width: 100%;
    }
  }

  .form-input {
    width: 100%;
    padding-left: 16px;
    font-size: 14px;
    display: block;
    margin: 10px 20px;
    -webkit-border-radius: 0;
    border-radius: 0;
    border: none;
    color: #242424;
    height: 38px;

    @include media-breakpoint-down(lg) {
      margin: 0;
    }
  }

  .form-input-submit {
    background: 0 0;
    color: #fff;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-border-radius: 0;
    border-radius: 0;
    border: 1px solid #fff;
    -webkit-transition: color .3s ease, border-color .3s ease, background-color .3s ease;
    -o-transition: color .3s ease, border-color .3s ease, background-color .3s ease;
    transition: color .3s ease, border-color .3s ease, background-color .3s ease;
    max-width: 220px;
    padding: 0;

    @include media-breakpoint-down(lg) {
      max-width: none;
    }

    &:hover {
      background: #fff;
      color: #e27826;
      border: 1px solid #fff;
    }

    &:active,
    &:focus {
      outline: none;
    }
  }

  .help-block,
  .subscribe-success {
    text-align: center;
    margin: 4px;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;

    &.blink {
      animation-name: pulsate;
      animation-duration: 1.2s;
      animation-timing-function: linear;
      animation-iteration-count: 1;
    }
  }
}

@keyframes pulsate {
  0% {
    color: rgba(255, 255, 255, 1);
  }
  50% {
    color: rgba(255, 255, 255, 0.1);
  }
  100% {
    color: rgba(255, 255, 255, 1);
  }
}

.hipaa-landing {
  .form-pdf {
    padding-top: 0;

    @media all and (max-width: 768px) {
      padding-left: 0;
      padding-right: 0;
    }

    .form-title {
      font-weight: 600;
    }

    form {
      padding: 25px 0;
      margin: 0 auto;
      @media all and (max-width: 768px) {
        max-width: 100%;
        width: 100%;
      }

      .subscribe-success {
        position: relative;
      }
    }

    .row {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      margin: auto;
      align-items: center;
    }

    .form-input-wrapper {
      display: flex;
      flex-wrap: wrap;
      @media all and (max-width: 768px) {
        width: 100%;
      }
    }

    .form-group {
      width: 60%;
      margin-right: 30px;
      margin-bottom: 0;
      @media all and (max-width: 1024px) {
        width: 100%;
        margin-right: 0;
        margin-bottom: 20px;
        min-width: auto;
      }

      label {
        margin-bottom: 0;
        width: 100%;
      }

      input {
        margin: 0;
      }

    }

    .form-input-submit {
      width: 30%;
      margin: 0;
      @media all and (max-width: 1024px) {
        width: 50%;
      }
      @media all and (max-width: 480px) {
        width: 100%;
      }
    }

    &.with-icon {
      padding: 0;
      background-image: url("/static/images/hipaa-landing/pdf-bg.png");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;

      .row {
        margin: 0;
      }

      .form-wrapper {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        max-width: 1530px;
        margin: 0 auto;
        padding: 30px 0;
      }

      .icon-wrapper {
        display: flex;
        justify-content: center;
      }

      form {
        max-width: 750px;
        justify-content: flex-start;
        flex-wrap: wrap;
      }

      .form-title {
        width: 100%;
        text-align: left;
        margin-left: 0;
        margin-bottom: 35px;
      }

      .form-group {
        min-width: auto;
        width: 60%;
        @media all and (max-width: 1024px) {
          width: 100%;
          margin-bottom: 20px
        }
      }

      .form-input {
        margin: 0;
      }

      .form-input-submit {
        width: 30%;
        margin-right: auto;
        margin-left: 0;
        margin-top: 15px;
        @media all and (max-width: 1024px) {
          width: 50%;
        }

        @media all and (max-width: 480px) {
          width: 100%;
        }
      }
    }

  }

  .form-subscribe {
    @media all and (max-width: 1200px) {
      flex-direction: row;
    }
  }
}

