.healthcare {
    &_section {
        padding: 40px 50px 30px !important;

        @include media-breakpoint-down(md) {
            padding: 20px 40px !important;
        }
    }

    .section-title {
        text-transform: uppercase !important;
        font-weight: 500 !important;
    }

    .content-hero {
        background: linear-gradient(90deg, #00606D 0%, #18E6C0 100%);
        padding: 25px;
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include media-breakpoint-down(md) {
            flex-direction: column;
        }

        &-block {
            max-width: 600px;
            text-align: center;

            @include media-breakpoint-down(md) {
                margin-bottom: 50px;
            }

            p {
                font-size: 16px;
                line-height: 1.6;
                margin-bottom: 15px;
            }

            .btn {
                min-width: 300px;
            }
        }
    }

    .img-logo {
        position: absolute;
        width: 100px;
        left: 30px;
        top: 30px;

        @include media-breakpoint-down(sm) {
            display: none;
        }
    }

    .img-left {
        max-width: 230px;
        align-self: flex-end;

        @include media-breakpoint-down(md) {
            align-self: center;
            margin-bottom: 40px;
            max-width: inherit;
        }
    }

    .intro {
        .item-text {
            line-height: 1.6;
            font-size: 16px;
        }

        .text_list {
            list-style: disc;
            padding-left: 25px;
        }
    }

    .why-we-are {
        .list-item {
            margin-bottom: 25px;
        }

        .item_title {
            display: flex;
            align-items: center;
            margin-bottom: 20px;

            @include media-breakpoint-down(md) {
                flex-direction: column;
                margin-bottom: 0;
            }
        }

        .item-img {
            width: 75px;
            height: 75px;
            border-radius: 50%;
            background-color: #E27826;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .text-title {
            margin-left: 20px;

            @include media-breakpoint-down(md) {
                align-self: start;
                margin-left: 0;
            }
        }

        .item-text {
            font-size: 16px;
            line-height: 1.6;
        }


        .text_list {
            padding-left: 15px;
            list-style: disc;

            li {
                margin-bottom: 10px;
                list-style-type: disc;
            }
        }
    }

    .benefits {
        background-color: #E69035;

        .content-block {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
        }

        &_item {
            display: flex;
            margin-bottom: 20px;
            width: 50%;
            padding: 0 15px;

            @include media-breakpoint-down(md) {
                width: 100%;
                padding: 0;
                flex-direction: column;
            }
        }

        &_icon {
            width: 75px;
            min-width: 75px;
            height: 75px;
            border-radius: 50%;
            background-color: #252525;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 20px;

            @include media-breakpoint-down(md) {
                margin: 0 auto 10px auto;
            }
        }

        &_text {
            font-size: 16px;
            line-height: 1.6;
        }
    }

    .testimonials {
        background-color: #fff;
        padding: 40px 15px;

        .section-title {
            color: #252525;
            margin-bottom: 0;
        }

        &-slider {
            padding: 15px 0;
        }
    }

    .types {
        &_title {
            display: flex;
            align-items: center;
        }

        &_icon {
            min-height: 50px;
            display: flex;
            align-items: flex-end;
            justify-content: center;
        }

        .text-title {
            color: #E27826;
            margin: 0 0 0 15px;
            font-weight: 600;
        }

        &_body {
            display: flex;
            flex-wrap: wrap;
        }

        &_item {
            width: calc(100% / 3);
            padding: 0 15px;

            @include media-breakpoint-down(md) {
                width: 100%;
                padding: 0;
                margin-bottom: 25px;
            }
        }

        &_content {
            margin-top: 20px;
            font-size: 16px;
            line-height: 1.6;

            ul {
                list-style: disc;
                padding-left: 20px;
            }

            li {
                margin-bottom: 10px;
            }
        }
    }

    .hire {
        background-color: #fff;
        color: #252525;

        .section-title {
            color: #252525;
        }

        &_body {
            display: flex;
            flex-wrap: wrap;
        }

        &_item {
            width: 50%;
            padding: 0 15px;
            font-size: 16px;
            line-height: 1.6;

            &:first-child {
                p:last-child {
                    @include media-breakpoint-down(md) {
                        margin-bottom: 0;
                    }
                }
            }

            @include media-breakpoint-down(md) {
                width: 100%;
            }
        }
    }
}