@import "../components/share-circle";

.menu {
  background-color: rgba(#000, .9);
  min-width: 210px;
  width: 210px;
  left: 0;
  top: 0;
  position: fixed;
  display: none;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  border-right: 1px solid rgba(#333, .5);
  z-index: 48177;

  @media screen and (max-height: 666px) {
    overflow: auto;
  }

  @include media-breakpoint-up(md) {
    display: flex;
  }

  &-clone {
    min-width: 210px;
    height: 100vh;
    min-height: 100vh;
    display: none;
    width: 100vw;
    position: fixed;

    @include media-breakpoint-up(lg) {
      min-height: 100%;
      height: auto;
    }

    @include media-breakpoint-up(md) {
      display: block;
      position: relative;
      width: 210px;
    }

    @include media-breakpoint-down(sm) {
      z-index: 480;
      background-color: rgba(0, 0, 0, .3);
    }
  }

  @include media-breakpoint-up(md) {
    background-color: #000;
  }

  &_top,
  &_bottom {
    width: 100%;
  }

  &_top {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  &_bottom {
    padding: 10px 10px 20px;
    margin-top: auto;

    .btn_discuss {
      margin: 0 auto;
    }
  }

  .flex-container {
    justify-content: space-around;
    width: 100%;
    align-items: center;
  }

  &__sitemap,
  &__copyright {
    opacity: .23;
    color: #fff;
    font-size: 11px;
  }

  &__sitemap {
    transition: opacity 0.3s ease;

    &:hover,
    &:active,
    &:focus {
      opacity: .6;
      color: #fff;
    }
  }

  .logo_wrap {
    padding: 0 20px;
    position: relative;
    margin-top: 10px;
    display: flex;
    justify-content: center;


    a {
      cursor: pointer;

      img {
        transform: scale(.7);
          transition: all 0.1s;
      }

      &:focus {
        img {
          transform: scale(.9);
          transition: all 0.1s;
        }
      }

      &:before,
      &:after {
        content: '';
        display: block;
        width: 15px;
        height: 15px;
        z-index: 5;
        position: absolute;
        transition: max-width .4s linear .3s, max-height .4s linear .3s;

        @include media-breakpoint-down(sm) {
          opacity: .5;
        }
      }

      &:before {
        border-top: 1px solid #fff;
        border-left: 1px solid #fff;
        left: 33px;
        top: 0;
      }

      &:after {
        border-bottom: 1px solid #fff;
        border-right: 1px solid #fff;
        bottom: 0;
        right: 33px;
      }

      &_img {
        width: auto;
        display: block;
        height: 65px;
        transition: all 0.1s;
      }
    }
  }

  &__button {
    @media screen and (max-width: 767px) {
      display: none !important;
    }

    @media (max-height: 666px) {
      display: none !important;
    }
  }

  &__nav {
    padding: 10px 15px;
    max-height: 420px;
    height: 100%;

    .nav__list {
      margin: 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
    }
  }
}

.burger {
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 1;

  @include media-breakpoint-up(md) {
    z-index: -1;
  }
}

.nav {
  &__list {
    padding-left: 0;
    position: relative;
    width: 100%;
    margin-top: 25px;

    @include media-breakpoint-up(md) {
      margin-top: 0;
    }

    @media screen and (max-height: 666px) {
      margin: 10px 0;
    }
  }

  &__item {
    position: relative;
    list-style-type: none;
    text-align: center;
    text-transform: uppercase;
    cursor: pointer;
    transition: color .3s ease;
    color: rgba(#fff, .8);

    @include media-breakpoint-up(md) {
      color: rgba(#fff, .5);
    }

    &.active {
      color: $accent;

      &:hover,
      &:focus {
        a {
          color: $accent;
        }
      }
    }

    & a {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 100%;
      color: inherit;
      padding: 9px 0;
      position: relative;

      &:before,
      &:after {
        content: '';
        height: 1px;
        width: 100%;
        background: linear-gradient(to right, transparent 0%, #ffffff 50%, transparent 100%);
        display: block;
        position: absolute;
        transition: opacity .3s ease;
        opacity: 0;

        @include media-breakpoint-up(md) {
          background: linear-gradient(to right, #000000 0%, #ffffff 50%, #000000 100%);
        }
      }

      &::before {
        top: 0;
      }

      &::after {
        bottom: 0;
      }

      &:hover,
      &:focus {
        color: #fff;

        &:before,
        &:after {
          opacity: 1;
        }
      }
    }
  }
}

.menu-contacts {
  margin: 10px 0;

  @include media-breakpoint-down(sm) {
    display: none;
  }

  @media (max-height: 666px) {
    display: none;
  }

  &__email,
  &__tel {
    color: #e27826;
    line-height: 25px;
    transition: color .3s ease;
    display: block;
    text-align: center;

    &:hover {
      color: darken(#e27826, 5%);
    }
  }
}

.menu-social {
  display: none;

  @include media-breakpoint-up(md) {
    display: flex;
    justify-content: space-between;
    letter-spacing: normal;
    list-style-type: none;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
  }

  @media (max-height: 666px) {
    display: none;
  }

  &__item {
    transition: opacity .3s ease;

    a {
      display: block;
      opacity: .5;

      &:hover,
      &:focus {
        opacity: 1;
      }

      span {
        font-size: 30px;
        color: #fff;
      }
    }
  }
}

.privacy {
  list-style-type: none;
  padding: 0;
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin: auto 0 10px 0;

  @include media-breakpoint-up(md) {
    margin: 15px 0;
  }

  &_item {
    a {
      font-size: 12px;
      color: #444;
      text-decoration: underline;
    }
  }
}