@import "design-fonts";

.design__text-content {
  &_mobile {
    display: none;

    @include media-breakpoint-down(sm) {
      display: block;
    }
  }

  &_desktop {

    @include media-breakpoint-down(sm) {
      display: none;
    }
  }
}

.portfolio-internal_design {
  padding: 0;

  @include media-breakpoint-down(sm) {
    padding: 0;
  }
}

.portfolio-internal-header__title_design {
  @include media-breakpoint-down(sm) {
    width: 100%;
  }
}

.portfolio-internal-technologies__image_design {
  margin-bottom: 10px;

  @include media-breakpoint-down(sm) {
    margin: 0 5px 10px 5px;
  }
}

.main-section__content_design {
  max-width: 1300px;
  margin: 20px auto 0 auto;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 40px;
  display: block;

  @include media-breakpoint-down(xs) {
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 20px;
    width: 100%;
    overflow: hidden;
  }
}

.design-box {
  background-color: $design-box-color;
  margin-top: 30px;
  overflow-x: visible;
  max-width: 1000px;
  position: relative;
  left: 50%;
  padding-top: 53px;
  padding-bottom: 53px;
  transform: translateX(-50%);

  @include media-breakpoint-down(xs) {
    width: calc(100% + 30px);
  }

  &__title {
    text-align: center;
    font-size: 50px;
    line-height: 1.2;
    margin-bottom: 28px;
    font-family: 'Roboto', sans-serif;
    font-weight: 900;
  }

  &__image {
    width: calc(100% - #{$design-box-padding} * 2);
    background-size: cover;
    background-repeat: no-repeat;
    padding-bottom: 53.5%;
    margin-left: $design-box-padding;

    @include media-breakpoint-down(sm) {
      width: calc(100% - #{$design-box-padding-sm} * 2);
      margin-left: $design-box-padding-sm;
    }
  }

  &__bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
    padding-left: $design-box-padding;
    padding-right: $design-box-padding;

    @include media-breakpoint-down(sm) {
      flex-direction: column;
    }
  }

  &__btn {
    font-weight: 200;
  }

  &__text {
    font-size: 24px;
    font-weight: 300;
    margin: 0;

    @include media-breakpoint-down(sm) {
      margin-bottom: 15px;
      text-align: center;
    }
  }
}

.design__top-image {
  padding-right: $design-box-padding;
  padding-left: $design-box-padding;

  @include media-breakpoint-down(sm) {
    padding-right: $design-box-padding-sm;
    padding-left: $design-box-padding-sm;
  }
}

.gradient-title {
  background-image: linear-gradient(to top, #afb9ad 0%, #ffffff 100%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: 'Roboto', sans-serif;
  font-weight: 900;
}

.design {
  &__btn {
    float: right;
    margin-bottom: 50px;
    margin-left: 15px;
    min-width: 150px;

    @include media-breakpoint-down(sm) {
      float: none;
      left: 50%;
      margin-left: 0;
      margin-bottom: 0;
      position: relative;
      transform: translateX(-50%);
    }
  }
}

.portfolio-internal__tools {
  margin-bottom: 80px;

  @include media-breakpoint-down(sm) {
    margin-bottom: 50px;
  }
}

.design-section {
  margin-top: 50px;
  padding: 0 $design-box-padding;

  @include media-breakpoint-down(sm) {
    padding-right: $design-box-padding-sm;
    padding-left: $design-box-padding-sm;
    margin-top: 30px;
  }

  & > .design-heading {
    position: relative;
    margin-bottom: 10px;
  }

  &_full-width {
    padding: 0;
  }

  &__img {
    position: absolute;
    left: 15px;
    top: 0;
    width: 100%;
    display: block;
    height: auto;
    z-index: 1;

    @include media-breakpoint-down(md) {
      left: auto;
      top: auto;
      position: relative;
    }
  }
}

.design__subtitle {
  text-align: center;
  line-height: 1.2;
  @include interpolate('font-size', 768px, 1920px, 42px, 70px);

  @include media-breakpoint-down(sm) {
    @include interpolate(font-size, 320px, 767px, 35px, 75px);
    text-align: left;
  }
}

.design-image {
  width: 100%;

  &__motion {
    &_schema {
      margin-top: 25px;

      @include media-breakpoint-down(sm) {
        display: none;
      }
    }

    &_concept {
      width: 112%;
      margin-bottom: -90px;

      @include media-breakpoint-down(sm) {
        width: 100%;
        margin-bottom: -60px;
      }
    }

    &_morphing {
      display: none;

      @include media-breakpoint-down(sm) {
        display: block;
        position: relative;
        margin-top: 30px;
      }
    }

    &_face {
      position: absolute;
    }
  }

  &__nxlog {
    &_pages {
      @include media-breakpoint-down(sm) {
        margin-top: 20px;
      }
    }

    &_final {
      margin-top: 30px;
      @include media-breakpoint-down(sm) {
        margin-top: 20px;
      }
    }
  }

  &__rveasy_pages {
    margin-top: -30px;

    @include media-breakpoint-down(sm) {
      margin-top: 20px;
      width: 100vw;
      margin-left: -#{$design-box-padding-sm};
    }
  }

  &__bekey {
    &_pages {
      @include media-breakpoint-down(sm) {
        margin-top: 20px;
      }
    }
  }

  &__avisaporta {

    &_grid-2 {
      padding-top: 35%;
    }

    &_final {
      margin-top: 80px;

      @include media-breakpoint-down(sm) {
        margin-top: 20px;
        padding-left: $design-box-padding-sm;
        padding-right: $design-box-padding-sm;
      }
    }

    &_pages {
      @include media-breakpoint-down(sm) {
        margin-top: -20px;
      }
    }
  }

  &__graphics {

    &_context {
      margin-top: -30px;
      position: relative;
      z-index: 2;

      @include media-breakpoint-down(sm) {
        margin-top: 5px;
      }
    }

    &_brochures {
      margin-top: -100px;
      position: relative;

      @include media-breakpoint-down(sm) {
        margin-top: -10px;
      }
    }

    &_vs-cards {
      padding-left: $design-box-padding;
      padding-right: $design-box-padding;
      margin-top: 49px;

      @include media-breakpoint-down(sm) {
        display: none;
      }
    }

    &_illustrations {
      margin-top: 30px;
    }

    &_final {
      padding-right: $design-box-padding;
      margin-top: 37px;
    }
  }

  &__branding {

    &_t-shorts {
      padding-left: calc(#{$design-box-padding} - 29px);
      margin-top: 30px;
      padding-right: $design-box-padding;
      position: relative;
      @include media-breakpoint-down(sm) {
        padding-left: 0;
        padding-right: 0;
      }

      &-1 {
        margin-top: 30px;
        margin-bottom: -90px;
        position: relative;
        z-index: 2;
        padding-left: calc(#{$design-box-padding} - 29px);
        padding-right: calc(#{$design-box-padding} - 29px);

        @include media-breakpoint-down(sm) {
          display: none;
        }
      }
    }

    &_letters {
      margin-top: -5px;
      padding-left: $design-box-padding;
      width: calc(101% + 30px);

      @include media-breakpoint-down(sm) {
        padding-left: $design-box-padding-sm;
        padding-right: calc(#{$design-box-padding-sm} - 6px);
        width: calc(100% + 30px);

      }
    }

    &_notebooks {
      padding-right: $design-box-padding;
      margin-top: 30px;
      padding-left: calc(#{$design-box-padding} - 32px);

      @include media-breakpoint-down(sm) {
        padding-right: $design-box-padding-sm;
      }
    }

    &_cups {
      margin-top: 30px;
    }
    &_flag {
      margin-top: -150px;
      padding-right: $design-box-padding;
      padding-left: calc(#{$design-box-padding} - 25px);

      @include media-breakpoint-down(sm) {
        padding-left: calc(#{$design-box-padding-sm} - 12px);
        padding-right: $design-box-padding-sm;
        margin-top: -50px;
      }
    }
  }

  &__crazysob {

    &_pages {
      width: 106%;
    }
  }
}

.margin_design-box {
  margin-left: $design-box-padding;
  margin-right: $design-box-padding;

  @include media-breakpoint-down(sm) {
    margin-left: $design-box-padding-sm;

  }
}

.design-heading {
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  min-width: 230px;
  display: inline-block;
  padding-left: 10px;
  position: absolute;
  top: 0;
  left: 0;
  padding-right: 40px;
  color: #fff;
  overflow: hidden;
  text-align: center;
  font-size: 24px;
  line-height: 31px;
  margin-bottom: 0;
  background: none;
  z-index: 2;
  box-shadow: -18px 12px 16px -11px rgba(0, 0, 0, 0.41);

  & > * {
    transform: skew(0);
  }

  &:after {
    content: '';
    display: inline-block;
    position: absolute;
    height: 100%;
    width: 100%;
    left: -30px;
    top: 0;
    transform: skew(20deg);
    padding-right: 10px;
    background-image: linear-gradient(to left, #e27826 0%, #f5a535 100%);
    z-index: -1;

    @include media-breakpoint-down(sm) {
      height: 150%;
    }
  }

  &_ml {
    margin-left: $design-box-padding;

    @include media-breakpoint-down(sm) {
      margin-left: $design-box-padding-sm;
    }
  }

  &_nxlog {
    &:after {
      background-image: linear-gradient(-25deg, rgba(0, 55, 96, 0.91) 0%, rgba(7, 134, 199, 0.91) 100%);
    }

    &-pages {
      transform: translateY(50px);

      @include media-breakpoint-down(sm) {
        transform: none;
      }

      &:after {
        background-color: $design-box-color;
      }
    }
  }

  &_rveasy {
    &:after {
      background-image: linear-gradient(-25deg, rgba(143, 148, 11, 0.91) 0%, rgba(179, 185, 17, 0.91) 52%, rgba(221, 225, 2, 0.91) 100%);
    }
  }

  &_avisaporta {
    &:after {
      background-image: linear-gradient(21deg, #9d1321 0%, #e62e3c 100%);
    }
  }

  &_crazysob {
    &:after {
      background-image: linear-gradient(-25deg, rgba(11, 98, 141, 0.91) 0%, rgba(45, 175, 232, 0.91) 100%);
    }

    &-about {
      margin-bottom: 40px;
    }

    &-before-after {
      margin-bottom: 50px;
    }

    &-mobile {
      margin-bottom: 40px;

      @include media-breakpoint-down(sm) {
        margin-bottom: 10px;
      }
    }

    &-ingredients {
      margin-bottom: 40px;

      @include media-breakpoint-down(sm) {
        margin-bottom: 10px;
      }
    }
  }

  &__nxlog {
    &_illustrations {
      margin-top: 20px;

      &:after {
        background-color: #FDFCF8;
      }
    }
  }
}

.design__fonts {
  margin-top: -30px;
}

.flex-container__nx-log-colors {
  align-items: center;
  transform: translateY(-30px);
  justify-content: space-between;

  @include media-breakpoint-down(sm) {
    transform: none;
  }
  margin-top: 20px;
}

.design__prototyping {
  margin-top: 30px;
  position: relative;
}

.design__prototyping {
  padding: 0 0 100% 0;
  margin-left: $design-box-padding;
  margin-right: $design-box-padding;
  background-image: none;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;

  @include media-breakpoint-down(md) {
    padding-bottom: 0;
    background: none;
  }

  @include media-breakpoint-down(sm) {
    margin-right: $design-box-padding-sm;
    margin-left: $design-box-padding-sm;
  }

  .design-heading {
    margin-top: 40px;

    @include media-breakpoint-down(sm) {
      margin-top: 20px;
    }

    &__bekey_prototyping {
      &:after {
        background-color: #FEAC54;
      }
    }

    &__avisaporta_prototyping {
      &:after {
        background-color: #E0E6E6;
      }
    }

  }
}

.prototyping__text {
  color: #7e7d7b;
  font-size: 18px;
  font-family: 'Roboto', sans-serif;
  padding-left: 50px;
  padding-top: 90px;
  margin: 0;
  padding-bottom: 15px;
  width: 100%;
  max-width: 200px;

  @include media-breakpoint-down(md) {
    max-width: 100%;
    background: #fff;
    padding-right: 15px;
    padding-left: 15px;
  }

  @include media-breakpoint-down(sm) {
    max-width: 100%;
    margin-left: 0;
    padding-right: 15px;
    padding-left: 15px;
    margin-top: 10px;
    @include interpolate("font-size", 320px, 767px, 16px, 48px)
  }

  &_bekey {
    color: #fff;
    background: transparent;

    @include media-breakpoint-down(sm) {
      background: #feac54;
      @include interpolate("font-size", 320px, 767px, 16px, 36px)
    }
  }

  &_avisaporta {
    color: #018cc6;
  }
}

.design-prototyping__content {
  position: absolute;
  z-index: 2;

  @include media-breakpoint-down(md) {
    position: relative;
    width: 100%;
  }
}

.design__text {
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  line-height: 1.5;
  font-size: 16px;

  @include media-breakpoint-down(sm) {
    line-height: 1.666;
  }

  &_about {
    margin-top: 30px;
    font-size: 16px;

    &-crazysob {
      margin-bottom: 30px;
    }
  }

  &_crazysob-mobile {
    position: absolute;
    width: 50%;
    margin-top: 5%;
    text-align: center;
    right: 10%;
    font-size: 16px;

    @include media-breakpoint-down(sm) {
      right: 0;
      width: 100%;
      position: relative;
      text-align: left;
    }
  }

  &_crazysob-ingredients {
    margin-bottom: 45px;
  }
}

.design__grid {
  margin-right: $design-box-padding;
  background-repeat: no-repeat;
  background-position: right top;
  position: relative;
  min-height: 390px;

  @include media-breakpoint-down(md) {
    background-size: contain;
    background-position: bottom;
    padding-bottom: 50%;
    margin-bottom: 50px;
  }

  @include media-breakpoint-down(sm) {
    display: none;
  }
}

.nxlog-section {
  &.design__prototyping {
    background-size: contain;
  }

  &.design__illustrations {
    background-image: url(/static/images/design/nxlog__illustrations.webp);
    padding-bottom: 52.5%;
    background-repeat: no-repeat;
    background-size: cover;
    margin-left: $design-box-padding;
    width: calc(100% - #{$design-box-padding} * 2);
    position: relative;
    padding-left: 0;

    @include media-breakpoint-down(md) {
      background-image: url(/static/images/design/nxlog__illustrations_mob.webp);
      background-size: contain;
      padding-bottom: 209.2%;
      margin-left: $design-box-padding-sm;
      width: calc(100% - #{$design-box-padding-sm} * 2);
    }
  }
}

.rveasy-section {
  &.design__grid {
    margin-top: 0;
    height: 457px;
  }

  &.design__icons {
    margin-top: -30px;

    @include media-breakpoint-down(sm) {
      margin-top: 0;
    }
  }
}

.crazysob-section {

  &.design__pages {
    margin-top: 50px;
    overflow: hidden;
  }

  &.design__ingredients {
    margin-top: 0;

    @include media-breakpoint-down(sm) {
      margin-top: 20px;
    }
  }
}

.graphics-section {
  &.design__printing {
    @include media-breakpoint-down(sm) {
      display: none;
    }
  }
}

.branding-section {

  &.design__notebooks {
    margin-top: -30px;
  }

  &.design__t-shorts {
    margin-top: -30px;

    @include media-breakpoint-down(sm) {
      margin-top: 0;
    }
  }

  &.design__cards {
    @include media-breakpoint-down(sm) {
      display: none;
    }
  }
}

.bekey-section {
  &.design__pages {
    margin-top: 20px;
  }

  &.design__prototyping {
    padding-bottom: 108%;

    @include media-breakpoint-down(md) {
      padding-bottom: 0;
    }
  }
}

.design-grid__text {
  width: 340px;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  line-height: 1.5;
  margin-top: 30px;
  font-size: 16px;
}

.rveasy-icons__text {
  margin-top: 50px;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  margin-bottom: 45px;
  line-height: 1.5;
  font-weight: 300;
  max-width: 450px;

  @include media-breakpoint-down(sm) {
    max-width: none;
    margin-top: 20px;
  }
}

.design-colors {
  @include media-breakpoint-down(md) {
    width: 80%;
    max-width: 300px;
    margin-top: 30px;
  }

  &_2 {
    @include media-breakpoint-down(md) {
      width: 130%;
      max-width: 500px;
      margin-top: 30px;
      margin-bottom: 20px;
    }
  }
}

.design-text {
  font-size: 16px;

  &__nxlog {

    &_illustrations {
      color: #272728;
      margin-top: 15px;
      font-size: 16px;
      padding-right: $design-box-padding;
      padding-left: $design-box-padding;
      position: absolute;

      @include media-breakpoint-down(sm) {
        @include interpolate("font-size", 320px, 767px, 14.5px, 38px);
        padding-left: $design-box-padding-sm;
      }
    }
  }

  &__bekey {
    &_about {
      margin-top: 30px;
    }
  }
}

.design-box {

  &_motion {
    overflow: hidden;
  }

  &.active {
    .design-box__image {
      &_nxlog {
        padding-bottom: 144%;
      }

      &_rveasy {
        padding-bottom: 55.3%;
      }

      &_avisaporta {
        padding-bottom: 93.9%;
      }

      &_design-crazysob {
        padding-bottom: 56.2%;
      }

      &_bekey {
        padding-bottom: 53.5%;
      }

      &_graphics {
        padding-bottom: 68.7%;
      }

      &_branding {
        padding-bottom: 72.9%;
        background-size: contain;
      }

    }
  }
}

.tools__title {
  display: inline-block;

  @include media-breakpoint-down(sm) {
    margin-top: 35px;
  }
}

.video-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;

  .motion__video_bekey + .video-play{
    background-size: contain;
  }

  video{
    filter: blur(0.1px);

    @include media-breakpoint-down(md){
      filter: none;
    }
  }
}

.video-play {
  opacity: 1;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  display: block;
  cursor: pointer;
  transition: opacity ease-in-out .3s;

  &:before {
    content: '';
    opacity: 1;
    border-radius: 100%;
    position: absolute;
    display: none;
    top: 50%;
    left: 50%;
    width: 50px;
    height: 50px;
    transform: translate(-50%, -50%) scale(1);
    background: rgba(255, 255, 255, .4);
    border: 1px solid rgba(255, 255, 255, .4);
    transition: transform ease-in-out .2s;

    @include media-breakpoint-down(lg){
      display: block;
    }
  }

  &:after {
    content: '';
    width: 0;
    height: 0;
    border-left: 14px solid rgba(0, 0, 0, .3);
    border-top: 10px solid transparent;
    border-bottom: 8px solid transparent;
    border-right: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: none;
    margin-left: 2px;

    @include media-breakpoint-down(lg){
      display: block;
    }
  }

  &.playing {
    opacity: 0;

    &:before {
      transform: translate(-50%, -50%) scale(2.5);
      opacity: 0;
      pointer-events: none;
    }

    &:after {
      opacity: 0;
      pointer-events: none;
    }
  }

  &.paused {
    background-image: none !important;
  }

  &.desktop {
    &:before,
    &:after {
      display: none;
    }
  }

  &.loading {
    &:before {
      background: rgba(255, 255, 255, 0);
      border-left: 1px solid #fff;
      border-right: 1px solid #fff;
      border-top: 1px solid rgba(255, 255, 255, 0);
      border-bottom: 1px solid rgba(255, 255, 255, 0);
      animation: rotation 2s linear infinite;
    }
  }
}

@keyframes rotation {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  50% {
    transform: translate(-50%, -50%) rotate(179.9deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(359deg);
  }
}

.design-image {
  pointer-events: none;
}

.position-relative {
  position: relative !important;
}