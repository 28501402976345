.pb-0 {
  padding-bottom: 0!important;
}

.mb-4 {
  margin-bottom: 40px;
}

.px-3 {
  padding: 0 30px;
}

.p-sm-1 {
  @include media-breakpoint-down(sm) {
    padding: 0 10px;
  }
}

.fw-600 {
  font-weight: 600;
}
