@import "../../../../fonts/icomoon/variables.scss";

.slick-slide {
  outline: none;
}

.contacts-mob {
  overflow-x: hidden;

  .slick-slider {
    padding-bottom: 8vw;
  }
  .slick-dots {
    display: flex;
    padding-left: 4.6vw;
    padding-right: 2.9vw;
    justify-content: space-between;
    width: 100vw;
    margin-top: 30px;
    position: relative;
    li {
      width: 18.43vw;
      height: auto;
      button {
        background-size: cover;
        background-position: 50%;
        width: 100%;
        height: 10.37vw;
        position: relative;
        &:before {
          display: none;
        }
      }

      &:nth-child(1) {
        button {
          background-image: url(/static/images/flag_usa.webp);
        }
      }

      &:nth-child(2) {
        button {
          background-image: url(/static/images/flag_ukraine.webp);
        }
      }

      &:nth-child(3) {
        button {
          background-image: url(/static/images/flag_israel.webp);
        }
      }

      &:nth-child(4) {
        button {
          background-image: url(/static/images/flag_germany.webp);
        }
      }

      &:nth-child(5) {
        button {
          background-image: url(/static/images/flag_canada.webp);
        }
      }

      &.slick-active {
        button:before {
          font-family: 'icomoon';
          content: $icon-play_up;
          color: #fff;
          font-size: 18px;
          display: block;
          left: 50%;
          margin-top: -12px;
          transform: translate(-50%, -100%);
          width: 4.8vw;
          height: 2.9vw;
        }
      }
    }
  }
}

.departments-int-mob {
  .departments-slider {
    .slick-slide {
      margin: 0 30px; //min-margin between slides (children)

    }
    .slick-list {
      margin: 0 -30px; //min-margin between slides (parent)
    }
  }

  .departments-image-slider {
    .slick-slide {
      margin: 0 0; //min-margin between slides (children)

    }
    .slick-list {

      @media (max-width: 320px) {
        margin: 0 30px; //min-margin between slides (parent)
      }

      overflow: visible;
    }

    .slick-active {
      z-index: 4;
      overflow: visible;
    }
  }
}

//STARTUP SLIDER + DEDICATED SLIDER

.portfolio {
  .portfolio__slider_phone {
    .slick-list {
      border-radius: 14px;
      height: 100%;
    }
  }

  .slick-track {
    height: 100%;
  }

  .slick-dots {
    display: inline-block;
    position: relative;
    top: 0;
    left: 0;
    height: 70px;
    z-index: 10;

    &-pseudo__wrapper {
      width: 450px;
      position: relative;
      margin-bottom: -70px;
      margin-top: 35px;
      margin-left: -0.5%;

      .slick-list {
        height: 100%;
        padding: 0 20px 0 0 !important;
      }
      .slick-track {
        height: 100%;
        display: flex;
      }
    }

    &.active {
      .slick-current button:before {
        content: $icon-Play;
        margin-left: 2px;
      }
    }

    &.bline {
      .slick-current button {
        background: linear-gradient(-28deg, #4b87b9 0%, #8977ab 77%, #8977ab 100%);
      }
    }

    &.israeli {
      .slick-current button {
        background: linear-gradient(-28deg, #222246 0%, #04b2e3 100%);
      }
    }

    &.holding {
      .slick-current button {
        background: linear-gradient(-28deg, #3a4868 0%, #3a4868 21%, #e53545 84%, #e53545 100%);

      }
    }

    &.kmj {
      .slick-current button {
        background: linear-gradient(-28deg, #71053e 0%, #71053e 8%, #ed2084 48%, #ffbede 99%, #ffbede 100%);
      }
    }

    &.videotherapy {
      .slick-current button {
        background: linear-gradient(-28deg, #59a37e 0%, #189dbe 97%, #189dbe 100%);
      }
    }
    &.crazysob {
      .slick-current button {
        background: #b0c36d;
      }
    }

    &.videotherapy {
      .slick-current button {
        background: linear-gradient(-28deg, #59a37e 0%, #189dbe 97%, #189dbe 100%);
      }
    }

    &.atlasense {
      .slick-current button {
        background: #b10d14;
      }
    }

    &.ehealth {
      .slick-current button {
        background-image: radial-gradient(circle 38px at center, rgba(255, 255, 255, 0.74) 0%, rgba(255, 255, 255, 0) 100%);
        background-color: #00c8c0;
      }
    }

    &.newx {
      .slick-current button {
        background: linear-gradient(to bottom, #0c4a75 0%, #59bec9 100%);
      }
    }

    &.syrtec {
      .slick-current button {
        background: linear-gradient(-28deg, #58bad6 0%, #83cce1 100%);
      }
    }

    &.crm {
      .slick-current button {
        background: linear-gradient(-28deg, #37bdeb 0%, #09c267 100%);
      }
    }

    &.design {
      .slick-current button {
        background-image: url(/static/images/design-button.webp);
        background-size: cover;
        background-position: 50%;
      }
    }

    li {
      width: auto;
      height: auto;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      button {
        height: 52px;
        width: 52px;
        transition: all .3s ease;
        content: '';
        border-radius: 50%;
        background-image: radial-gradient(circle 25px at center, #f4c43e 0%, #e5862a 100%);

        &:before {
          display: none;
        }

        &.line1 {
          transform: scale(.9)
        }

        &.line2 {
          transform: scale(.75) translateY(5%)
        }

        &.line3 {
          transform: scale(.6) translateY(10%)
        }

        &.line4 {
          transform: scale(.45) translateY(15%)
        }

        &.line5 {
          transform: scale(.3) translateY(20%)
        }

        @include media-breakpoint-down(md) {
          height: 42px;
          width: 42px;
        }
      }

      &.slick-current {

        button {
          transform: translateY(-20%) scale(1.2);

          &:before {
            font-family: 'icomoon';
            content: $icon-Pause;
            color: #fff;
            font-size: 40px;
            display: block;
            height: 40px;
            width: 40px;
            left: 50%;
            margin-top: -1px;
            top: 50%;
            transform: translate(-50%, -25%);
          }

          @include media-breakpoint-down(md) {

            &:before {
              height: 32px;
              width: 32px;
              font-size: 32px;
              margin-top: -2px;
            }
          }
        }
      }
    }
  }
}

//DEPARTMENTS MOBILE SLIDER

.departments-int-mob {

  .slick-dots {

    margin-top: 30px;
    margin-bottom: 70px;

    bottom: auto;
    top: 0;
    position: relative;
    display: block;

    li {
      display: flex;
      width: auto;
      height: 40px;
      margin-top: 20px;

      &.slick-active {
        .departments-int-mob__icon-text {
          color: #e27826;
          text-decoration: underline;
        }
      }

      button {
        height: 40px;
        width: 40px;

        &:before {
          display: none;
        }
      }
    }
  }
}
