@charset "utf-8";

@import "../fonts/icomoon/style.scss";

@import "mixins/orbits";
@import "mixins/adjustable-font-size";

@import "parts/components/libs/libs";
@import "parts/components/breadcrumbs";

@import "var's/vars";
@import "helpers";

@import "parts/components/fonts";
@import "parts/components/preloader";
@import "parts/components/base";
@import "parts/components/futered-posts";

@import "parts/sections/menu";
@import "parts/sections/sitemap";
@import "parts/components/scroll-buttons";

@import "parts/sections/not-found";
@import "parts/sections/careers";
@import "parts/sections/about";
@import "parts/sections/mission";
@import "parts/sections/contacts";
@import "parts/sections/expertise";
@import "parts/sections/departments";
@import "parts/sections/departments-internal";
@import "parts/sections/portfolio-internal";
@import "parts/sections/design";
@import "parts/sections/design-fonts";
@import "parts/sections/services";
@import "parts/sections/testimonials-slider";
@import "parts/sections/home-section";
@import "parts/sections/faq";
@import "parts/sections/additional-section";
@import "parts/sections/author";
@import "parts/sections/healthcare-app-development";
@import "parts/sections/blog-internal";
@import "parts/sections/landing_pages";
@import "parts/sections/chatgpt";
//@import "parts/sections/motions";
//@import "parts/sections/services-internal";
@import "parts/sections/blog";
//@import "parts/sections/home";
@import "parts/sections/portfolio";
//@import "parts/sections/parallax";

@import "parts/components/scroll";
@import "parts/components/paginator";
@import "parts/components/popup";
@import "parts/components/forms";
@import "parts/components/technology/index";
@import "parts/components/hipaa/index";
@import "parts/components/expertise-internal/index";
@import "parts/components/cookies";
@import "parts/components/animation_banner";

.brand-color {
  color: $brand-primary;
}

.section-title {
  @extend .h2;
  @extend .text-left;
  @extend .brand-color;
  font-weight: 400;
  width: 100%;
  margin: 20px 0 35px;
  text-transform: uppercase;
}

.content-inner {
  background: #252525;

  .page-title {
    text-transform: uppercase;
    text-align: center;
    font-size: 30px;
    margin: 170px auto 30px;

    @include media-breakpoint-up(md) {
      margin: 70px auto 20px;
    }
  }

  section {
    width: 100%;
    padding: 30px 15px 30px;

    @include media-breakpoint-up(md) {
      padding: 50px 0;
    }

    &.well {
      padding: 25px 15px;

      @include media-breakpoint-up(md) {
        padding: 35px 0;
        margin: 0 auto;
      }
    }
  }

  .section-title {
    font-size: 25px;
    text-align: center !important;
    margin: 0 0 30px 0;
    padding: 0 15px;
    text-transform: initial;
    color: #fff;
    font-weight: 600;

    &.land {
      font-weight: 500;
      text-transform: uppercase;
    }

    &-sm {
      font-size: 20px;
    }
  }
}

.internal-page-container {
  background: #252525;
}

.well {
  border: none;
  border-radius: 0;
  box-shadow: none;
  background: transparent;
  text-align: left;
  margin: 0;

  &-content {
    display: flex;
    flex-direction: column;
    justify-content: center;

    p {
      @include media-breakpoint-down(xs) {
        text-align: center;
      }
    }
  }

  &-content-wrapper {
    display: flex;
    flex-wrap: nowrap;
    align-items: stretch;
    justify-content: space-between;
    flex-direction: column;
    text-align: center;

    @include media-breakpoint-up(lg) {
      text-align: left;
      flex-direction: row;
    }
  }

  &-title {
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 10px;

    @include media-breakpoint-up(md) {
      margin-bottom: 0;
    }
  }

  &-text {
    color: #000;
    font-size: 14px;
  }

  a.btn.btn-bordered, 
  span.btn.btn-bordered {
    min-width: 212px;
    width: 100%;
    margin: 20px auto auto;

    @include media-breakpoint-up(md) {
      margin: auto 0 auto 30px;
      width: auto;
    }
  }
}

.pie_chart {
  width: 100%;
  margin-bottom: 20px;

  @include media-breakpoint-up(lg) {
    width: 50%;
    margin-bottom: 0;
  }

  &-wrapper {
    padding: 0 15px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;

    @include media-breakpoint-up(lg) {
      align-items: stretch;
      flex-direction: row;
    }
  }

  &-value-list {
    width: 100%;

    @include media-breakpoint-up(md) {
      width: 50%;
    }

    &-source {
      margin-top: 0;
      margin-bottom: auto;
      text-align: left;
      width: 100%;
      margin-left: 0;

      @include media-breakpoint-up(md) {
        margin-left: 20px;
      }
    }
  }

  &-value-list,
  .chart {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin: 0 auto;
  }
}

.line_chart {
  max-width: 800px;
  width: 100%;
  margin: 0 auto;

  svg {
    width: 100%;
    height: auto;
  }
}

.pie_chart * {
  stroke: none;
}

.chart-legend {
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: flex-start;
  list-style: none;
  flex-wrap: wrap;
  padding: 0;
  width: 100%;
  margin: 20px 0 40px 0;

  @include media-breakpoint-up(sm) {
    margin: auto 0 70px 20px;
  }

  li {
    padding: 0;
    margin-bottom: 5px;
    white-space: nowrap;
    min-width: 100%;

    @include media-breakpoint-up(sm) {
      min-width: 50%;
    }

    &:last-child {
      margin-bottom: 0;
    }

    .chart-dot {
      display: inline-block;
      margin-right: 10px;
      width: 12px;
      height: 12px;
      border-radius: 8px;
      vertical-align: middle;
    }

    .chart-value {
      color: #E27826;
    }
  }
}

.bg-brand {
  background: linear-gradient(269.72deg, #D96600 0.19%, #F39F33 99.7%);
}

.bg-white {
  background-color: #fff;
  color: #000;
}

.bg-black {
  background: #252525;
}

.bg-blue {
  background-color: #3D538C;
}

.pie_chart {
  .highcharts-background {
    fill: #252525 !important;
  }

  .highcharts-data-label-color-2 {
    text {
      font-size: 150% !important;
    }
  }
}

.line_chart {
  .highcharts-background {
    fill: none !important;
  }

  .highcharts-label {
    tspan {
      stroke: none !important;
      stroke-width: 1px !important;
      font-weight: 100 !important;
    }
  }
}

.highcharts-credits,
.highcharts-exporting-group {
  display: none !important;
}

a.btn.btn-bordered,
span.btn.btn-bordered {
  background: transparent;
  box-shadow: none;
  border: 1px solid #fff;
  color: #fff;
  border-radius: 0;
  padding: 14px 20px !important;
  display: inline-block;
  max-width: none;
  width: auto;
  height: auto;
  min-width: 0;
  line-height: 1;
  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;
  text-shadow: none;

  &:hover,
  &:active {
    text-decoration: none;
    box-shadow: none;
    background: #fff;
    color: $brand-primary;
  }
}

.grecaptcha-badge {
  z-index: -1;
  opacity: 0;
}
