// styles for footer featured-post
.futered-post-footer.hipaa-footer {
  .futered-post-footer__flex-container {
    flex-wrap: wrap;

    @media all and (max-width: 768px) {
      padding: 0 15px;
    }
  }

  .futered-post-footer__item {
    padding: 15px;
    width: 100%;

    @media all and (min-width: 768px) {
      width: 50%;
    }

    @media all and (min-width: 1200px) {
      width: 33.3%;
      max-height: 310px;
    }

    .futered-post-footer__item-wrapper {
      position: relative;
      height: 100%;
      width: 100%;

      @media all and (min-width: 768px) {
        background-position: 50%;
      }
    }
  }
}
