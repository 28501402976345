// styles for section facts
.technology {
  .facts {
    .section-subtitle {
      @include media-breakpoint-down(sm) {
        text-align: left;
      }
    }

    .text-note {
      color: #EFA13C;
      font-weight: 600;

      @include media-breakpoint-down(sm) {
        text-align: left;
      }

      a {
        color: $accent;
        text-decoration: underline;
      }
    }

    .facts-list {
      display: flex;
      flex-wrap: wrap;

      .list-item {
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;

        @include media-breakpoint-down(sm) {
          display: inline-block;
        }

        .list-item-layout {
          display: flex;
          align-items: flex-start;

          img {
            min-width: 40px;
            width: 40px;
            margin-right: 20px;
            margin-top: 6px;

            @include media-breakpoint-down(sm) {
              float: left;
            }
          }

          .text-title {
            font-weight: 600;
          }

          .icon-elephant {
            font-size: 40px;
            margin-right: 20px;
            color: #e27826;
          }
        }
      }
    }
  }
}

.technology.hireangulardeveloper {
  section.facts {
    background-color: #181D29;
  }
}
