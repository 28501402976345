.portfolio-internal-title {
  font-weight: 300;
  font-family: 'Roboto', sans-serif;
  font-size: 24px;
  margin-bottom: 0;
  margin-top: 0;

  &_two-lines {
    margin-top: 15px;
  }


  &_regular {
    font-weight: 400;
    font-size: 20px;
  }

  &_crazysob-achievements {
    margin-top: 75px;
  }
}


.portfolio-internal {
  background-color: #0f0b04;
  background-size: 100% auto;
  background-position: top center;
  background-repeat: no-repeat;
  background-attachment: scroll;
  padding: 30px 12.4% 50px 12.4%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;

  @include media-breakpoint-down(sm) {
    padding: 0 15px 30px 15px;
    margin-top: 130px;
    background-size: auto 110vw;
  }

  &_bottom {
    @include media-breakpoint-down(sm) {
      margin-top: 0;
    }
  }

  .main-section__content {
    @include media-breakpoint-down(sm) {
      margin-top: 0;
      padding-top: 0;
    }
  }

  &_newx,
  &_syrtec{

    .portfolio-internal-header {
      padding: 30px 10.4% 0 10.4%;
    }

    .main-section__content {
      padding: 30px 10.4% 50px 10.4%;

      @include media-breakpoint-down(sm) {
        padding-left: 15px;
        padding-right: 15px;
      }
    }
  }



  &-header {

    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    position: relative;

    @include media-breakpoint-down(sm) {
      padding-left: 0;
      padding-top: 15px;

      &:before {
        content: '';
        display: block;
        width: 100vw;
        position: absolute;
        background: linear-gradient(to bottom, #000, transparent);
        height: 55px;
        top: 0;
        left: 0;
        margin-left: -2%;
      }
    }

    &__title {
      font-family: 'Roboto', sans-serif;
      font-weight: 300;
      font-size: 25px;
      text-align: center;
      margin-top: 10px;

      @include media-breakpoint-down(sm) {
        text-align: left;
        @include interpolate(font-size, 320px, 767px, 25px, 65px);
        width: 80%;
      }
    }

    &__arrow {
      font-size: 22px;
      z-index: 6;

      @include media-breakpoint-down(sm) {
        display: none;
      }
    }
  }

  &__arrow {
    color: #fff;
    &:hover,
    &:focus,
    &:active {
      color: #eee;
    }
  }

  &-hero {
    margin-top: 25px;
    width: 100%;
    position: relative;

    @include media-breakpoint-down(sm) {
      margin-bottom: 55px;
    }

    &__image {
      width: 100%;
    }

    &__arrow {
      display: none;
      position: absolute;
      bottom: 0;
      transform:translateY(110%);
      z-index: 3;
      font-size: 33px;

      @include media-breakpoint-down(sm) {
        display: block;
      }

      &_prev {
        left: 0;
      }

      &_next {
        right: 0;
      }
    }

  }

  &-color {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: flex-end ;
    margin-bottom: 50px;


    @include media-breakpoint-down(sm) {
      flex-direction: column;
      align-items: flex-start;
      height: 75px;
      margin-top: 50px;

    }

    &__title {

      @include media-breakpoint-down(md) {
        font-size: 30px;
        width: 100%;
      }
    }

    &__image {
      height: 57px;
      max-width: 100%;

      @include media-breakpoint-down(lg) {
        height: 43px;
      }

      @include media-breakpoint-down(md) {
        height: 30px;
      }

      @include media-breakpoint-down(sm) {
        height: auto;
        width: auto;
        margin-top: 15px;
        margin-bottom: 20px;
      }
    }
  }

  &__description {
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 300;
    line-height: 25px;
    margin-top: 5px;

    @include media-breakpoint-down(sm) {
    }
  }

  &-technologies {
    margin-top: 87px;
    display: flex;
    align-self: flex-start;
    align-items: center;

    &_two-lines {
      align-items: flex-start;
    }

    @include media-breakpoint-down(md) {
      display: block;
      margin-top: 45px;
    }

    &__image {
      height: 40px;
      width: auto;
      display: inline-block;
      max-width: 120px;
      margin: 6px 10px;

      &_two-lines {
        margin-top: 10px;
      }
    }
  }

  &-story {
    width: 100%;
    margin-top: 78px;

    @include media-breakpoint-down(sm) {
      margin-top: 30px;
    }

    &__title {

      &_newx,
      &_atlasense {
        margin-bottom: 35px;
      }
    }

    &__lists-section {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      margin-top: 10px;

      &_newx {
        display: block;
        margin-bottom: 30px;
        column-count: 2;
      }

      &_crazysob {
        margin-bottom: 30px;
      }

      &_atlasense {
        margin-top: 45px;
      }

      @include media-breakpoint-down(sm) {
        flex-direction: column;
        display: flex;
        column-count: 1;
      }
    }

    &__list_wrapper {
      width: 50%;

      @include media-breakpoint-down(sm) {
        width: 100%;
        margin-top: 25px;
      }
    }

    &__list {
      font-weight: 300;
      font-family: 'Roboto', sans-serif;
      font-size: 16px;

      @include media-breakpoint-down(sm) {
        font-size: 18px;
        margin-bottom: 20px;
      }

      &-title {
        font-size: 16px;

        @include media-breakpoint-down(sm) {
          font-size: 18px;
          font-weight: 300;
          font-family: 'Roboto', sans-serif;
        }

      }
    }
  }

  &__list {
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    font-size: 16px;
    line-height: 25px;
    margin: 35px 0 ;

    &_newx {
      padding-left: 25px;
    }

    &_collapsed {
      margin: 0;
      padding-left: 20px;

      &--mb30 {
        margin-bottom: 30px;
      }
    }
  }
  &__order-box {
    margin-top: 0 !important;

  }

  &__success-story-image {
    width: 100%;

    @include media-breakpoint-down(sm) {
      display: none;
    }

    &_mob {
      width: 100%;
      display: none;

      @include media-breakpoint-down(sm) {
        display: block;
      }
    }
  }
}

.portfolio-internal-order {
  width: 100%;
  background-image: linear-gradient(to left, #d96600 0%, #f39f33 100%);
  padding: 26px 29px 36px 31px;
  display: flex;
  justify-content: space-between;

  @include media-breakpoint-down(md) {
    flex-direction: column;
    align-items: center;
  }

  &__text {

    @include media-breakpoint-down(md) {
      text-align: center;
    }

    &_big {
      font-size: 24px;
      margin-bottom: 0;
    }

    &_small {
      font-size: 16px;
      color: #252525;
      font-family: 'Roboto', sans-serif;
      margin-bottom: 0;
      line-height: 1.5;
    }
  }
  &__button {
    min-width: 184px;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 200;
    margin-left: 18px;

    @include media-breakpoint-down(md) {
      height: 54px;
      margin-top: 23px;
      margin-left: 0;
    }
  }
}

.portfolio-internal-footer {
  display: none;

  @include media-breakpoint-down(sm) {
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin-top: 40px;

    &__arrow {
      font-size: 42px;
      color: #fff;

      &:hover,
      &:focus,
      &:active {
        color: #eee;
      }
    }
  }
}

.popup__wrapper_portfolio-internal .agree {
  margin-top: 15px;
}