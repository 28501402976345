@import '../../../fonts/icomoon/variables.scss';
@import '../../mixins/bs_breakpoints.scss';
@import '../../mixins/adjustable-font-size';

.home-section {
  padding: 40px 15px 25px;
  background: #161616 url('/static/images/home-bg.webp') no-repeat 50%;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  position: relative;
  transition: none;

  @include media-breakpoint-down(md) {
    height: auto;
  }

  @include media-breakpoint-down(sm) {
    padding: 75px 15px 25px;
  }

  &__wrap {
    height: inherit;
    max-width: 1170px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
  }

  @include media-breakpoint-up(md) {
    transition: opacity 0.3s ease 1s;
  }

  * {
    user-select: none;
  }

  &.passive {
    opacity: 0;
    transition: none;

    @include media-breakpoint-up(md) {
      transition: opacity 0.7s ease;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: relative;

    @include media-breakpoint-down(xs) {
      flex-direction: row;
      margin: 0 0 25px;
    }
  }

  &__bg {
    background-image: url(/static/images/parallax-bg_orange.webp);
    width: 100%;
    background-size: cover;
    background-position: 50%;
    height: 100%;
    opacity: 0.75;
  }

  &__title {
    margin: 0 0 25px 0;
    font-weight: 600;
    color: #e27826;
    font-size: 44px;
    text-align: left;
    line-height: 1.3;
    @include interpolate(font-size, 320px, 767px, 26px, 38px);
    width: 100%;

    @include media-breakpoint-down(md) {
      margin: 0 0 5px 0;
    }

    @include media-breakpoint-down(sm) {
      line-height: 1.5;
    }

    @media (max-width: 767px) and (orientation: landscape) {
      @include interpolate(font-size, 320px, 767px, 20px, 36px);
    }
  }

  &__wrapper-text {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__text {
    width: 80%;
    padding: 0 15px;
    line-height: 1.66;
    letter-spacing: 0.02em;
    color: #e2e2e2;
    font-size: 16px;
    margin: 0;

    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  &__button-wrapper {
    display: flex;
    justify-content: flex-end;
    width: 15%;
    padding: 0 15px;

    @include media-breakpoint-down(md) {
      width: auto;
    }
  }

  &__button {
    display: block;
    box-shadow: none;
    border-radius: 50%;
    background: linear-gradient(269.72deg, #d96600 0.19%, #f39f33 99.7%);
    @include interpolate(width, 768px, 1920px, 65px, 90px);
    @include interpolate(height, 768px, 1920px, 65px, 90px);
    padding-right: 10px;
    padding-left: 10px;
    z-index: 1;
    position: relative;
    pointer-events: auto;

    &:hover {
      box-shadow: none;
    }

    &:before {
      content: '';
      position: absolute;
      z-index: 0;
      left: 2px;
      border-radius: 50%;
      top: 2px;
      bottom: 2px;
      right: 2px;
      width: calc(100% - 4px);
      height: calc(100% - 4px);
      background: #161616;
    }

    &:focus {
      & + div.home__button-text {
        color: $accent;
      }
    }

    @include media-breakpoint-down(sm) {
      @include interpolate(width, 320px, 767px, 65px, 140px);
      @include interpolate(height, 320px, 767px, 65px, 140px);
    }

    @media (max-width: 767px) and (orientation: landscape) {
      @include interpolate(width, 320px, 767px, 40px, 95px);
      @include interpolate(height, 320px, 767px, 40px, 95px);
    }

    &:after {
      content: '';
      opacity: 0;
      top: 50%;
      left: 50%;
      border: 2px solid #f39f33;
      @include interpolate(width, 768px, 1920px, 75px, 100px);
      @include interpolate(height, 768px, 1920px, 75px, 100px);
      z-index: 0;
      position: absolute;
      border-radius: 50%;
      transform: translate(-50%, -50%) scale(1);
      animation: 2s linear infinite pulse;

      @include media-breakpoint-down(sm) {
        @include interpolate(width, 320px, 767px, 75px, 150px);
        @include interpolate(height, 320px, 767px, 75px, 150px);
      }

      @media (max-width: 767px) and (orientation: landscape) {
        @include interpolate(width, 320px, 767px, 50px, 105px);
        @include interpolate(height, 320px, 767px, 50px, 105px);
      }
    }

    &-wrapper {
      margin-top: 20px;
      display: flex;
      align-items: center;

      @media (max-width: 767px) and (orientation: landscape) {
        margin-top: 0;
      }
    }

    &-text {
      margin: 0 0 0 30px;
      @include interpolate(font-size, 768px, 1920px, 24px, 30px);
      letter-spacing: 0.5px;
      font-weight: 600;

      @include media-breakpoint-down(sm) {
        @include interpolate(font-size, 320px, 767px, 18px, 30px);
        margin-left: 10px;
      }

      @media (max-width: 767px) and (orientation: landscape) {
        @include interpolate(font-size, 320px, 767px, 18px, 24px);
        margin-left: 15px;
      }
    }

    .icon-Play {
      background: linear-gradient(269.72deg, #d96600 0.19%, #f39f33 99.7%);
      color: transparent;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      display: initial;
      position: relative;
      z-index: 3;
      margin-right: -5px;
      font-size: 40px;
    }

    p {
      display: block;
      white-space: normal;
      margin-bottom: 0;
      margin-top: 5px;
      line-height: 1;

      @include media-breakpoint-down(sm) {
        display: none;
      }
    }
  }

  &__contact-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 35px 0;

    @include media-breakpoint-down(md) {
      flex-direction: column;
      margin: 50px 0 20px;
    }
  }

  &__contact-button {
    @include interpolate(width, 768px, 1920px, 54px, 54px);
    @include interpolate(height, 768px, 1920px, 54px, 54px);
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    color: #fff;
    border-radius: 2px;
    pointer-events: auto;

    &:hover,
    &:active,
    &:focus,
    &:active:focus {
      span {
        color: $accent;
      }
    }

    svg {
      transition: color 0.3s ease;
    }

    .icon-phone {
      color: #fff;
      font-size: 25px;
    }
  }

  &__contact-button_mail {
    position: relative;
    min-width: 173px;
    font-size: 14px;
    line-height: 1;
    font-weight: 600;
    text-transform: uppercase;
    padding: 12px 22px;
    background-image: linear-gradient(269.72deg, #d96600 0.19%, #f39f33 99.7%);
    transition: 0.4s ease-in-out;

    @include media-breakpoint-down(xs) {
      width: auto;
      padding: 20px 35px;
      height: auto;
    }
  }

  &__contact-button_phone {
    transition: 0.3s ease-in-out;
    position: relative;
    overflow: hidden;
    background: linear-gradient(
      101.96deg,
      #2c2c2c 0.92%,
      #e7841b 52.52%,
      #222222 100%
    );

    svg {
      position: relative;
      z-index: 2;

      @include media-breakpoint-down(xs) {
        width: 25px;
        height: 25px;
      }
    }

    &:before {
      content: '';
      position: absolute;
      left: 1px;
      top: 1px;
      z-index: 2;
      border-radius: 2px;
      bottom: 1px;
      right: 1px;
      width: calc(100% - 2px);
      height: calc(100% - 2px);
      background: linear-gradient(101.87deg, #313131 6.37%, #242424 88.98%);
    }

    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      z-index: 0;
      opacity: 0;
      border-radius: 2px;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      transform: rotate(0);
      background: linear-gradient(
        165.96deg,
        #2c2c2c 0.92%,
        #e7841b 52.52%,
        #222222 100%
      );
      transition: 0.5s;
    }

    &:hover:after {
      content: '';
      position: absolute;
      opacity: 1;
    }
  }

  &__service-block {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

  }

  &__service-wrapper-item {
    padding: 15px;
    width: 100%;
    max-width: 400px;
  }

  &__service-item {
    padding: 15px 35px;
    display: flex;
    align-items: center;
    transition: 0.3s ease-in-out;
    position: relative;
    overflow: hidden;
    border-radius: 2px;
    background: linear-gradient(
      101.96deg,
      #2c2c2c 0.92%,
      #e7841b 52.52%,
      #222222 100%
    );

    @include media-breakpoint-down(sm) {
      padding: 15px;
    }

    .icon-arrow-left {
      margin-bottom: 7px;
      align-self: flex-end;
      position: relative;
      z-index: 2;
      transform: rotate(180deg) translateX(0);
      transition: 0.5s;

      @include media-breakpoint-down(xs) {
        display: none;
      }
    }

    svg {
      rect,
      g,
      path {
        transition: 0.5s;
      }

      .circle-start {
        opacity: 1;
      }

      .circle-end {
        transform: translate(12px, 15px);
        opacity: 0;
      }
    }

    &:before {
      content: '';
      position: absolute;
      left: 1px;
      top: 1px;
      z-index: 2;
      border-radius: 2px;
      bottom: 1px;
      right: 1px;
      width: calc(100% - 2px);
      height: calc(100% - 2px);
      background: linear-gradient(101.87deg, #313131 6.37%, #242424 88.98%);
    }

    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      z-index: 0;
      opacity: 0;
      border-radius: 2px;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      transform: rotate(0);
      background: linear-gradient(
        165.96deg,
        #2c2c2c 0.92%,
        #e7841b 52.52%,
        #222222 100%
      );
      transition: 0.5s;
    }

    &:hover:after {
      content: '';
      position: absolute;
      opacity: 1;
    }

    &:hover {
      svg {
        .rect1,
        .rect2 {
          height: 18.2128px;
        }

        .rect2 {
          y: 61px;
        }

        .rect3,
        .rect4 {
          height: 43.6752px;
        }

        .rect4 {
          y: 35px;
        }

        .circle-start {
          opacity: 0;
        }

        .circle-end {
          opacity: 1;
        }

        .top {
          transform: translate(3px, -3px);
        }

        .right {
          transform: translate(-3px, -3px);
        }

        .left {
          transform: translate(0, 3px);
        }

        .cloud {
          transform: translate(5px, 3px) scale(.9);
        }
      }

      .icon-arrow-left {
        transform: rotate(180deg) translateX(-20px);
      }
    }
  }

  .service-item__icon {
    width: 65px;
    position: relative;
    z-index: 2;
  }

  .service-item__text {
    position: relative;
    margin: 0 25px 10px 40px;
    z-index: 2;
    font-weight: 600;
    color: rgba(226, 226, 226, 1);
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: 0.02em;
    text-transform: capitalize;

    @include media-breakpoint-down(sm) {
      margin: 0 25px;
    }
  }

  &__item {
    width: 100%;
    height: 100%;
    display: flex;

    .image {
      width: 1920px;
      min-width: 100vw;
      position: absolute;
      height: 150%;
      background-size: 100%;
      overflow: visible;

      @include media-breakpoint-down(lg) {
        width: 120%;
      }

      &_1 {
        top: -100px;
        left: 0;
      }

      &_2 {
        top: 0;
        left: 100%;
      }
    }

    @for $i from 1 through 4 {
      &_#{$i} {
        .image {
          background-image: url(/static/images/parallax_#{$i}.svg);
        }
      }
    }

    &_1 {
      will-change: margin-left;
      animation: Gradient1 40s linear infinite;

      @include media-breakpoint-down(lg) {
        animation: none !important;
      }

      @media (min-width: 1921px) {
        animation: none !important;
      }
    }

    &_2 {
      will-change: margin-left, transform;
      animation: Gradient2 100s linear infinite;

      @include media-breakpoint-down(lg) {
        animation: none !important;
      }

      @media (min-width: 1921px) {
        animation: none !important;
      }
    }

    &_3 {
      will-change: margin-left, transform;
      animation: Gradient3 200s linear infinite;

      @include media-breakpoint-down(lg) {
        animation: none !important;
      }

      @media (min-width: 1921px) {
        animation: none !important;
      }
    }

    &_4 {
      will-change: margin-left, transform;
      animation: Gradient4 320s linear infinite;

      @include media-breakpoint-down(lg) {
        animation: none !important;
      }

      @media (min-width: 1921px) {
        animation: none !important;
      }
    }

    &_moving {
      position: absolute;
      top: 0;
    }
  }

  &__actions {
    position: fixed;
    right: 40px;
    bottom: 20px;
    display: flex;
    align-items: center;
    z-index: 99;
  }

  &__footer {
    display: flex;
    margin-top: auto;

    @include media-breakpoint-down(md) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__clutch {
    width: 230px;
    @include media-breakpoint-down(md) {
      margin: 10px 0;
      align-self: center;
    }

    .clutch-widget {
      transform: scale(.7)translateX(-55px);
      width: 271px;
    }
  }
}

@for $i from 1 through 4 {
  @keyframes Gradient#{$i} {
    0% {
      margin-left: 0;
      opacity: 0;
    }

    5% {
      opacity: 1;
    }

    10% {
      margin-left: -10%;
      opacity: 1;
    }

    20% {
      margin-left: -20%;
      opacity: 1;
    }

    30% {
      margin-left: -30%;
      opacity: 1;
    }

    40% {
      margin-left: -40%;
      opacity: 1;
    }

    50% {
      margin-left: -50%;
      opacity: 1;
    }

    60% {
      margin-left: -60%;
      opacity: 1;
    }

    70% {
      margin-left: -70%;
      opacity: 1;
    }

    80% {
      margin-left: -80%;
      opacity: 1;
    }

    90% {
      margin-left: -90%;
      opacity: 1;
    }

    95% {
      margin-left: -90%;
      opacity: 1;
    }

    100% {
      margin-left: -100%;
      opacity: 0;
    }
  }
}

.parallax__video {
  position: absolute;
  margin-top: 25px;
  padding-bottom: 45.25%;
  padding-top: 25px;
  height: 0;
  width: 85%;
  z-index: 4;
  top: 50vh;
  left: 50%;
  transform: translate(-50%, -50%);

  @media (max-width: 1024px) {
    width: 100%;
    margin-top: 0;
  }

  @include media-breakpoint-down(sm) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0;
    width: 100vw;
    height: calc(100vw * 0.5625);

    @supports (-webkit-overflow-scrolling: touch) {
      margin-left: 7%;
    }

    @media (orientation: landscape) {
      height: calc(100vw * 0.4825);
      margin-left: 0;
    }
  }

  &-container {
    width: calc(100vw - 210px);
    height: 100vh;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    max-width: 0;
    max-height: 0;
    z-index: 15;
    transition: opacity 0.7s ease 0.7s;

    @include media-breakpoint-down(md) {
      width: 100vw;
      position: absolute;
      left: 0;
      top: 0;
    }

    .video_interactive {
      transform: translateY(-200%);
      opacity: 0;
      transition: transform 0s ease 1s, opacity 1s ease;
    }

    &.active {
      opacity: 1;
      max-width: calc(100vw - 210px);
      max-height: 100vh;

      @include media-breakpoint-down(sm) {
        max-width: 100vw;
        width: 100vw;
      }

      .video_interactive {
        transform: translateY(0);
        width: 100%;
        opacity: 1;
        height: 100%;
        transition: transform 1s ease 0.5s, opacity 2s ease 0.5s;

        @include media-breakpoint-down(sm) {
          width: 100vw;
          min-width: 100vw;
        }
      }
    }

    &.active.first {
      .video_interactive {
        transition: transform 1.5s ease, opacity 2s ease;
      }
    }
  }
}

#mainVideo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.parallax-video_close {
  position: absolute;
  top: 40px;
  z-index: 15;
  display: block;
  width: 30px;
  height: 30px;
  left: 50%;
  font-size: 30px;
  transform: translate(-50%, -100px);
  background-size: cover;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0s ease 1s, transform 0.5s ease 0.5s;

  @include media-breakpoint-down(sm) {
    transform: none;
    transition: none;
    top: 5%;
  }

  &.active {
    opacity: 1;
    transform: translateX(-50%);
    transition: opacity 1s ease, transform 0.5s ease 0.5s;

    @include media-breakpoint-down(sm) {
      transition: none;
    }
  }
}

@keyframes pulse {
  0% {
    transform: translate(-50%, -50%) scale(0.9);
    opacity: 0.3;
  }
  25% {
    opacity: 0.3;
  }
  50% {
    transform: translate(-50%, -50%) scale(1.2);
    opacity: 0.4;
    border-width: 2px;
  }
  100% {
    transform: translate(-50%, -50%) scale(2);
    opacity: 0.2;
    border-width: 1px;
  }
}

.interactive-content {
  width: 85%;
  top: 50%;
  left: 50%;
  margin-top: 25px;
  transform: translate(-50%, -50%);
  position: absolute;
  z-index: 50;
  pointer-events: none;

  @include media-breakpoint-down(sm) {
    width: 100vw;
    margin-top: 0;
    height: calc(100vw * 0.5625);
  }

  &:after {
    display: block;
    z-index: -1;
    content: '';
    padding-bottom: 56%;
  }
}
