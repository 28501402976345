// styles for section video
.expertise-internal {
  section.video {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;

    .video-wrapper {
      width: 100%;
      padding-bottom: 56.25%; /* 16:9 */
      max-width: 1050px;
      position: relative;
      margin: 40px 0;

      @media (min-width: 1420px) {
        padding-bottom: 580px;
      }

      &.hytPlayerWrap {
        display: inline-block;
        position: relative;
      }

      &.general:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        cursor: pointer;
        background-color: black;
        background-repeat: no-repeat;
        background-position: center;
      }

      &.ended:after {
        background-size: 64px 64px;
        background-image: url(/static/images/youtube-reload.svg);
      }

      &.paused:after {
        background-size: 40px 40px;
        background-image: url(/static/images/youtube-pause.svg);
      }

      .item-video {
        width: 100%;
        height: 100%;
        max-height: 590.62px;
        top: 0;
        left: 0;
        position: absolute;
      }
    }
  }
}

.page-content__wrapper__about-us {

  .about-top__video_wrapper {

    &.hytPlayerWrap {
      display: inline-block;
      position: relative;
    }

    &.general:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      cursor: pointer;
      background-color: black;
      background-repeat: no-repeat;
      background-position: center;
    }

    &.ended:after {
      background-size: 64px 64px;
      background-image: url(/static/images/youtube-reload.svg);
    }

    &.paused:after {
      background-size: 40px 40px;
      background-image: url(/static/images/youtube-pause.svg);
    }
  }
}
