// styles for section why-we-are
.technology {
  section.why-we-are {

    p.section-subtitle {
      @include media-breakpoint-down(sm) {
        text-align: left;
      }
    }

    .text-note {
      color: #F29E32;
      font-weight: bold;

      @include media-breakpoint-down(sm) {
        text-align: left;
      }

      a {
        color: $accent;
        text-decoration: underline;
      }
    }

    .why-we-are-list {
      .list-item {
        display: flex;
        align-items: flex-start;
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;

        @include media-breakpoint-down(sm) {
          flex-direction: column;
          align-items: center;
          text-align: center;
        }

        .item-img {
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          background-color: $accent;
          width: 76px;
          min-width: 76px;
          height: 76px;
          padding: 16px;
          margin-right: 2rem;

          @include media-breakpoint-down(sm) {
            margin-right: 0;
          }

          span {
            color: #fff;
            font-size: 40px;
          }
        }

        .item-text {
          .text-title {
            font-weight: 600;

            @include media-breakpoint-down(sm) {
              margin: 1rem 0;
            }
          }
          .text-description {
            @include media-breakpoint-down(sm) {
              display: none;
            }
          }
        }
      }
    }
  }
}
