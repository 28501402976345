section.security-audit {
    background: linear-gradient(270.69deg, #E48D33 0.58%, #F3A442 99.41%);
    padding: 40px 55px 90px !important;

    @include media-breakpoint-down(md) {
        padding: 20px 30px 30px !important;
    }

    p, span, li {
        font-size: 16px;
        line-height: 26px;
        font-weight: 400;
        margin-bottom: 20px;
    }

    ul {
        list-style-type: disc;
        padding-left: 25px;
        margin-bottom: 20px;
    }

    span, li {
       margin: 0;
    }

    h2 {
        margin:0 0 20px;
        text-align: center;
        text-transform: uppercase;
    }

    a {
        font-weight: 600;
        color: #fff;

        &:hover {
            text-decoration: none;
        }
    }

    .items-wrapper {
        display: flex;
        margin: 30px 0;
        justify-content: center;
        flex-wrap: wrap;
        gap: 80px;

        @include media-breakpoint-down(md) {
            gap: 50px;
        }

        .item {
            max-width: 120px;
            text-align: center;

            .img-wrapper {
                background: #252525;
                border-radius: 50%;
                width: 90px;
                height: 90px;
                margin:0 auto 10px;
                display: grid;
                place-content: center;
            }
        }
    }

    .boxes-wrapper {
        display: flex;
        gap: 20px;
        margin-bottom: 20px;
        flex-wrap: wrap;

        @include media-breakpoint-down(md) {
            gap: 35px;
        }

        .box {
            background: rgba(249, 248, 248, 0.1);
            border-radius: 4px;
            padding: 20px;

            @include media-breakpoint-up(lg) {
                width: calc(100% / 2 - 10px);
            }
        }
    }
}