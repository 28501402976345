.about {
  background-image: linear-gradient(to left, #e27826 0%, #f5a535 100%);
  padding: 26px 29px 26px 29px;

  //100 vw - menu width + paddings
  width: calc(100vw - 210px);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  overflow: hidden;
  position: relative;
  height: 100vh !important;
  top: 0;
  left: 0;

  @include media-breakpoint-down(md) {
    display: block;
    width: 100%;
    height: auto !important;
  }

  @include media-breakpoint-down(sm) {
    display: flex;
  }

  .about-title{
    text-transform: uppercase;
    text-align: center;
    font-size: 30px;
    font-weight: 600;
    margin: 0 0 25px 0;
  }

  .main-section__content {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    height: calc(100% - 30px);

    @include media-breakpoint-down(md) {
      margin-top: 20px;
    }
    @include media-breakpoint-down(sm) {
      height: auto;
    }
  }

  &__top_flex {
    display: flex;
    align-items: center;
    max-height: 450px;

    @include media-breakpoint-down(md) {
      flex-direction: column;
      max-height: none;
      order: 1;
    }
  }

  &__boxes {
    display: flex;
    margin-top: 30px;
    overflow-x: visible;

    @include media-breakpoint-down(md) {
      flex-direction: column;
      align-items: center;
      margin-top: 20px;
      order: 3;
    }
  }

  &__box {
    //(100 vw - menu width + paddings )/ 4
    width: calc(25vw - 63.25px);
    //(100 vw - menu width + paddings )/ 4 * proportion
    height: calc(25vmin);
    max-height: 250px;
    border-left: 1px solid #713c12;

    @include media-breakpoint-down(md) {
      width: 80%;
      margin-top: 30px;
      height: 304px;
      border-left: none;
    }

    &:first-child {
      border-left: none;
    }

    .about-box__front-part {
      span {
        color: #fff;
        font-size: 100px;
        transition: font-size .2s ease-out 0s;
        will-change: font-size;
      }
    }
  }
}

.about-top {
  width: 100%;

  &__video {
    max-width: calc(50vw - 121.5px);
    align-self: center;
    margin-right: 15px;
    height: 40vh;
    width: 100%;

    @include media-breakpoint-down(md) {
      max-width: none;
      width: 100%;
      margin-right: 0;
      height: 35vw;
    }
    @include media-breakpoint-down(sm) {
      height: 40vh;
    }

    &_wrapper {
      min-width: 30%;
      width: 50%;
      display: flex;
      justify-content: center;
      align-items: center;

      @include media-breakpoint-down(md) {
        width: 100%;
      }
    }

  }

  &__text {
    margin-left: 15px;
    width: 50%;
    max-width: 70%;
    height: 40vh;
    overflow: auto;
    display: block;
    max-height: 40vh;
    padding: 15px;

    @include media-breakpoint-down(md) {
      max-width: none;
      width: 100%;
      height: auto;
      margin-left: 0;
      padding: 0;
      margin-top: 30px;
      max-height: 100%;
    }

    &_p {
      line-height: 1.5;
      @include interpolate(font-size, 768px, 1920px, 16px, 16px);

      @include media-breakpoint-down(sm) {
        @include interpolate(font-size, 320px, 767px, 16px, 20px);
      }
    }
  }
}

.about-box {
  overflow: visible;
  perspective: 55vw;

  transition: border 0s .5s;

  @include media-breakpoint-down(sm) {
    perspective: 215vw;
  }

  &:hover,
  &.active,
  &:focus {
    transition: border 0s;
    border-left-color: transparent;

    & ~ .about-box {
      transition: border 0s;
      border-left-color: transparent;
    }

    .about-box__front-part {
      opacity: 0;
      transform: translateY(-110px) rotateX(90deg);
    }

    .about-box__back-part {
      opacity: 1;
      transform: rotateX(0);
    }
  }

  &__inner {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 0;

  }

  &__front-part,
  &__back-part {
    position: absolute;
    top: 0;
    left: 0;
    vertical-align: middle;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    padding: 20px;
    overflow: hidden;
    transition: all .5s;
    box-shadow: 0 0 25px 10px rgba(35, 17, 1, 0.3);
  }

  &__front-part {
    opacity: 1;
    box-shadow: 0 0 25px 10px rgba(35, 17, 1, 0.3);
  }

  &__back-part {
    opacity: 0;
    background-color: #252525;
    transform: translateY(110px) rotateX(-90deg);

  }

  &__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__text {
    text-align: center;
    margin-top: -1vw;
    font-size: 14px;

    @include media-breakpoint-down(sm) {
      margin-top: -17px;
    }

    &:after {
      content: '';
      height: 1px;
      width: 36px;
      background-color: #fff;
      display: block;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      margin-top: 10px;
      transition: width .5s ease;

      @include media-breakpoint-down(sm) {
        margin-top: 5px;
      }
    }

    &_info {
      font-size: 18px;
      font-weight: 200;
      text-align: center;
      display: block;
    }
  }
}


.button-wrapper {
  display: flex;
  justify-content: center;
  margin: 50px 0 0;

  @include media-breakpoint-down(md) {
    order: 2;
    flex-wrap: wrap;
  }
}

.btn-careers {
  margin: 0 33px 0 auto;
  box-shadow: 0 0 25px 10px rgba(35, 17, 1, 0.43);
  max-width: 231px;
  min-width: 231px;
  background-color: #fff;
  color: #242424;
  max-height: 80px;
  overflow: hidden;
  border: none;
  position: relative;
  z-index: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  transition: background-color .5s cubic-bezier(1, .03, 1, -0.72), color .5s ease;

  @include media-breakpoint-down(md) {
    margin: 0 auto 20px;
  }

  @include media-breakpoint-down(sm) {
    min-width: 320px;
    margin-bottom: 40px;
  }

  &:active,
  &:focus,
  &:hover {
    outline: none;
  }

  &:hover {
    color: #ffffff;

    .btn-careers__icon {
      background-image: url("/static/images/animated-rocket_white.gif");
      background-color: #242424;
    }

    .btn-careers__text:before {
      transform: scale(6)
    }

    @include media-breakpoint-down(sm) {
      color: #242424;
      background-color: #fff;

    }
  }

  &__icon {
    display: inline-block;
    width: 60px;
    height: 60px;
    margin-right: 15px;
    border-radius: 50%;
    position: relative;
    z-index: 3;

    background-image: url("/static/images/rocket-static.webp");
    background-color: #242424;
    background-size: contain;
    background-repeat: no-repeat;

  }

  &__text {
    font-size: 21px;
    text-shadow: 0 1px 0 rgba(255, 255, 255, 0.23);
    line-height: 80px;

    &:before {
      content: '';
      width: 60px;
      height: 60px;
      background-color: #242424;
      display: inline-block;
      z-index: -1;
      position: absolute;
      left: 27px;
      top: 11px;
      border-radius: 50%;
      transition: transform .5s ease-in-out;

      @include media-breakpoint-down(sm) {
        display: none;
      }
    }
  }

  &.our-mission {
    display: flex;
    justify-content: flex-start;
    background-color: #252525;
    padding: 0 22px;
    margin: 0 auto 0 33px;
    min-width: 340px;
    min-height: 80px;
    height: 100%;

    @include media-breakpoint-down(sm) {
      min-width: 320px;
    }

    @include media-breakpoint-down(md) {
      margin: 0 auto;
    }

    .btn-careers__text {
      font-family: "Proxima Nova", sans-serif;
      font-size: 20px;
      color: #fff;
      text-shadow: 0 1px 0 rgba(255, 255, 255, 0.23);
      line-height: 24px;

      @include media-breakpoint-down(md) {
        font-size: 18px;
      }

      &:before {
        content: '';
        width: 60px;
        height: 60px;
        color: #fff;
        background-color: #fff;
        display: inline-block;
        z-index: -1;
        position: absolute;
        left: 22px;
        top: 11px;
        border-radius: 50%;
        transition: transform .5s ease-in-out;

        @include media-breakpoint-down(sm) {
          display: block;
        }
      }
    }

    .btn-mission__icon {
      display: inline-block;
      width: 61px;
      height: 61px;
      margin-right: 20px;
      position: relative;
      z-index: 3;

      background-image: url("/static/images/lamp-static.webp");
      background-size: contain;
      background-repeat: no-repeat;
    }

    &:hover {
      .btn-mission__icon {
        background-image: url("/static/images/animation-lamp.gif");
      }

      .btn-careers__text {
        color: #242424;

        &:before {
          transform: scale(11);
        }
      }
    }
  }
}