.faq {
    background: #161616;
    padding: 40px 85px 60px;

    &.land {
        display: block;
        padding: 30px;

        &.blog {
            padding: 20px 0;
            background: transparent;
        }
    }

    @include media-breakpoint-down(md) {
        padding: 20px;
    }

    &.bg-grey {
        background: #252525;
    }

    &-title {
        text-transform: uppercase;
        font-size: 24px;
        margin-bottom: 35px;

        &.orange {
            color: #E27826;
        }
    }

    &-item {
        &:not(:last-child) {
            padding-bottom: 20px;
        }

        p, span, li {
            font-size: 16px;
            line-height: 26px;
            font-weight: 400;
        }

        ul {
            list-style-type: disc !important;
            padding-left: 25px;
        }

        ol {
            padding-left: 25px;
        }

        &.showing {
            .faq-item__question span::after {
                transform: rotate(0);
            }
        }

        &__answer {
            display: none;
        }

        &__question {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 20px;
            cursor: pointer;

            h3 {
                font-size: 20px;
                color: #E27826;
                margin: 0;
                font-weight: 600;
            }

            span {
                width: 24px;
                height: 24px;
                background: linear-gradient(269.72deg, #D96600 0.19%, #F39F33 99.7%);
                border-radius: 50%;
                position: relative;
                flex-shrink: 0;
                margin-left: 10px;

                &::before, &::after {
                    content: '';
                    position: absolute;
                    top: 50%;
                    background-color: #fff;
                    width: 12px;
                    left: 6px;
                    height: 2px;
                    transition: all 0.5s ease;
                }

                &::after {
                    transform: rotate(90deg);
                }

                i {
                    visibility: hidden;
                }
            }
        }
    }

    &_departments {
       margin-top: 0 !important;
    }
}
