@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?xpfndl');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?xpfndl#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?xpfndl') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?xpfndl') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?xpfndl##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-iot-healthcare {
  &:before {
    content: $icon-iot-healthcare;     
    color: #fff;
  }
}
.icon-iot-ensures {
  &:before {
    content: $icon-iot-ensures;     
    color: #fff;
  }
}
.icon-iot-customer {
  &:before {
    content: $icon-iot-customer;     
    color: #fff;
  }
}
.icon-iot-application {
  &:before {
    content: $icon-iot-application;     
    color: #fff;
  }
}
.icon-iot-employees {
  &:before {
    content: $icon-iot-employees;     
    color: #fff;
  }
}
.icon-iot-infrastructure {
  &:before {
    content: $icon-iot-infrastructure;     
    color: #fff;
  }
}
.icon-iot-assembly {
  &:before {
    content: $icon-iot-assembly;     
    color: #e27826;
  }
}
.icon-iot-android {
  &:before {
    content: $icon-iot-android;     
    color: #e27826;
  }
}
.icon-iot-desktop {
  &:before {
    content: $icon-iot-desktop;     
    color: #e27826;
  }
}
.icon-wellness-automation {
  &:before {
    content: $icon-wellness-automation;     
    color: #fff;
  }
}
.icon-wellness-content {
  &:before {
    content: $icon-wellness-content;     
    color: #fff;
  }
}
.icon-wellness-solutions {
  &:before {
    content: $icon-wellness-solutions;     
    color: #fff;
  }
}
.icon-wellness-trackers {
  &:before {
    content: $icon-wellness-trackers;     
    color: #fff;
  }
}
.icon-wellness-data {
  &:before {
    content: $icon-wellness-data;     
    color: #fff;
  }
}
.icon-wellness-mobile {
  &:before {
    content: $icon-wellness-mobile;     
    color: #fff;
  }
}
.icon-wellness-mental {
  &:before {
    content: $icon-wellness-mental;     
    color: #e27826;
  }
}
.icon-wellness-workout {
  &:before {
    content: $icon-wellness-workout;     
    color: #e27826;
  }
}
.icon-wellness-nutrition {
  &:before {
    content: $icon-wellness-nutrition;     
    color: #e27826;
  }
}
.icon-wellness-lifecycle {
  &:before {
    content: $icon-wellness-lifecycle;     
    color: #e27826;
  }
}
.icon-booking {
  &:before {
    content: $icon-booking;     
    color: #e27826;
  }
}
.icon-expertise-advertising {
  &:before {
    content: $icon-expertise-advertising;     
    color: #fff;
  }
}
.icon-expertise-attractions {
  &:before {
    content: $icon-expertise-attractions;     
    color: #fff;
  }
}
.icon-expertise-big-data {
  &:before {
    content: $icon-expertise-big-data;     
    color: #fff;
  }
}
.icon-expertise-brain {
  &:before {
    content: $icon-expertise-brain;     
    color: #e27826;
  }
}
.icon-expertise-cafe {
  &:before {
    content: $icon-expertise-cafe;     
    color: #fff;
  }
}
.icon-expertise-consumer {
  &:before {
    content: $icon-expertise-consumer;     
    color: #e27826;
  }
}
.icon-expertise-engaging {
  &:before {
    content: $icon-expertise-engaging;     
    color: #fff;
  }
}
.icon-expertise-hotel {
  &:before {
    content: $icon-expertise-hotel;     
    color: #e27826;
  }
}
.icon-expertise-management {
  &:before {
    content: $icon-expertise-management;     
    color: #fff;
  }
}
.icon-expertise-marketing {
  &:before {
    content: $icon-expertise-marketing;     
    color: #fff;
  }
}
.icon-expertise-otas {
  &:before {
    content: $icon-expertise-otas;     
    color: #fff;
  }
}
.icon-expertise-paint {
  &:before {
    content: $icon-expertise-paint;     
    color: #e27826;
  }
}
.icon-expertise-pms {
  &:before {
    content: $icon-expertise-pms;     
    color: #e27826;
  }
}
.icon-expertise-search {
  &:before {
    content: $icon-expertise-search;     
    color: #e27826;
  }
}
.icon-expertise-service {
  &:before {
    content: $icon-expertise-service;     
    color: #e27826;
  }
}
.icon-expertise-software {
  &:before {
    content: $icon-expertise-software;     
    color: #e27826;
  }
}
.icon-expertise-ux-ui {
  &:before {
    content: $icon-expertise-ux-ui;     
    color: #e27826;
  }
}
.icon-scala {
  &:before {
    content: $icon-scala; 
  }
}
.icon-xamarin {
  &:before {
    content: $icon-xamarin; 
  }
}
.icon-bluetooth {
  &:before {
    content: $icon-bluetooth; 
  }
}
.icon-apple {
  &:before {
    content: $icon-apple; 
  }
}
.icon-bootstrap {
  &:before {
    content: $icon-bootstrap; 
  }
}
.icon-jquery {
  &:before {
    content: $icon-jquery; 
  }
}
.icon-mobile {
  &:before {
    content: $icon-mobile; 
  }
}
.icon-android {
  &:before {
    content: $icon-android; 
  }
}
.icon-angular {
  &:before {
    content: $icon-angular; 
  }
}
.icon-ba {
  &:before {
    content: $icon-ba; 
  }
}
.icon-devops {
  &:before {
    content: $icon-devops; 
  }
}
.icon-flutter {
  &:before {
    content: $icon-flutter; 
  }
}
.icon-go {
  &:before {
    content: $icon-go; 
  }
}
.icon-graphic-design {
  &:before {
    content: $icon-graphic-design; 
  }
}
.icon-html-5 {
  &:before {
    content: $icon-html-5; 
  }
}
.icon-html-css {
  &:before {
    content: $icon-html-css; 
  }
}
.icon-ionic_2 {
  &:before {
    content: $icon-ionic_2; 
  }
}
.icon-js {
  &:before {
    content: $icon-js; 
  }
}
.icon-java {
  &:before {
    content: $icon-java; 
  }
}
.icon-kotlin {
  &:before {
    content: $icon-kotlin; 
  }
}
.icon-managers {
  &:before {
    content: $icon-managers; 
  }
}
.icon-motion-design {
  &:before {
    content: $icon-motion-design; 
  }
}
.icon-nodejs {
  &:before {
    content: $icon-nodejs; 
  }
}
.icon-objc {
  &:before {
    content: $icon-objc; 
  }
}
.icon-phonegap {
  &:before {
    content: $icon-phonegap; 
  }
}
.icon-php-1 {
  &:before {
    content: $icon-php-1; 
  }
}
.icon-ppc {
  &:before {
    content: $icon-ppc; 
  }
}
.icon-python-1 {
  &:before {
    content: $icon-python-1; 
  }
}
.icon-qa-automation {
  &:before {
    content: $icon-qa-automation; 
  }
}
.icon-qa-manual {
  &:before {
    content: $icon-qa-manual; 
  }
}
.icon-react {
  &:before {
    content: $icon-react; 
  }
}
.icon-ruby-on-rails_2 {
  &:before {
    content: $icon-ruby-on-rails_2; 
  }
}
.icon-seo {
  &:before {
    content: $icon-seo; 
  }
}
.icon-smm {
  &:before {
    content: $icon-smm; 
  }
}
.icon-swift {
  &:before {
    content: $icon-swift; 
  }
}
.icon-ui-ux {
  &:before {
    content: $icon-ui-ux; 
  }
}
.icon-vue {
  &:before {
    content: $icon-vue; 
  }
}
.icon-css3 {
  &:before {
    content: $icon-css3; 
  }
}
.icon-marketing2 {
  &:before {
    content: $icon-marketing2; 
  }
}
.icon-c {
  &:before {
    content: $icon-c; 
  }
}
.icon-aspnet {
  &:before {
    content: $icon-aspnet; 
  }
}
.icon-qa {
  &:before {
    content: $icon-qa; 
  }
}
.icon-pm {
  &:before {
    content: $icon-pm; 
  }
}
.icon-about_1 {
  &:before {
    content: $icon-about_1; 
  }
}
.icon-about_2 {
  &:before {
    content: $icon-about_2; 
  }
}
.icon-about_3 {
  &:before {
    content: $icon-about_3; 
  }
}
.icon-about_4 {
  &:before {
    content: $icon-about_4; 
  }
}
.icon-blockchain-1 {
  &:before {
    content: $icon-blockchain-1; 
  }
}
.icon-blockchain-2 {
  &:before {
    content: $icon-blockchain-2; 
  }
}
.icon-Frame1 {
  &:before {
    content: $icon-Frame1; 
  }
}
.icon-Frame2 {
  &:before {
    content: $icon-Frame2; 
  }
}
.icon-Frame3 {
  &:before {
    content: $icon-Frame3; 
  }
}
.icon-blockchain-3 {
  &:before {
    content: $icon-blockchain-3; 
  }
}
.icon-blockchain-4 {
  &:before {
    content: $icon-blockchain-4; 
  }
}
.icon-business-5 {
  &:before {
    content: $icon-business-5; 
  }
}
.icon-business-6 {
  &:before {
    content: $icon-business-6; 
  }
}
.icon-business-7 {
  &:before {
    content: $icon-business-7; 
  }
}
.icon-business-8 {
  &:before {
    content: $icon-business-8; 
  }
}
.icon-business-9 {
  &:before {
    content: $icon-business-9; 
  }
}
.icon-healthcare-1 {
  &:before {
    content: $icon-healthcare-1; 
  }
}
.icon-healthcare-2 {
  &:before {
    content: $icon-healthcare-2; 
  }
}
.icon-healthcare-3 {
  &:before {
    content: $icon-healthcare-3; 
  }
}
.icon-healthcare-4 {
  &:before {
    content: $icon-healthcare-4; 
  }
}
.icon-healthcare-5 {
  &:before {
    content: $icon-healthcare-5; 
  }
}
.icon-healthcare-6 {
  &:before {
    content: $icon-healthcare-6; 
  }
}
.icon-healthcare-7 {
  &:before {
    content: $icon-healthcare-7; 
  }
}
.icon-healthcare-8 {
  &:before {
    content: $icon-healthcare-8; 
  }
}
.icon-healthcare-9 {
  &:before {
    content: $icon-healthcare-9; 
  }
}
.icon-healthcare-10 {
  &:before {
    content: $icon-healthcare-10; 
  }
}
.icon-healthcare-11 {
  &:before {
    content: $icon-healthcare-11; 
  }
}
.icon-healthcare-12 {
  &:before {
    content: $icon-healthcare-12; 
  }
}
.icon-healthcare-13 {
  &:before {
    content: $icon-healthcare-13; 
  }
}
.icon-tourism-1 {
  &:before {
    content: $icon-tourism-1; 
  }
}
.icon-tourism-2 {
  &:before {
    content: $icon-tourism-2; 
  }
}
.icon-tourism-3 {
  &:before {
    content: $icon-tourism-3; 
  }
}
.icon-tourism-4 {
  &:before {
    content: $icon-tourism-4; 
  }
}
.icon-iot-1 {
  &:before {
    content: $icon-iot-1; 
  }
}
.icon-iot-2 {
  &:before {
    content: $icon-iot-2; 
  }
}
.icon-iot-3 {
  &:before {
    content: $icon-iot-3; 
  }
}
.icon-Frame4 {
  &:before {
    content: $icon-Frame4; 
  }
}
.icon-Frame5 {
  &:before {
    content: $icon-Frame5; 
  }
}
.icon-Frame6 {
  &:before {
    content: $icon-Frame6; 
  }
}
.icon-iot-4 {
  &:before {
    content: $icon-iot-4; 
  }
}
.icon-marketing-1 {
  &:before {
    content: $icon-marketing-1; 
  }
}
.icon-marketing-2 {
  &:before {
    content: $icon-marketing-2; 
  }
}
.icon-marketing-3 {
  &:before {
    content: $icon-marketing-3; 
  }
}
.icon-marketing-4 {
  &:before {
    content: $icon-marketing-4; 
  }
}
.icon-marketing-5 {
  &:before {
    content: $icon-marketing-5; 
  }
}
.icon-facebook {
  &:before {
    content: $icon-facebook; 
  }
}
.icon-instagram {
  &:before {
    content: $icon-instagram; 
  }
}
.icon-elephant {
  &:before {
    content: $icon-elephant; 
  }
}
.icon-etsy {
  &:before {
    content: $icon-etsy; 
  }
}
.icon-linkedin {
  &:before {
    content: $icon-linkedin; 
  }
}
.icon-twitter {
  &:before {
    content: $icon-twitter; 
  }
}
.icon-Frame7 {
  &:before {
    content: $icon-Frame7; 
  }
}
.icon-Frame8 {
  &:before {
    content: $icon-Frame8; 
  }
}
.icon-Frame9 {
  &:before {
    content: $icon-Frame9; 
  }
}
.icon-Frame10 {
  &:before {
    content: $icon-Frame10; 
  }
}
.icon-startup-1 {
  &:before {
    content: $icon-startup-1; 
  }
}
.icon-startup-2 {
  &:before {
    content: $icon-startup-2; 
  }
}
.icon-startup-3 {
  &:before {
    content: $icon-startup-3; 
  }
}
.icon-startup-4 {
  &:before {
    content: $icon-startup-4; 
  }
}
.icon-startup-5 {
  &:before {
    content: $icon-startup-5; 
  }
}
.icon-startup-6 {
  &:before {
    content: $icon-startup-6; 
  }
}
.icon-backend {
  &:before {
    content: $icon-backend; 
  }
}
.icon-design {
  &:before {
    content: $icon-design; 
  }
}
.icon-frontend {
  &:before {
    content: $icon-frontend; 
  }
}
.icon-marketing {
  &:before {
    content: $icon-marketing; 
  }
}
.icon-qa-pm {
  &:before {
    content: $icon-qa-pm; 
  }
}
.icon-phone {
  &:before {
    content: $icon-phone; 
  }
}
.icon-check {
  &:before {
    content: $icon-check; 
  }
}
.icon-chevron-right {
  &:before {
    content: $icon-chevron-right; 
  }
}
.icon-share {
  &:before {
    content: $icon-share; 
  }
}
.icon-map-marker {
  &:before {
    content: $icon-map-marker; 
  }
}
.icon-chevron-left {
  &:before {
    content: $icon-chevron-left; 
  }
}
.icon-arrow-left {
  &:before {
    content: $icon-arrow-left; 
  }
}
.icon-dedicated-benefits_1 {
  &:before {
    content: $icon-dedicated-benefits_1; 
  }
}
.icon-dedicated-benefits_2 {
  &:before {
    content: $icon-dedicated-benefits_2; 
  }
}
.icon-dedicated-benefits_3 {
  &:before {
    content: $icon-dedicated-benefits_3; 
  }
}
.icon-dedicated-benefits_4 {
  &:before {
    content: $icon-dedicated-benefits_4; 
  }
}
.icon-dedicated-benefits_5 {
  &:before {
    content: $icon-dedicated-benefits_5; 
  }
}
.icon-dedicated-benefits_6 {
  &:before {
    content: $icon-dedicated-benefits_6; 
  }
}
.icon-dedicated-benefits_7 {
  &:before {
    content: $icon-dedicated-benefits_7; 
  }
}
.icon-dedicated-benefits_8 {
  &:before {
    content: $icon-dedicated-benefits_8; 
  }
}
.icon-expertise-int_1 {
  &:before {
    content: $icon-expertise-int_1; 
  }
}
.icon-expertise-int_2 {
  &:before {
    content: $icon-expertise-int_2; 
  }
}
.icon-expertise-int_3 {
  &:before {
    content: $icon-expertise-int_3; 
  }
}
.icon-expertise-int_4 {
  &:before {
    content: $icon-expertise-int_4; 
  }
}
.icon-google {
  &:before {
    content: $icon-google; 
  }
}
.icon-mail {
  &:before {
    content: $icon-mail; 
  }
}
.icon-Pause {
  &:before {
    content: $icon-Pause; 
  }
}
.icon-yahoo {
  &:before {
    content: $icon-yahoo; 
  }
}
.icon-Play {
  &:before {
    content: $icon-Play; 
  }
}
.icon-play_up {
  &:before {
    content: $icon-play_up; 
  }
}
.icon-quotes {
  &:before {
    content: $icon-quotes; 
  }
}
.icon-white-cross {
  &:before {
    content: $icon-white-cross; 
  }
}

