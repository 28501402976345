.slider-mega-wrapper {
  margin: 0 !important;

  & > section {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .section-title {
    text-transform: initial;
    color: #fff;
    margin-bottom: 35px;
  }
}
