@import "../../../fonts/icomoon/variables.scss";

.startups_testimonials {
  background-color: #fff;

  &_wrap {
    padding-bottom: 30px;

    @include media-breakpoint-down(sm) {
      padding-bottom: 0;
    }
  }

  .testimonials-slider {
    padding-bottom: 30px;
  }
}

.testimonials-slider {
  background-color: #fff;
  color: #000;
  width: 100%;

  .slick-list {
    min-height: 100%;
  }

  .slick-slide {
    height: 0 !important;
    transition: all 0.3s;

    &.slick-active {
      height: 100% !important;
      transition: all 0.3s;
    }
  }

  &__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 13%;

    @include media-breakpoint-down(sm) {
      width: 100%;
      padding: 15px 25px;
    }
  }

  &__top {
    display: flex;
    align-items: center;
  }

  &__photo {
    border-radius: 50%;
    margin-right: 20px;
    width: 60px;
    height: auto;

    @include media-breakpoint-down(sm) {
      width: 20vw;
    }
  }

  &__cv {
    display: flex;
    flex-direction: column;
  }

  &__name {
    font-size: 20px;
    display: inline-block;
    line-height: 24px;
    letter-spacing: 0.02em;
    font-weight: 600;
    margin: 0;
  }

  &__position,
  &__address {
    font-size: 15px;
    margin-bottom: 0;

    @include media-breakpoint-down(sm) {
      font-size: 18px;
    }
  }

  &__bottom {
    position: relative;
    margin-top: 10px;
  }

  &__text {
    font-size: 16px;
    line-height: 1.5;
    text-align: center;
    margin-bottom: 0;
    font-style: italic;

    &:before,
    &:after {
      font-family: 'icomoon';
      font-size: 10px;
      content: $icon-quotes;
      position: absolute;
      top: 0;
      line-height: 1;
    }

    &:before {
      left: -40px;

      @include media-breakpoint-down(sm) {
        left: -15px;
      }
    }

    &:after {
      right: -40px;

      @include media-breakpoint-down(sm) {
        right: -15px;
      }
    }
  }

  &__prev,
  &__next {
    position: absolute;
    opacity: .3;
    z-index: 3;
    cursor: pointer;
    top: 50%;
    transition: opacity .3s ease;

    &:hover {
      opacity: 1;
    }

    @include media-breakpoint-down(sm) {
      display: none !important;
    }
  }

  &__prev {
    left: 40px;
    transform: rotate(180deg) translateY(50%);
  }

  &__next {
    transform: translateY(-50%);
    right: 40px;
  }

  .slick-dots {
    li {
      button {
        padding: 0;

        &:before {
          font-size: 10px;
          border: 2px solid #ccc;
          border-radius: 50%;
          width: 17px;
          height: 17px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          content: '';
          background-color: transparent;
        }
      }

      &.slick-active {
        button {
          &:before {
            background-color: #e69035;
            border-color: #e69035;
          }
        }
      }
    }
  }
}

.testimonials-title {
  font-size: 24px;
  padding: 40px 0 10px 0;
  text-align: center;
  color: #252525;
  margin: 0;
  text-transform: uppercase;
  font-weight: 500;
}