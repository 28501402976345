// styles for section facts
.expertise-internal {
  section.success-history {

    a {
      color: $accent;
      text-decoration: underline;
    }

    background-color: #FFFFFF;
    color: #252525;

    .section-title {
      color: #252525;
    }

    .text-note {
      color: $accent;
    }

    #text-more {
      display: none;
    }
  }
}
