// styles for section facts
.hipaa-landing {
  .what-guide {
    padding-bottom: 0;
    @media all and (max-width: 768px) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  ol {
    padding-left: 0;
  }

  li.text-title {
    list-style-position: inside;
    font-weight: 600;
    margin-bottom: 60px;

    @media all and (max-width: 1024px) {
      margin-bottom: 30px;
    }

    p {
      display: inline;
    }
    .table-wrapper {
      margin-top: 30px;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }

  table.head-table {
    table {
      width: 100%;
    }

    background-color: #fff;
    max-width: 1460px;
    margin: 30px auto 0 auto;
    color: #252525;

    tr {
      border: 1px solid #DCDCDC;

      &:first-child {
        border-top: none;
      }

      &:last-child {
        border-bottom: none;
      }
    }

    .title-cell {
      max-width: 190px;
      border-right: 1px solid #DCDCDC;
    }

    .content-cell {
      padding: 0;
      tr {
        border-left: none;
        border-right: none;
      }
      td {
        padding: 10px 15px;
        font-size: 16px;
        font-weight: 400;
        line-height: 1.25;
        @media all and (max-width: 768px) {
          padding: 10px 5px;
        }
      }
    }

  }

  h3 {
    font-weight: 600;
    font-size: 18px;
    padding: 0 20px;
    @media all and (max-width: 768px) {
      padding: 0 5px;
    }
  }
}