.author {
    background-color: #252525;

    @include media-breakpoint-down(sm) {
        padding-top: 100px;
    }

    &_title {
        text-align: center;
        margin-top: 0;

        h1 {
            font-size: 43px;
            margin-top: 0;
            padding-top: 15px;
        }
    }

    &_info {
        background-color: #2E2E2E;
        padding: 20px;
        display: flex;
        justify-content: center;
        width: 100%;
        max-width: 1100px;
        margin: 20px auto 0;

        @include media-breakpoint-down(md) {
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            padding: 50px 20px 20px;
        }
    }

    &_avatar {
        width: 30%;
        display: flex;
        flex-direction: column;
        align-items: center;

        @include media-breakpoint-down(md) {
            width: 100%;
        }
    }

    &_img {
        width: 170px;
        height: 170px;
        border-radius: 50%;
        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            line-height: 0;
        }
    }

    &_position {
        display: flex;
        flex-direction: column;
        text-align: center;

        .h2 {
            margin: 5px 0;
        }
    }

    &_company {
        color: #e27826;
    }

    &_content {
        width: 70%;
        display: flex;
        flex-direction: column;

        @include media-breakpoint-down(md) {
            width: 100%;
            margin-top: 20px;
        }

        .h3 {
            margin-top: 0;
        }
    }

    &_about {
        flex-grow: 1;
        max-width: 650px;
    }

    &_social {
        margin-top: 15px;
        
        @include media-breakpoint-down(md) {
            margin: 15px auto;
        }

        a {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            width: 30px;
            height: 30px;
            margin-right: 15px;
            color: #808080;

            &:hover {
                color: #e27826;
            }
        }

        span {
            font-size: 30px;
        }
    }

    &_blog {
        margin-top: 40px;
        padding-bottom: 40px;

        .h2 {
            text-align: center;
            color: #e27826;
            display: inline-flex;
            justify-content: center;
            width: 100%;
        }

        .blog__content {
            width: 100%;
            max-width: 740px;
            margin: 0 auto;
        }
    }
}