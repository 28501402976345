.popup {
  width: 100%;
  padding: 40px 64px 40px;
  display: none;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;

  @include media-breakpoint-down(sm) {
    padding: 25px 30px;
  }

  &__wrapper {
    width: 442px;
    top: 50%;
    z-index: 500;
    transform: translate(-50%, -50%);
    left: calc(50% + 105px);
    max-height: 90%;
    overflow: auto;
    position: fixed;
    overflow-x: hidden;

    @include media-breakpoint-down(sm) {
      left: 50%;
      width: 100%;
      max-width: 600px;
      padding-right: 20px;
      padding-left: 20px
    }

    &_contacts {
      height: auto;
      transform: translate(-50%, 85%);
      left: 50%;
      top: auto;
      bottom: 0;
      position: absolute;
      transition: transform .2s ease, margin .2s ease;
      cursor: pointer;

      @include media-breakpoint-up(md) {

        > * {
          pointer-events: none;
          cursor: pointer;
        }

        &.active {

          > * {
            pointer-events: auto;
          }
        }
      }

      @include media-breakpoint-down(sm) {
        width: 100%;
        max-width: 100%;
        padding-left: 0;
        padding-right: 0;
        position: relative;
        transform: none;
        left: 0;
      }

      &:hover {
        @include media-breakpoint-down(sm) {
          transform: translate(0);
        }
      }

      &.active {
        margin-bottom: 50vh;

        transform: translate(-50%, 50%);
      }
    }

    &_blog-welcome {
      position: static;
      width: 100%;
      flex-shrink: 0;
      display: flex;
      justify-content: center;
      transform: none;
      overflow-y: auto;
      overflow-x: hidden;
      margin-top: 30px;
      margin-bottom: 30px;
    }

    &_why-bekey,
    &_why-bekey_startup {
      position: static;
      flex-shrink: 0;
      display: flex;
      justify-content: center;
      transform: none;
      overflow-y: auto;
      overflow-x: hidden;

      .popup__close {
        display: none;
      }
    }
  }

  &_contacts {
    display: flex;
    cursor: pointer;

    @include media-breakpoint-down(sm) {
      padding: 40px 60px;
    }
  }

  &__close {
    position: absolute;
    font-size: 17px;
    right: 17px;
    top: 20px;
    transform: scale(0.8);
    cursor: pointer;
    transition: transform .3s ease;

    &:hover {
      transform: scale(1);
    }
  }

  &__title {
    text-align: center;
    margin: 10px 0 0 0;
    font-size: 20px;

    &_contacts {
      @include media-breakpoint-down(sm) {
        font-size: 40px;
      }
    }

    &_careers {
      width: 120%;
      margin-left: -10%;
    }
  }

  &__input,
  &__textarea {
    width: 100%;
    padding-left: 16px;
    font-size: 15px;
    display: block;
    margin-top: 20px;
    border-radius: 0;
    border: none;
    font-family: 'Roboto', sans-serif;
    color: #242424;

    @include media-breakpoint-down(sm) {
      font-size: 14px;
    }

    &::-webkit-input-placeholder,
    &::-moz-placeholder,
    &:-ms-input-placeholder,
    &:-moz-placeholder {
      color: #cacaca;
    }

    @include media-breakpoint-down(sm) {
      &_contacts {
        font-size: 14px;
        padding: 12px;
        margin-top: 18px;
      }
    }
  }

  &__input {
    height: 46px;
  }

  &__textarea {
    height: 115px;
    padding-top: 10px;
    max-height: 20vh;
    resize: vertical;
  }

  &__file {
    left: 50%;
    position: relative;
    transform: translateX(-50%);
    margin-top: 20px;
    cursor: pointer;

    &_default {
      opacity: 0 !important;
      margin-left: 70px;
      margin-top: 50px;
      position: absolute;
      height: 0;
    }

    &_clip {
      width: 60px;
      height: 60px;
      padding: 15px;
      border-radius: 50%;
      border: 1px solid #fff;
      transition: border-color .7s ease;
    }

    span {
      opacity: .7;
      font-family: 'Roboto', sans-serif;
      font-weight: 300;
      position: absolute;
      display: inline-block;
      max-width: 200px;
      bottom: 0;
      min-width: 100px;

      @include media-breakpoint-down(xs) {
        max-width: 75px;
        min-width: 0;
      }

      &:first-child {
        left: 0;
        transform: translateX(-100%)
      }

      &:last-child {
        min-width: 150px;
        transform: translateX(70px)
      }
    }
  }

  &__send {
    margin-top: 20px;
    height: 47px;
    width: 172px;
    margin-left: 50%;
    transform: translateX(-50%);

    &:disabled{
      &:hover{
        background: transparent;
        color: #fff;
      }
    }

    @include media-breakpoint-down(sm) {
      margin-top: 20px;
      height: 47px;
      width: 172px;
    }

    &_contacts {
      @include media-breakpoint-down(sm) {
        height: 11.2vw;
        font-size: 3.59vw;
        width: 39vw;
        margin-top: 3.8vw;
      }
    }
  }
}

.popup__shadow {
  position: fixed;
  display: none;
  width: 200vw;
  left: -100vw;
  top: -100vh;
  z-index: 480;
  height: 200vh;
  background-color: rgba(#000, .3);
}

.popup_contacts {
  .popup__close {
    @include media-breakpoint-down(sm) {
      display: none;
    }
  }
}

// SVG ATTACH-iCON
.attach {
  position: relative;
  width: 64px;
  height: 64px;
  box-shadow: inset 0 0 0 1px #eee;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.6s ease, box-shadow 0s 1.7s;
}

.attach:hover {
  box-shadow: inset 0 0 0 1px #ddd;
}

.attach svg {
  position: absolute;
  top: 0;
  left: 0;
}

.attach svg circle {
  stroke-dasharray: 195;
  stroke-dashoffset: 585;
  transition: all 1.5s ease, stroke 0s;
  transition-delay: 0.2s;
}

.attach svg #doc {
  transform: translate(21px, 80px);
  transition: all 1.4s ease;
  transition-delay: .3s;
}

.attach svg #clip-front,
.attach svg #clip-back {
  transform: translate(1px, 2px);
  transition: all 1.4s ease;
  transition-delay: .3s;
}

.attach.active {
  animation: bounce 0.4s forwards;
  box-shadow: inset 0 0 0 1px transparent;
  transition: box-shadow 0s;
  margin: 0 auto;
}

.attach circle {
  stroke: "rgba(255,255,255,0.5)";
}

.attach.active svg circle {
  stroke-dasharray: 195;
  stroke-dashoffset: 390;
}

.attach.active.success svg circle {
  stroke: "#5AA834";
}

.attach.error svg circle {
  stroke: "red";
}

.attach.active.success svg #doc {
  transform: translate(21px, 20px);
}

.attach.active.success svg #clip-front,
.attach.active.success svg #clip-back {
  transform: translate(1px, 0);
}

.popup__file .attached {
  position: relative;
  transform: translateX(-7px) !important;
  text-align: center;
  min-width: 300px !important;
}

@-moz-keyframes bounce {
  50% {
    transform: scale(0.95);
  }
}

@-webkit-keyframes bounce {
  50% {
    transform: scale(0.95);
  }
}

@-o-keyframes bounce {
  50% {
    transform: scale(0.95);
  }
}

@keyframes bounce {
  50% {
    transform: scale(0.95);
  }
}

//THANX POPUP
.popup {
  &_thanx {
    padding: 86px 80px 70px 80px;

    @include media-breakpoint-down(sm) {
      padding: 35px 20px 20px;
    }
  }

  &_blog-welcome {
    display: block;
    width: 442px;
    height: auto;

    .popup__close {
        display: none;
    }
  }

  &_why-bekey,
  &_why-bekey_startup {
    display: block;
    width: 442px;

    @include media-breakpoint-down(md) {
      width: 100%;
    }

    @include media-breakpoint-down(sm) {
      overflow-y: hidden;
    }
    //ON MOBILE IN CLOSED FORM
    .popup__close {
      @include media-breakpoint-down(sm) {
        display: none;
      }
    }

    .captcha-wrapper {
      @include media-breakpoint-down(sm) {
        max-height: 0;
        opacity: 0;
        margin: 0;
        transition: max-height .3s ease, opacity .3s ease .2s;
      }
    }

    input, textarea {
      @include media-breakpoint-down(sm) {
        opacity: 0;
        margin: 0;
        padding: 0;
        max-height: 0;
        visibility: hidden;
        transition: max-height 1s ease, opacity .3s ease .2s;
      }
    }

    .popup__send {
      @include media-breakpoint-down(sm) {
        display: none;
      }
    }

    .agree-wrapper {
      @include media-breakpoint-down(sm) {
        display: none;
      }
    }

    .popup__title {
      @include media-breakpoint-down(sm) {
        margin-top: 0;
      }
    }

    //ON MOBILE IN OPENED FORM
    &.opened {
      .popup__input,
      .popup__textarea,
      .popup__send {

        @include media-breakpoint-down(sm) {
          opacity: 1;
          margin-top: 20px;
          padding-left: 15px;
          max-height: 100%;
          visibility: visible;
          display: block;
        }
      }

      .captcha-wrapper {
        @include media-breakpoint-down(sm) {
          max-height: 300px;
          opacity: 1;
          margin-top: 20px;
        }
      }

      textarea {
        @include media-breakpoint-down(sm) {
          padding-top: 10px;
        }
      }

      .popup__title {

        @include media-breakpoint-down(sm) {
          margin-top: 0;
        }
      }

      .agree-wrapper {
        display: block;
      }

      .popup__send {
        display: block;
        margin-left: 50%;
      }
    }
  }

  &__title_thanx {
    font-family: 'Montserrat', sans-serif;
    font-weight: 200;
    font-size: 42px;
    white-space: nowrap;
    line-height: 1;

    @include media-breakpoint-down(sm) {
      white-space: normal;
      font-size: 38px;
    }
  }

  &__text_thanx {
    margin-top: 35px;
    font-family: 'Roboto', sans-serif;
    font-size: 17px;
    font-weight: 300;
    text-align: center;
  }

  &__follow-us_thanx {
    font-size: 14px;
    opacity: .5;
    text-align: center;
    font-family: 'Roboto', sans-serif;
  }

  &__social_thanx {
    width: 70%;
    left: 50%;
    transform: translateX(-50%);
    position: relative;
    display: flex;
    justify-content: space-between;
    list-style-type: none;
    padding: 0 15px;
  }
}
