$icomoon-font-family: "icomoon" !default;
$icomoon-font-path: "../fonts/icomoon/fonts" !default;

$icon-iot-healthcare: "\e976";
$icon-iot-ensures: "\e977";
$icon-iot-customer: "\e978";
$icon-iot-application: "\e97a";
$icon-iot-employees: "\e97b";
$icon-iot-infrastructure: "\e97c";
$icon-iot-assembly: "\e97d";
$icon-iot-android: "\e97e";
$icon-iot-desktop: "\e97f";
$icon-wellness-automation: "\e96a";
$icon-wellness-content: "\e96b";
$icon-wellness-solutions: "\e96c";
$icon-wellness-trackers: "\e96d";
$icon-wellness-data: "\e96e";
$icon-wellness-mobile: "\e970";
$icon-wellness-mental: "\e972";
$icon-wellness-workout: "\e973";
$icon-wellness-nutrition: "\e974";
$icon-wellness-lifecycle: "\e975";
$icon-booking: "\e943";
$icon-expertise-advertising: "\e948";
$icon-expertise-attractions: "\e94a";
$icon-expertise-big-data: "\e94e";
$icon-expertise-brain: "\e94f";
$icon-expertise-cafe: "\e950";
$icon-expertise-consumer: "\e951";
$icon-expertise-engaging: "\e952";
$icon-expertise-hotel: "\e953";
$icon-expertise-management: "\e95c";
$icon-expertise-marketing: "\e95d";
$icon-expertise-otas: "\e95e";
$icon-expertise-paint: "\e95f";
$icon-expertise-pms: "\e960";
$icon-expertise-search: "\e961";
$icon-expertise-service: "\e962";
$icon-expertise-software: "\e963";
$icon-expertise-ux-ui: "\e968";
$icon-scala: "\e800";
$icon-xamarin: "\e801";
$icon-bluetooth: "\e802";
$icon-apple: "\e803";
$icon-bootstrap: "\e804";
$icon-jquery: "\e805";
$icon-mobile: "\e806";
$icon-android: "\e807";
$icon-angular: "\e808";
$icon-ba: "\e809";
$icon-devops: "\e80b";
$icon-flutter: "\e80c";
$icon-go: "\e80d";
$icon-graphic-design: "\e80e";
$icon-html-5: "\e80f";
$icon-html-css: "\e810";
$icon-ionic_2: "\e811";
$icon-js: "\e812";
$icon-java: "\e813";
$icon-kotlin: "\e814";
$icon-managers: "\e815";
$icon-motion-design: "\e816";
$icon-nodejs: "\e817";
$icon-objc: "\e818";
$icon-phonegap: "\e819";
$icon-php-1: "\e81a";
$icon-ppc: "\e81b";
$icon-python-1: "\e81c";
$icon-qa-automation: "\e81d";
$icon-qa-manual: "\e81e";
$icon-react: "\e81f";
$icon-ruby-on-rails_2: "\e820";
$icon-seo: "\e821";
$icon-smm: "\e822";
$icon-swift: "\e823";
$icon-ui-ux: "\e824";
$icon-vue: "\e825";
$icon-css3: "\e826";
$icon-marketing2: "\e827";
$icon-c: "\e828";
$icon-aspnet: "\e82a";
$icon-qa: "\e900";
$icon-pm: "\e901";
$icon-about_1: "\e902";
$icon-about_2: "\e903";
$icon-about_3: "\e904";
$icon-about_4: "\e905";
$icon-blockchain-1: "\e906";
$icon-blockchain-2: "\e907";
$icon-Frame1: "\e908";
$icon-Frame2: "\e909";
$icon-Frame3: "\e90a";
$icon-blockchain-3: "\e90b";
$icon-blockchain-4: "\e90c";
$icon-business-5: "\e90d";
$icon-business-6: "\e90e";
$icon-business-7: "\e90f";
$icon-business-8: "\e910";
$icon-business-9: "\e911";
$icon-healthcare-1: "\e912";
$icon-healthcare-2: "\e913";
$icon-healthcare-3: "\e914";
$icon-healthcare-4: "\e915";
$icon-healthcare-5: "\e916";
$icon-healthcare-6: "\e917";
$icon-healthcare-7: "\e918";
$icon-healthcare-8: "\e919";
$icon-healthcare-9: "\e91a";
$icon-healthcare-10: "\e91b";
$icon-healthcare-11: "\e91c";
$icon-healthcare-12: "\e91d";
$icon-healthcare-13: "\e91e";
$icon-tourism-1: "\e91f";
$icon-tourism-2: "\e920";
$icon-tourism-3: "\e921";
$icon-tourism-4: "\e922";
$icon-iot-1: "\e923";
$icon-iot-2: "\e924";
$icon-iot-3: "\e925";
$icon-Frame4: "\e926";
$icon-Frame5: "\e927";
$icon-Frame6: "\e928";
$icon-iot-4: "\e929";
$icon-marketing-1: "\e92a";
$icon-marketing-2: "\e92b";
$icon-marketing-3: "\e92c";
$icon-marketing-4: "\e92d";
$icon-marketing-5: "\e92e";
$icon-facebook: "\e92f";
$icon-instagram: "\e930";
$icon-elephant: "\e931";
$icon-etsy: "\e932";
$icon-linkedin: "\e933";
$icon-twitter: "\e934";
$icon-Frame7: "\e935";
$icon-Frame8: "\e936";
$icon-Frame9: "\e937";
$icon-Frame10: "\e938";
$icon-startup-1: "\e939";
$icon-startup-2: "\e93a";
$icon-startup-3: "\e93b";
$icon-startup-4: "\e93c";
$icon-startup-5: "\e93d";
$icon-startup-6: "\e93e";
$icon-backend: "\e93f";
$icon-design: "\e940";
$icon-frontend: "\e941";
$icon-marketing: "\e942";
$icon-qa-pm: "\e944";
$icon-phone: "\e945";
$icon-check: "\e946";
$icon-chevron-right: "\e947";
$icon-share: "\e949";
$icon-map-marker: "\e94b";
$icon-chevron-left: "\e94c";
$icon-arrow-left: "\e94d";
$icon-dedicated-benefits_1: "\e954";
$icon-dedicated-benefits_2: "\e955";
$icon-dedicated-benefits_3: "\e956";
$icon-dedicated-benefits_4: "\e957";
$icon-dedicated-benefits_5: "\e958";
$icon-dedicated-benefits_6: "\e959";
$icon-dedicated-benefits_7: "\e95a";
$icon-dedicated-benefits_8: "\e95b";
$icon-expertise-int_1: "\e964";
$icon-expertise-int_2: "\e965";
$icon-expertise-int_3: "\e966";
$icon-expertise-int_4: "\e967";
$icon-google: "\e969";
$icon-mail: "\e96f";
$icon-Pause: "\e971";
$icon-yahoo: "\e979";
$icon-Play: "\e984";
$icon-play_up: "\e985";
$icon-quotes: "\e988";
$icon-white-cross: "\e997";

