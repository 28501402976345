.expertise {
  width: 100%;
  min-height: 100vh;
  background-color: #000;
  background-image: url(/static/images/bekey_flamed.webp);
  background-size: 70% auto;
  background-repeat: no-repeat;
  background-position: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 0;

  @include media-breakpoint-down(sm) {
    height: 100%;
    padding-bottom: 35px;
    background-image: none;
  }

  &_head {
    margin-top: 30px;

    @include media-breakpoint-down(sm) {
      margin-top: 110px;
    }
  }

  &-title {
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 600;
    text-align: center;

    @include media-breakpoint-up(md) {
      font-size: 30px;
    }
  }

  &-content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    max-width: 1024px;
    margin: auto;

    .expertise-svg {
      height: 60px;

      @include media-breakpoint-down(md) {
        height: 50px;
      }

      @include media-breakpoint-down(sm) {
        height: 70px;
      }
    }
  }

  &-box {
    width: calc((100% / 3) - 30px);
    margin: 15px;
    box-shadow: 0 0 34px 21px rgba(35, 17, 1, 0.53);

    @include media-breakpoint-down(md) {
      width: calc(50% - 20px);
      margin: 10px;
    }

    @include media-breakpoint-down(sm) {
      width: 100%;
      margin: 15px 25px;
    }

    &:hover,
    &:active,
    &:focus {
      color: #fff;
      text-decoration: none;
    }

    a {
      display: inline-flex;
      justify-content: center;
      width: 100%;
      height: 100%;
      color: #fff;

      &:nth-child(3n + 1) {
        background-color: #e57f28;
        background-image: linear-gradient(to left, #d96600 0%, #e6831a 40%, #e6831a 80%, #f39f33 100%);
      }

      &:nth-child(3n + 2) {
        background-color: #e7832a;
        background-image: linear-gradient(to left, #ac5202 0%, #dd6800 100%);
      }

      &:nth-child(3n) {
        background-color: #e7842a;
        background-image: linear-gradient(to left, #6c3402 0%, #b45704 100%);
      }

      &:nth-child(odd) {
        @include media-breakpoint-down(sm) {
          background-color: #e57f28;
          background-image: linear-gradient(to left, #d96600 0%, #e6831a 40%, #e6831a 80%, #f39f33 100%);
          box-shadow: 0 0 68px 42px rgba(35, 17, 1, 0.53);
        }
      }

      &:nth-child(even) {
        @include media-breakpoint-down(sm) {
          background-color: #e7832a;
          background-image: linear-gradient(to left, #ac5202 0%, #dd6800 100%);
          box-shadow: 0 0 68px 42px rgba(35, 17, 1, 0.53);
        }
      }
    }

    &_wrap {
      padding: 25px 15px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
    }

    &_img {
      display: flex;
      justify-content: center;
    }

    &_icon {
      max-width: 80px;

      @include media-breakpoint-down(md) {
          max-width: 60px;
      }
    }

    &-title {
      h2 {
        font-weight: 200;
        text-align: center;
        text-transform: uppercase;
        display: flex;
        font-size: 24px;
        align-items: center;
        justify-content: center;
        margin-top: 0;

        @include media-breakpoint-down(md) {
          font-size: 20px;
        }
      }
    }
  }

  .departments_info {
    a {
      border-bottom: 1px dashed #dbdada;
    }
  }

  &_section {
    h1 {
      text-align: center;
    }

    .text-block {
      p,
      li {
        margin-bottom: 20px !important;
      }
    }
  }

  &_grid {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0 auto 15px;
    max-width: 800px;
  }

  &_item {
    width: calc((100% / 3) - 20px);
    margin: 10px;
    padding: 20px;
    border: 1px solid #fff;
    text-align: center;

    @include media-breakpoint-down(md) {
      width: calc(50% - 20px);
    }

    @include media-breakpoint-down(xs) {
      width: 100%;
      display: flex;
      align-items: center;
    }
  }

  &_title {
    text-transform: uppercase;
  }

  &_icon {
    width: 30px;
    max-width: 30px;
    margin: 0 auto 20px;

    @include media-breakpoint-down(xs) {
      width: 40px;
      max-width: 40px;
      min-width: 40px;
      margin: 0 15px;
    }
  }
}