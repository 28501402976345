@import "../../mixins/bs_breakpoints.scss";

.portfolio {

  @include media-breakpoint-down(sm) {
    margin-top: 110px !important;
  }


  &_head {
    text-align: center;
    
    h1 {
      color: #E27826;
      font-weight: 600;

      @include media-breakpoint-down(sm) {
        font-size: 24px;
      }
    }
  }

  &_main {
    width: 100%;
    padding-bottom: 10px;
    position: relative;

      &_wrap {
        overflow: hidden;
        overflow-x: auto;
      }

      .wrap_blur {
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        width: 100%;
        max-width: 300px;
        background: linear-gradient(to right, rgba(0,0,0,0) 0%,rgba(0,0,0,0.9) 100%); ;
        z-index: 2;

        @include media-breakpoint-down(md) {
          max-width: 0 !important;
          width: 0 !important;
        }
      }
  }

  .main {
    &_row {
      display: flex;
    }

    &_item {
      width: 370px;
      min-width: 370px;
      flex-grow: 1;
      text-decoration: none !important;

      @include media-breakpoint-down(sm) {
        width: 300px;
        min-width: 300px;
        padding: 5px 10px;
      }

      &:not(.additional):hover {
        .main {
          &_img {
            img {
              transform: scale(1.08);
            }
          }

          &_content {
            background: linear-gradient(269.72deg, #D96600 0.19%, #F39F33 99.7%);
            color: #fff;
            text-decoration: none !important;
          }

          &_action {
            visibility: visible;
            display: block;
          }

          &_categories {
            visibility: hidden;
            opacity: 0;
            display: none;
          }
        }
      }
    }

    &_img {
      position: relative;
      z-index: 1;
      line-height: 1;

      img {
        width: 100%;
        min-width: inherit;
        transition: .5s;
      }
    }

    &_content {
      position: relative;
      z-index: 1;
      overflow: hidden;
      padding: 0 15px 15px;
      transition: .5s;
      color: #fff;
    }

    &_action {
      visibility: hidden;
      display: none;
      transition: .5s;
    }

    &_title {
      text-transform: uppercase;
      padding-top: 15px;
      
      h3 {
        font-weight: 600;
        font-size: 16px;
        margin-top: 0;
      }
    }

    &_desc {
      font-size: 14px;
      font-weight: 600;
      min-height: 105px;

      @include media-breakpoint-down(sm) {
        font-size: 13px;
      }
    }

    &_categories {
      margin-top: 15px;
      position: relative;
      transition: .5s;
      height: auto;
      display: flex;
      align-items: center;

      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 2px;
        background-color: #E27826;
      }

      span {
        font-weight: 600;
        color: #fff;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 12px;
        display: inline-flex;
        margin-top: 20px;

        &:not(:last-child) {
          margin-right: 15px;
        }

        @include media-breakpoint-down(sm) {
          margin-top: 10px;
        }
      }
    }
  }

  &_contacts {
    margin-bottom: 60px !important;
  }
}

.additional {
  display: flex;
  flex-direction: column;
  padding: 15px;

  &_img {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      min-width: 200px;
    }
  }

  &_logo {
    position: relative;

    &:before,
    &:after {
      content: '';
      position: absolute;
      width: 20px;
      height: 20px;
      border: solid #fff;
    }

    &::before {
      left: 0;
      top: 0;
      border-width: 1px 0 0 1px;
    }

    &::after {
      right: 0;
      bottom: 0;
      border-width: 0 1px 1px 0;
    }
  }

  &_head {
    text-align: center;
    font-size: 32px;
    text-transform: uppercase;
    font-weight: 600;
    color: #fff;

    @include media-breakpoint-down(sm) {
      font-size: 24px;
    }
  }

  &_action {
    display: flex;
    justify-content: center;
    margin-top: 30px;

    @include media-breakpoint-down(sm) {
      margin: 20px 0;
    }

    .btn_more {
      display: flex;
      align-items: center;
      justify-self: center;
      position: relative;
      min-width: 173px;
      font-size: 14px;
      line-height: 1;
      font-weight: 600;
      text-transform: uppercase;
      border-radius: 5px;
      color: #fff;
      padding: 12px 22px;
      background-color: linear-gradient(269.72deg, #d96600 .19%, #f39f33 99.7%);
      transition: .4s;
      border: 1px solid transparent;
      box-shadow: none;
      cursor: pointer;

      &:hover {
        text-decoration: none;
        background: rgba(226, 120, 38, .2);
        border-color: #d96600;
      }
    }
  }
}

.cases {
  padding: 0 15px;
  margin-top: 0;

  @include media-breakpoint-down(sm) {
    margin-top: 120px !important;
  }

  &_head {
    text-align: center;
    margin: 10px 0;
    
    h1 {
      font-weight: 600;
      text-transform: uppercase;
      margin: 10px 0;
    }
  }

  .nav-tabs {
    border-bottom: 0;
  }

  &_tab {
    border-bottom: 2px solid transparent;

    &.active,
    &:hover {
      border-color: #D96600;

      a {
        background-color: transparent !important;
        border: 0 !important;
        color: #D96600 !important;
        text-decoration: none !important;
        line-height: 1.2 !important;
      }
    }

    a {
      font-size: 14px;
      border: 0 !important;
      font-weight: 600;
      color: #999999;
      text-transform: uppercase;
      padding: 7px !important;
      line-height: 1.2 !important;
    }
  }

  &_tab_content:not(.active) {
    display: none !important;
  }

  &_row {
    margin: 0 -15px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    @include media-breakpoint-down(sm) {
      margin: 15px 0;
      justify-content: center;
    }
  }

  &_item {
    width: calc((100% / 3) - 30px);
    margin: 15px;
    height: inherit;
    max-height: 270px;
    overflow: hidden;
    position: relative;


    @include media-breakpoint-down(md) {
      width: calc(50% - 30px);
      max-height: 190px;
    }

    @include media-breakpoint-down(sm) {
      width: 100%;
      max-width: 270px;
    }

    &:hover {
      .cases_title {
        bottom: calc(100% - 50px);
      }

      .cases_action {
        top: 0;
      }
    }
  }

  &_img {
    width: 100%;
    height: 100%;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(180deg, rgba(34, 34, 34, 0) 0%, #222222 100%);
      transition: .5s;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top;
    }
  }

  &_title {
    position: absolute;
    z-index: 1;
    padding: 15px;
    left: 0;
    bottom: 0;
    transition: .5s;
    z-index: 2;

    h3 {
       color: #fff;
       font-weight: 600 !important;
       font-size: 16px !important;
       margin: 0;
    }
  }

  &_action {
    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(270deg,#d96600 0,#f39f33);
    transition: .5s;
    z-index: 1;
    display: flex;
    align-items: flex-end;
    padding: 10px;
  }
}

.internal {
  background-color: #252525;
  margin-top: 0 !important;
  padding-top: 50px;

  @include media-breakpoint-down(sm) {
    padding-top: 120px;
  }

  h2 {
    font-size: 24px;
    margin: 15px 0;
    font-weight: 500;
    text-transform: uppercase;
  }

  h3 {
    font-weight: 600;
    font-size: 22px;
  }

  p {
    font-size: 16px;
    font-weight: 400;
    color: #F1F1F1;
    line-height: 26px;
  }

  .uppercase {
    text-transform: uppercase;
  }

  &_hero {
    padding: 20px;

    @include media-breakpoint-down(sm) {
      padding: 0;
    }

    img {
      width: 100%;
    }
  }

  &_row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -20px;

    @include media-breakpoint-down(sm) {
      margin: 0 -20px;
    }
  }

  &_item {
    width: calc(50% - 40px);
    margin: 0 20px;

    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }

  &_problem {
    padding: 40px;

    @include media-breakpoint-down(sm) {
      padding: 20px;
    }
  }

  &_challenge {
    background-color: #444444;
    padding: 40px;

    @include media-breakpoint-down(sm) {
      padding: 20px;
    }

    .internal_item {
      padding-bottom: 30px;
    }
  }

  &_cta {
    background: linear-gradient(269.72deg, #D96600 0.19%, #F39F33 99.7%);
    padding: 20px;

    .well-content p {
      font-weight: 600;
    }
  }

  &_solution {
    padding: 40px;

    @include media-breakpoint-down(sm) {
      padding: 20px;
    }
  }

  &_tech {
    padding: 40px;
    background-color: #444444;

    @include media-breakpoint-down(sm) {
      padding: 20px;
    }

    h2 {
      text-align: center;
      margin-bottom: 20px;
    }
  }

  &_review {
    padding: 40px;
    background-color: #444444;

    @include media-breakpoint-down(sm) {
      padding: 20px;
    }
  }

  &_contact {
    padding: 20px 80px;

    @include media-breakpoint-down(sm) {
      padding: 20px;
    }

    &-wrap {
      max-width: 700px;
      margin: 0 auto;
    }
  }

  &_related {
    padding: 40px;

    @include media-breakpoint-down(sm) {
      padding: 20px;
    }
  }

  &_up {
    padding-bottom: 30px;

    a {
      margin: 0 !important;
    }
  }
}

.promo {
  &_title {
    text-align: center;
  }

  &_top {
    padding: 40px;

    @include media-breakpoint-down(sm) {
      padding: 20px;
    }
  }

  &_bottom {
    display: flex;
    justify-content: center;
    position: relative;

    &:after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 80%;
      background-color: #F19B30;
      ;
    }

    img {
      width: 100%;
      max-width: 800px;
      position: relative;
      z-index: 1;
    }
  }
}

.challenge {
  &_head {
    display: flex;
    align-items: center;
  }

  &_icon {
    width: 76px;
    min-width: 76px;
    height: 76px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #E27826;
    margin-right: 15px;
  }

  &_content {
    margin-top: 15px;
  }
}

.solution {
  &_table {
    padding-left: 0;
    margin-top: 15px;
    li {
      position: relative;
      padding: 10px 0;

      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 1px;
        background: radial-gradient(ellipse at center, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 72%);
      }

      &:last-child::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 1px;
        background: radial-gradient(ellipse at center, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 72%);
      }
    }

    span {
      font-size: 18px;
      text-decoration: none !important;
      font-weight: 500;
      color: #999;
      cursor: pointer;

      &.active,
      &:hover {
        color: #D96600;
        text-decoration: none !important;
      }
    }
  }

  &_img {
    position: relative;
    border-radius: 8px;
    overflow: hidden;

    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 50%;
      background: linear-gradient(269.72deg, #D96600 0.19%, #F39F33 99.7%);
    }

    img {
      width: 100%;
      z-index: 1;
      position: relative;
    }
  }

  &_nav {
    display: block;

    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  &_content {
    height: 100%;
    max-height: 400px;
    overflow: hidden;
    overflow-y: auto;

    .sensiu & {
      max-height: 500px;
    }

    .habitu & {
      max-height: 550px;
    }

    @include media-breakpoint-down(sm) {
      max-height: inherit !important;
    }
  }
}

.tech {
  &_row {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
  }

  &_item {
    margin: 10px;
    max-width: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;

    span {
      font-size: 16px;
      font-weight: 400;
      color: #F1F1F1;
      text-align: center;
    }
  }

  &_icon {
    width: 80px;
    min-width: 80px;
    height: 80px;
    overflow: hidden;
    border-radius: 50%;
    background: linear-gradient(269.72deg, #D96600 0.19%, #F39F33 99.7%);
    padding: 1px;
    position: relative;
    margin-bottom: 7px;
  }

  &_img {
    display: flex;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    background-color: #444444;
  }
}

.result {
  &_top {
    padding: 40px;

    @include media-breakpoint-down(sm) {
      padding: 20px;
    }

    p {
      margin-bottom: 0;
    }
  }

  &_bottom {
    position: relative;
    display: flex; 
    justify-content: center;
    padding: 0 40px;

    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 45%;
      background: linear-gradient(269.72deg, #D96600 0.19%, #F39F33 99.7%);
    }

    img {
      width: 100%;
      max-width: 800px;
      z-index: 1;
    }
  }
}

.related {
  h2 {
    text-align: center;
    color: #D96600;
    text-transform: none;
    font-size: 25px;
  }
}

.review {
  max-width: 800px;
  margin: 0 auto;
  padding: 0 40px;

  &_images {
    max-width: inherit;
    padding: 0 20px;

    @include media-breakpoint-down(sm) {
      padding: 0;
    }
  }

  &_head {
    text-align: center;

    @include media-breakpoint-down(sm) {
      text-align: left;
    }
  }

  &_banner {
    img {
      height: auto;
      width: 100%;
    }
  }

  &_author {
    display: flex;
    margin-bottom: 15px;
  }

  &_avatar {
    width: 50px;
    min-width: 50px;
    height: 50px;
    overflow: hidden;
    border-radius: 50%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &_info {
    margin-left: 15px;
    display: flex;
    flex-direction: column;
  }

  &_name {
    font-size: 20px;
    font-weight: 600;
  }

  &_content {
    font-style: italic;
    position: relative;

    &:before,
    &:after {
      position: absolute;
      top: 5px;
      font-size: 48px;
      font-weight: 600;
    }

    &::before {
      content: '“';
      left: -40px;
    }

    &:after {
      content: '”';
      right: -20px;
    }
  }
}

.front_pagination {
  position: static !important;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 10px 0 0 !important;

  @include media-breakpoint-down(sm) {
    margin: 10px 10px 20px !important;
  }

  & li:last-child > span,
  & li:first-child > span {
    border-radius: 0 !important;
  }
}

.list_item.visible {
  display: block;
}