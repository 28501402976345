@import './../../mixins/bs_breakpoints';

.scroll_btn {
    position: absolute;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #e27826;
    top: calc(50% - 20px);
    z-index: 3;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    @include media-breakpoint-down(md) {
        display: none !important;
    }

    &-prev {
        left: 20px;
        transform: rotate(-135deg);
        display: none;

        &:before {
            transform: translate(-3px, 2px);
        }
    }

    &-next {
        right: 20px;
        transform: rotate(45deg);

        &:before {
            transform: translate(-3px, 2px);
        }
    }
    
    &:before {
        content: '';
        width: 15px;
        height: 15px;
        border: solid #fff;
        border-width: 2px 2px 0 0;
    }
}