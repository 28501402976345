//STYLES FOR DESKTOP
.departments-internal {
  @include media-breakpoint-down(sm) {
    display: none;
  }
}

.departments-internal,
.departments-int-mob {
  background-color: #252525;
  position: relative;
  padding: 28px;

  .main-section__content {
    padding-top: 110px;
  }

  &__top {
    display: flex;
    align-items: center;

    &:before {
      content: '';
      width: 100%;
      height: 100%;
      background-image: url(/static/images/figures.webp);
      background-position: 100px 0;
      background-size: contain;
      display: block;
      opacity: 0.1;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  &__bottom {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    position: relative;
  }

  &__box {
    width: 50%;
    height: calc(50vh - 28px);
    padding: 4vh;
    flex-shrink: 0;
    background-image: linear-gradient(to left, rgba(#e27826, .99) 0%, rgba(#f5a535, .9) 100%);
    background-size: 300% 100%;
    -webkit-animation: Gradient 7.5s ease infinite;
    -moz-animation: Gradient 7.5s ease infinite;
    animation: Gradient 7.5s ease infinite;
  }

  &__icon {

    @include media-breakpoint-down(md) {
      transition: none;
    }
  }

  [class^="icon-"] {
    color: #fff;
  }

  .icon-nodejs {
    font-size: 25px;
  }

  .icon-go,
  .icon-ruby-on-rails_2 {
    font-size: 40px;
  }

  .icon-scala {
    font-size: 45px;
  }

  .icon-devops {
    font-size: 45px;
  }

  .icon-php-1,
  .icon-aspnet {
    font-size: 30px;
  }

  .icon-java {
    font-size: 60px;
  }

  .icon-c {
    font-size: 40px;
  }

  .icon-python-1,
  .icon-apple {
    font-size: 35px;
  }

  .icon-graphic-design,
  .icon-motion-design {
    font-size: 35px;
  }

  .icon-ui-ux {
    font-size: 40px;
  }

  .icon-marketing2,
  .icon-smm,
  .icon-ppc,
  .icon-seo {
    font-size: 35px;
  }

  .icon-managers {
    font-size: 50px;
  }

  .icon-ba,
  .icon-qa-manual,
  .icon-qa-automation {
    font-size: 35px;
  }

  .icon-js {
    font-size: 35px;
  }

  .icon-html-css {
    font-size: 45px;
  }

  .icon-angular {
    font-size: 35px;
  }

  .icon-react,
  .icon-vue {
    font-size: 30px;
  }

  .icon-flutter {
    font-size: 45px;
  }

  .icon-android {
    font-size: 40px;
  }

  &__icon-list {
    list-style-type: none;
    z-index: 2;
    height: calc(50vh - 28px);
    flex-direction: column;
    display: flex;
    justify-content: center;
    margin-bottom: 0;
    padding-left: 0;
    width: 100%;
    align-items: flex-start;

    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  &__icon-list-item {
    display: flex;
    align-items: center;
    margin-top: 1.45vh;
    margin-bottom: 1.45vh;
    cursor: pointer;
    margin-left: 20%;

    @include media-breakpoint-down(lg) {
      margin-left: 10%;
    }

    @include media-breakpoint-down(sm) {
      margin-left: 20%;
    }

    @media screen and (max-width:1600px) {
      margin-bottom: 0;
      margin-top: 0;
    }

    &.active,
    &:hover {
      .departments-internal__icon-text {
        color: #e27826;
        opacity: 1;
        text-decoration: underline;
        text-decoration-color: #e27826;
      }

    }
  }

  &__icon {
    width: 100%;
    max-width: 70px;
    display: inline-block;
    height: auto;
    line-height: 45px;
    flex-shrink: 0;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50%;
    text-align: center;

    .icon-apple {
      font-size: 35px;
    }

    &-wide {
      svg {
        width: 100%;
        max-width: 35px;
        height: auto;
      }
    }

    &_centered {
      position: absolute;

      &-top {
        left: 50%;
        top: 7px;
        transform: translate(-50%, -50%) scale(1);
        transform-origin: center;
        animation: moveIcon-top .7s ease forwards;

        svg {
          height: 3vh;
          width: auto;
          max-width: 80px;
        }
      }

      &-bottom {
        left: 0;
        top: 50%;
        transform: translate(-50%, -50%) scale(1);
        transform-origin: center;
        animation: moveIcon-bottom .7s ease forwards;

        svg {
          height: auto;
          width: 3vh;
        }

        .icon-php-1{
          position: relative;
          left: -15px;
        }

        .icon-aspnet,
        .icon-flutter,
        .icon-html-css,
        .icon-js,
        .icon-managers,
        .icon-ba,
        .icon-motion-design,
        .icon-seo,
        .icon-ppc {
          position: relative;
          left: -10px;
        }

        .icon-java,
        .icon-python-1 {
          position: relative;
          left: -5px;
        }

        .icon-html-css {
          font-size: 25px;
        }
      }
    }

    svg {
      vertical-align: middle;
    }

    #asp * {
      stop-color: #fff;
      fill: #fff;
    }

    .icon-stroke-color {
      stroke: #fff;
    }

    .icon-color {
      fill: #fff;

      &-dark {
        fill: #ccc;
      }

      &-light {
        fill: #fff;
        opacity: 0.5;
      }
    }

    &-list-item {
      &.active {
        .icon-color {
          fill: $brand-primary;
          color: $brand-primary;

          &-light {
            fill: $brand-primary;
            color: $brand-primary;
          }

          &-dark {
            fill: darken($brand-primary, 20%);
            color: darken($brand-primary, 20%);
          }
        }

        .icon-stop-color {
          stop-color: $brand-primary;
        }

        .icon-stroke-color {
          stroke: $brand-primary;
        }

        #asp * {
          stop-color: $brand-primary;
          fill: $brand-primary;
        }
      }
    }
  }

  &__icon-text {
    margin-left: 40px;
    font-size: 18px;
    font-weight: 200;
    opacity: .73;
    display: flex;
    align-items: center;
    padding-right: 20px;
    min-width: 120px;

    @include media-breakpoint-down(lg) {
      display: block;
    }
  }
}

.departments-internal-box,
.departments-int-mob-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 2;
  position: relative;

  .technology-text_hidden {
    display: block;
  }

  &__title {
    font-weight: 200;
    font-size: 30px;
    opacity: .73;
    text-align: center;
    margin-top: 0;
  }

  &__text {
    overflow-y: auto;
    @include interpolate(font-size, 768px, 1920px, 14px, 15px);
  }

  &__mouse {
    margin-top: 5vh;
    min-height: 33px;

    &.mouse {
      display: none
    }
  }
}

.technology-text {
  h2 {
    font-size: 18px;
  }

  &_hidden {
    display: none;
  }
}

// styles for sticky btn hiredeveloper
.hiredeveloper-btn-wrp {
  display: none;
  position: absolute;
  bottom: 90px;
  left: 0;
  width: 100%;
}

.departments-int-mob-box .hiredeveloper-btn-wrp,
.departments-internal-box .hiredeveloper-btn-wrp {
  display: block;
}

.technology-hire {
  +.mouse.departments-internal-box__mouse {
    margin-top: 13vh;
  }
}

@keyframes moveIcon-top {
  from {
    position: absolute;
    z-index: 5;
    opacity: 0;
    transform: translate(-50%, -50%) scale(1);
  }

  to {
    position: absolute;
    z-index: 125;
    transform: translate(-50%, -50%) scale(1.8);
  }
}

@keyframes moveIcon-bottom {
  from {
    position: absolute;
    z-index: 5;
    opacity: 0;
    transform: translate(-50%, -50%) scale(1);
  }

  to {
    position: absolute;
    z-index: 125;
    transform: translate(-50%, -50%) scale(1.8);
  }
}

//STYLES FOR MOBILE

.departments-int-mob {
  width: 100vw;
  overflow-x: hidden;
  display: none;
  min-height: 100vh;

  @include media-breakpoint-down(sm) {
    display: block;
  }

  height: auto;

  img {
    @include media-breakpoint-down(sm) {
      max-width: 100px;
    }
  }

  &:before {
    content: '';
    width: 100%;
    height: 100%;
    background-image: url(/static/images/figures.webp);
    background-position: 100px 0;
    background-size: cover;
    display: block;
    opacity: 0.1;
    position: absolute;
    top: 0;
    z-index: 0;
    left: 0;
  }

  &__dots-container {
    height: auto;
    position: relative;

    .slick-dots span {
      min-width: 120px;
    }
  }

  &__icon-text {
    margin-left: 20px;
    padding: 0;
  }

}

.departments-int-mob-box {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  z-index: 0;

  &__title {
    width: 100%;
  }

  &__text {
    overflow: hidden;

    &_p {
      line-height: 1.5;
      @include interpolate(font-size, 320px, 767px, 16px, 22px)
    }
  }
}

.departments-int-mob-box__text~a {
  color: #fff;
  line-height: 1.5;
  font-size: 5.6vw;
  margin-top: 15px;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

.departments-slider {
  width: 100%;

  &__arrow {
    width: 50px;
    height: 50px;
    transition: transform .3s ease;
    position: absolute;
    z-index: 55;
    top: 0;

    img {
      width: 50px;
      height: 50px;
    }

    &-left {
      left: 0;
      transform: translate(-40%, 50%);

      &.active {
        transform: translate(-100%, 50%);
      }
    }

    &-right {
      right: 0;
      transform: translate(40%, 50%);

      &.active {
        transform: translate(100%, 50%);
      }
    }
  }

  &__item {
    overflow-y: visible;
    position: relative;
    width: 100%;
  }

  &-item {
    &__button {
      left: 50%;
      position: relative;
      transform: translateX(-50%);
      margin-top: 10px;
    }
  }

  &__icon {
    z-index: 10;
    width: 50px;
    height: 50px;
    position: relative;
    background-size: cover;
    display: block;
    display: flex;
    justify-content: center;
  }
}

.departments-image-slider {
  height: 30px;
  position: absolute;
  margin-top: -50px;
  left: 0;
  width: 100vw !important;
  z-index: 5;

  &__item_wrapper {
    display: flex !important;
    justify-content: center;

    img {
      height: 75px;
      width: 75px;
    }

  }
}

@keyframes Gradient {
  0% {
    background-position: 0 50%
  }

  50% {
    background-position: 100% 50%
  }

  100% {
    background-position: 0 50%
  }
}


.departments-int-mob__icon.departments-int-mob__icon_cp.departments-int-mob__icon.departments-slider__icon {
  width: 160px;
  height: 101px;
}

.departments-int-mob__icon.icon_apple {
  width: 50px;
  height: 50px;
  top: -5px;
}

.slick-dots {
  .departments-int-mob & {
    li {
      height: auto;
    }
  }

  svg {
    vertical-align: middle;
    width: 50px;
    max-height: 40px;

    &.wide:not(.ha) {
      width: 90px;
    }
  }

  .departments-dots-item_hybrid-apps {
    svg {
      height: 90px;
      max-height: 90px;
    }

    .departments-int-mob__icon-text {
      line-height: 20px;
      text-align: left;
      display: flex;
    }
  }

  span {
    vertical-align: middle;
    line-height: 40px;
  }

  .icon-stroke-color {
    stroke: #fff;
  }

  #asp * {
    stop-color: #fff;
    fill: #fff;
  }

  .icon-color {
    fill: #fff;

    &-dark {
      fill: #ccc;
    }

    &-light {
      fill: #fff;
      opacity: 0.5;
    }
  }

  .slick-active {
    .icon-stroke-color {
      stroke: $brand-primary;
    }

    .icon-color {
      fill: $brand-primary;
      color: $brand-primary;

      &-light {
        fill: $brand-primary;
      }

      &-dark {
        fill: darken($brand-primary, 20%);
      }
    }

    .icon-stop-color {
      stop-color: $brand-primary;
    }

    #asp * {
      stop-color: $brand-primary;
      fill: $brand-primary;
    }
  }
}

.departments-int-mob__icon.wide {
  max-width: 90px;
  width: 100%;
}

.departments-additional {
  .text-block {
    margin-bottom: 25px !important;
  }
}

.technology {
  &_list {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }

  &_item {
    width: calc(100% / 3);
    padding: 10px 15px;
    margin-bottom: 10px;

    @include media-breakpoint-down(md) {
      width: 100%;
      padding: 0;
    }

    & > span {
      text-decoration: underline;
    }
  }

  &_sublist {
    list-style-type: disc;
    padding-left: 20px;
  }
}