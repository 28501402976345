.careers {
  width: 100%;
  background-color: #252525;
  padding: 4vh 1.45vw 3.4vh 1.45vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include media-breakpoint-down(sm) {
    padding-left: 10px;
    padding-right: 10px;
    min-height: 100vh !important;
  }

  &__title {
    text-align: center;
  }

  &__btn {
    &_apply {
      display: none;
      max-height:  calc(17.65vh - 59.5px);

      &-inner {
        margin-top: 10px;
      }
    }
  }

  &__text {
    font-family: 'Roboto', sans-serif;
    line-height: 1.5;
    text-align: center;
    font-weight: 300;
   @include interpolate(font-size, 768px, 1920px, 15px, 19px);

    @include media-breakpoint-down(xs) {
      text-align: left;
    }
  }

  &__accordion {
    margin-top: 30px;
  }
  &__accordion-content {

    &_flex {
      display: flex;
      justify-content: space-between;
      overflow: auto;

      @include media-breakpoint-down(sm) {
        flex-wrap: wrap;
      }
    }
  }

  &__accordion-section {
    box-shadow: 0 0 25px 10px rgba(35, 17, 1, 0.43);
    background-color: #252525;
  }

  &__accordion-section-content {
    background-color: #f98d2e;
    width: 100%;

    //interrupt bs class
    padding: 0 !important;
    overflow: auto !important;
    box-shadow: inset 0 7px 40px -17px rgb(0, 0, 0);
    z-index: 2;
    overflow-y: scroll !important;
    max-height: 22vh !important;

    @include media-breakpoint-down(sm) {
      max-height: none !important;
    }


    &__body {
      //interrupt bs class
      border-top: none !important;
      padding: 15px!important;

    }
  }

  &__accordion-section-title {
    width: 100%;
    display: flex;
    line-height: calc(17.65vh - 59.5px);
    justify-content: space-between;
    align-items: center;
    color: #fff;

    &:hover,
    &:active,
    &:focus {
      color: #fff;
      text-decoration: none;
    }
  }
  &-content-item {
    padding-left: 15px;
    ul {
      padding-left: 17px;
    }

    &:first-child {
      padding-left: 0;
    }

    @include media-breakpoint-down(sm) {
      width: 100%;
      padding-left: 0;

      &:after {
        content: '';
        height: 1px;
        width: 33%;
        left: 50%;
        margin-top: 20px;
        margin-bottom: 20px;
        transform: translateX(-50%);
        position: relative;
        display: block;
        background-color: #fff;
      }

      &:last-child:after {
        display: none;
      }
    }

    &__title {
      font-size: 14px;
      margin-top: 0;
    }

    &__list-item {
      margin-top: 5px;
      font-size: 14px;
    }
  }
}

