// sections
.landing {
    &_page {

        p,
        ul {
            font-size: 16px;
            line-height: 166%;
            letter-spacing: 0.02em;
        }

        .faq {
            background-color: #252525;
            
            &-title {
                font-size: 32px;
            }

            &-item {
                h3 {
                    font-size: 20px;
                }

                p,
                ul {
                    font-size: 16px;
                }
            }
        }

        .well {
            p {
                font-size: 20px;
            }
        }
    }

    &_head {
        background-color: #252525;
        padding: 20px 50px 70px;
    }

    &_title {
        display: flex;
        justify-content: center;
        text-transform: uppercase;
        color: #fff;

        h1 {
            font-weight: 600;
            font-size: 32px;
            text-align: center;
            margin: 10px 0;

            @include media-breakpoint-down(sm) {
                font-weight: 500;
                font-size: 24px;
            }
        }

        h2 {
            font-weight: 500;
            font-size: 24px;
            margin: 10px;
            text-align: center;
        }
    }

    &_section {
        padding: 40px 50px 50px;
        background-color: #252525;

        @include media-breakpoint-down(sm) {
            padding: 40px 20px 50px;
        }

        .landing_title {
            margin-bottom: 20px;
        }

        ul {
            list-style: none;
        }

        li {
            position: relative;

            &:before {
                content: '';
                width: 3px;
                height: 3px;
                border-radius: 50%;
                background-color: #fff;
                position: absolute;
                top: calc(50% - 1px);
                left: -15px;
            }
        }
    }

    &_list {
        margin: 0 -15px;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        @include media-breakpoint-down(sm) {
            margin: 0;
        }
    }

    &_item {
        .item {
            &_head {
                display: flex;
                align-items: center;

                h4 {
                    color: #E27826;
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 24px;
                }
            }

            &_title {
                margin-left: 20px;
                min-height: 92px;
                display: flex;
                align-items: center;
            }
        }
    }

    &_intro {
        position: relative;
        height: 300px;

        @include media-breakpoint-down(sm) {
            height: 550px;
        }

        @include media-breakpoint-down(xs) {
            height: 750px;
        }

        .intro {
            &_bg {
                position: absolute;
                z-index: 0;
                inset: 0;
            }

            &_before,
            &_after,
            &_logo {
                position: absolute;
                z-index: 1;
            }

            &_logo {
                top: 25px;
                left: 30px;
            }

            &_fg {
                position: relative;
                z-index: 5;
                height: 100%;
                display: flex;
                align-items: center;

                @include media-breakpoint-down(sm) {
                    align-items: flex-start;
                    padding-top: 75px;
                }
            }

            &_content {
                max-width: 780px;
                padding: 20px;
                display: flex;
                flex-direction: column;
                align-items: center;
                text-align: center;
                margin: 0 auto;

                p {
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 166%;
                    margin-bottom: 20px;
                }
            }
        }
    }
}

.light_section {
    background-color: #fff;

    h2,
    h3,
    p,
    ul {
        color: #252525;
    }

    li {
        position: relative;

        &:before {
            content: '';
            width: 3px;
            height: 3px;
            border-radius: 50%;
            background-color: #252525;
            position: absolute;
            top: calc(50% - 2px);
            left: -15px;
        }
    }

}

.offer {
    .landing {
        &_item {
            margin: 15px 0;
        }
    }

    .item {
        &_title {
            h3 {
                font-weight: 600;
                font-size: 22px;
                margin: 10px 0;
            }
        }
    }
}

.process {
    .landing {
        &_item {
            display: flex;
            align-items: center;

            @include media-breakpoint-down(sm) {
                flex-direction: column;
            }
        }
    }

    .item {
        &_body {
            margin-left: 25px;

            @include media-breakpoint-down(sm) {
                margin-left: 0;
            }
        }

        &_title {
            margin-left: 0;

            h3 {
                margin: 10px 0;
            }
        }

        &_icon {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &_title {
            @include media-breakpoint-down(sm) {
                text-align: center;
                display: flex;
                justify-content: center;
            }

            h3 {
                font-weight: 600;
                font-size: 22px;
                line-height: 27px;
            }
        }
    }
}

.choose {
    .landing {
        &_item {
            width: calc(100% / 3);
            padding: 10px 15px;

            @include media-breakpoint-down(md) {
                width: 50%;
            }

            @include media-breakpoint-down(sm) {
                width: 100%;
            }
        }
    }

    .item {
        &_head {
            flex-direction: column;
            align-items: center;
        }

        &_title {
            margin-left: 0;
            text-align: center;
            align-items: flex-start;

            h3 {
                font-weight: 600;
                font-size: 22px;
                margin: 10px 0;
            }
        }
    }
}

// pages
.laravel {
    .benefits {
        .landing {
            &_item {
                width: calc(100% / 3);
                padding: 10px 15px;

                @include media-breakpoint-down(md) {
                    width: 50%;
                }

                @include media-breakpoint-down(sm) {
                    width: 100%;
                }
            }
        }
    }

    .process {
        .landing {
            &_item {
                padding: 20px;
                margin: 10px 0;
                background: linear-gradient(101.87deg, #313131 6.37%, #242424 88.98%);
                border: 1px solid #444444;
                border-radius: 2px;
            }
        }
    }

    .intro {
        &_bg {
            background-image: url('/static/images/laravel/intro.jpg');
            background-size: cover;
            background-position: center;

            @include media-breakpoint-down(sm) {
                background-image: url('/static/images/laravel/intro_mobile.jpg');
            }
        }

        &_logo {
            @include media-breakpoint-down(md) {
                display: none;
            }
        }

        &_before {
            bottom: 30px;
            left: 5%;

            @include media-breakpoint-down(sm) {
                left: 50%;
                transform: translateX(-50%);
                bottom: initial;
                top: 45px;
            }
        }

        &_after {
            top: 50%;
            transform: translateY(-50%);
            right: 30px;

            @include media-breakpoint-down(lg) {
                transform: none;
                bottom: 0;
                top: initial;
            }

            @include media-breakpoint-down(sm) {
                left: 50%;
                transform: translateX(-50%);
                bottom: 0;
                right: initial;
            }

            @include media-breakpoint-down(xs) {
                bottom: 35px;
            }
        }

        &_fg {
            @include media-breakpoint-down(sm) {
                align-items: center;
                padding-top: 0;
            }
        }
    }
}

.offshore {
    .benefits {
        .landing {
            &_item {
                width: 50%;
                padding: 10px 15px;

                @include media-breakpoint-down(sm) {
                    width: 100%;
                }

                &-full {
                    width: 100%;
                }
            }
        }
    }

    .landing {
        &_intro {
            @include media-breakpoint-down(sm) {
                height: 600px;
            }

            @include media-breakpoint-down(xs) {
                height: 800px;
            }
        }
    }

    .intro {
        &_bg {
            background-image: url('/static/images/offshore/intro.jpg');
            background-size: cover;
            background-position: center;
            overflow: hidden;

            @include media-breakpoint-down(sm) {
                background-image: url('/static/images/offshore/intro_mobile.jpg');
            }
        }

        &_logo {
            @include media-breakpoint-down(md) {
                display: none;
            }
        }

        &_before {
            bottom: -25px;
            left: 1%;

            @include media-breakpoint-down(sm) {
                left: 50%;
                transform: translateX(-50%);
                bottom: initial;
                top: 45px;
                bottom: initial;
            }
        }

        &_after {
            bottom: 20px;
            right: 30px;

            @include media-breakpoint-down(lg) {
                bottom: 0;
            }

            @include media-breakpoint-down(sm) {
                left: 50%;
                transform: translateX(-50%);
                bottom: 0;
                right: initial;
            }

            @include media-breakpoint-down(xs) {
                bottom: 35px;
            }
        }

        &_fg {
            @include media-breakpoint-down(sm) {
                align-items: center;
                padding-top: 0;
            }
        }
    }

    .offer {
        .landing {
            &_item {
                width: 50%;
                padding: 10px 15px;

                @include media-breakpoint-down(sm) {
                    width: 100%;
                }
            }
        }
    }

    .process {
        .landing {
            &_item {
                width: calc(100% / 3);
                padding: 15px;
                flex-direction: column;

                @include media-breakpoint-down(md) {
                    width: 50%;
                }
                
                @include media-breakpoint-down(sm) {
                    width: 100%;
                }

            }
        }

        .item {
            &_head {
                width: 100%;
            }

            &_title {
                margin-left: 20px;
                text-align: left;
            }

            &_body {
                margin-left: 0;
            }
        } 
    }
}

.security {
    .landing {
        &_intro {
            @include media-breakpoint-down(xs) {
                height: 700px;
            }

            @media (max-width: 360px) {
                height: 800px;
            }
        }
    }

    .intro {
        &_bg {
            background-image: url('/static/images/security/intro.jpg');
            background-size: cover;
            background-position: center;

            @include media-breakpoint-down(sm) {
                background-image: url('/static/images/security/intro_mobile.jpg');
            }
        }

        &_logo {
            @include media-breakpoint-down(md) {
                display: none;
            }
        }

        &_after {
            bottom: 20px;
            right: 30px;

            @include media-breakpoint-down(lg) {
                bottom: 0;
            }

            @include media-breakpoint-down(sm) {
                left: 50%;
                transform: translateX(-50%);
                bottom: 0;
                right: initial;
            }

            @include media-breakpoint-down(xs) {
                bottom: 35px;
            }
        }

        &_content {
            max-width: 800px;
        }
    }

    .benefits {
        .landing {
            &_item {
                width: 50%;
                padding: 15px;

                @include media-breakpoint-down(sm) {
                    width: 100%;
                    padding: 0;
                }
            }
        }
    }

    .offer {
        .landing {
            &_item {
                display: flex;
                width: 50%;
                padding: 0 15px;

                @include media-breakpoint-down(sm) {
                    width: 100%;
                    padding: 0;
                    flex-direction: column;
                    align-items: center;

                    &:first-child {
                        order: 0;
                    }

                    &:nth-child(3) {
                        order: 1;
                    }

                    &:nth-child(2) {
                        order: 2;
                    }

                    &:last-child {
                        order: 3;
                    }
                }
            }
        }

        .item {
            &_content {
                margin-left: 20px;

                @include media-breakpoint-down(sm) {
                    margin-left: 0;
                    margin-top: 15px;
                }
            }
        }
    }

    .custom {
        li {
            margin-bottom: 10px;

            &:before {
                top: 12px;
            }
        }
    }

    .process {
        .landing {
            &_item {
                flex-direction: column;
                align-items: flex-start;
                margin: 10px 0;
            }
        }

        .item {
            &_title {
                margin-left: 20px;
                text-align: left;
            }

            &_body {
                margin-left: 0;
            }
        }
    }

    .partner {
        padding-bottom: 0;

        .landing {
            &_item {
                width: 50%;
                padding: 15px;

                @include media-breakpoint-down(sm) {
                    width: 100%;
                    padding: 0;
                }
            }
        }

        .item {
            &_content {
                h3 {
                    font-size: 22px;
                    font-weight: 600;
                }
            }
        }

        li {
            &:before {
                top: 12px
            }
        }
    }

    .faq {
        padding: 10px 30px 30px;
    }
}

.telemedicine {
    .landing {
        &_intro {
            @include media-breakpoint-down(xs) {
                height: 700px;
            }

            @media (max-width: 360px) {
                height: 750px;
            }
        }
    }

    .intro {
        &_bg {
            background-image: url('/static/images/telemedicine/intro.jpg');
            background-size: cover;
            background-position: center;

            @include media-breakpoint-down(sm) {
                background-image: url('/static/images/telemedicine/intro_mobile.jpg');
            }
        }

        &_logo {
            @include media-breakpoint-down(md) {
                display: none;
            }
        }

        &_after {
            bottom: 20px;
            right: 20px;

            @include media-breakpoint-down(lg) {
                bottom: 0;
            }

            @include media-breakpoint-down(sm) {
                left: 50%;
                transform: translateX(-50%);
                bottom: 0;
                right: initial;
            }

            @include media-breakpoint-down(xs) {
                bottom: 35px;
            }
        }

        &_content {
            max-width: 800px;
        }
    }

    .custom {
        .landing {
            &_item {
                width: 50%;
                padding: 0 15px;

                @include media-breakpoint-down(sm) {
                    padding: 0;
                    width: 100%;
                }
            }
        }

        .item {
            &_list {
                margin: 0 auto;
                width: max-content;

                @include media-breakpoint-down(sm) {
                    margin: 0;
                    width: inherit;
                }

                li {
                    &:before {
                        top: 13px;
                    }
                }
            }
        }
    }

    .benefits {
        .landing {
            &_list {
                &-title {
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    text-align: center;

                    h3 {
                        font-size: 22px;
                        font-weight: 600;
                    }
                }
            }

            &_item {
                width: calc(100% / 3);
                padding: 15px;
    
                @include media-breakpoint-down(md) {
                    width: 50%;
                }
    
                @include media-breakpoint-down(sm) {
                    width: 100%;
                    padding: 15px 0;
                }
            }
        }

        .item {
            &_head {
                flex-direction: column;
                align-items: center;
            }

            &_title {
                margin-left: 0;
                min-height: inherit;
                text-align: center;

                h3 {
                    color: #E27826;
                    font-weight: 600;
                    font-size: 20px;
                    margin: 10px;
                }
            }

            &_content {
                text-align: center;
            }
        }
    }

    .offer {
        .landing {
            &_text {
                text-align: center;
            }
        }
    }

    .process {
        .landing {
            &_text {
                text-align: center;
            }

            &_item {
                padding: 20px;
                margin: 10px 0;
                background: linear-gradient(101.87deg, #313131 6.37%, #242424 88.98%);
                border: 1px solid #444444;
                border-radius: 2px;
            }
        }

        .item {
            &_icon {
                @include media-breakpoint-down(sm) {
                    margin-bottom: 30px;
                }
            }
        }
    }

    .points {
        .landing {
            &_item {
                width: 50%;
                padding: 15px;

                @include media-breakpoint-down(md) {
                    width: 100%;
                    padding: 15px 0;
                }
            }
        }

        .item_title {
            h3 {
                font-weight: 600;
                font-size: 22px;
                margin: 10px;
            }
        }
    }

    .choose {
        padding-bottom: 10px;
    }

    .faq {
        padding: 10px 30px 30px;
    }
}