@use "sass:math";

$colorBg: #eb8e2d;
$colorOutline: #ffffff;
$colorOutlineFade: darken(#eb8e2d, 10%);

$widthMouse: 23px;
$heightMouse: 34px;
$borderMouse: 3px;

$posMouse: 8px;
$posText: 2px;

$sizeTrackball: 3px;
$posTrackball: 5px;
$shrinkTrackball: 0.4;

$animDuration: 5s;

@mixin bgGradient {
  background: $colorOutlineFade linear-gradient(
                  transparent 0%,
                  transparent 50%,
                  $colorOutline 50%,
                  $colorOutline 100%
  );
}

body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100%;
  background-color: $colorBg;
}

.mouse {
  @include bgGradient;
  position: relative;
  width: $widthMouse;
  display: inline-block;
  height: $heightMouse;
  background-size: 100% 100%;
  border-radius: 100px;
  animation: colorSlide $animDuration linear infinite,
  nudgeMouse $animDuration ease-out infinite;

  &:before,
  &:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
  }

  &:before {
    width: $widthMouse - $borderMouse;
    height: $heightMouse - $borderMouse;
    background-color: $colorBg;
    border-radius: 100px;
  }

  &:after {
    background-color: $colorOutline;
    width: $sizeTrackball;
    height: $sizeTrackball;
    border-radius: 100%;
    animation: trackBallSlide $animDuration linear infinite;
  }
}

@keyframes colorSlide {
  0% {
    background-position: 0% 100%;
  }
  20% {
    background-position: 0% 0%;
  }
  21% {
    background-color: $colorOutlineFade;
  }
  29.99% {
    background-color: $colorOutline;
    background-position: 0% 0%;
  }


}

@keyframes trackBallSlide {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  6% {
    opacity: 1;
    transform: scale(0.9) translateY(math.div($posTrackball, 4));
  }
  14% {
    opacity: 0;
    transform: scale($shrinkTrackball) translateY($posTrackball*2);
  }
  15%, 19% {
    opacity: 0;
    transform: scale($shrinkTrackball) translateY(-$posTrackball);
  }
  28%, 29.99% {
    opacity: 1;
    transform: scale(1) translateY(-$posTrackball);
  }

}

@keyframes nudgeMouse {
  0% {
    transform: translateY(0);
  }
  20% {
    transform: translateY($posMouse);
  }
  30% {
    transform: translateY(0);
  }

}

@keyframes nudgeText {
  0% {
    transform: translateY(0);
  }
  20% {
    transform: translateY($posText);
  }
  30% {
    transform: translateY(0);
  }

}

@keyframes colorText {
  21% {
    color: $colorOutlineFade;
  }
  30% {
    color: $colorOutline;
  }

}
