.mission {
  background: #252525;
  padding: 30px;
  width: calc(100vw - 210px);
  position: relative;

  @include media-breakpoint-down(md) {
    display: block;
    width: 100%;
    height: auto !important;
  }

  @include media-breakpoint-down(sm) {
    display: flex;
  }

  @include media-breakpoint-down(xs) {
    padding: 45px 10px;
  }

  @media all and (max-width: 1600px) {
    padding: 25px 30px;
  }

  .main-section__title {
    font-size: 30px;
    margin: 0 0 30px;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 0.02em;
    color: #FFFFFF;
    text-align: center;

    @include media-breakpoint-down(xs) {
      font-size: 26px;
    }

    @media all and (max-width: 1600px) {
      margin-bottom: 20px;
    }
  }

  h2.main-section__title{
    font-size: 24px;
  }

  .main-section__content {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    height: calc(100% - 30px);

    @include media-breakpoint-down(md) {
      margin-top: 20px;
    }

    @include media-breakpoint-down(sm) {
      height: auto;
    }
  }
}

.mission-top {
  display: flex;
  flex-direction: column;
  margin: 0 -15px;
  margin-bottom: 40px;

  @include media-breakpoint-up(lg) {
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;
  }

  @media all and (min-width: 1600px) {
    padding: 0 75px;
    margin-bottom: 30px;
  }

  @media all and (max-width: 1600px) {
    margin-bottom: 20px;
  }

  &__image_wrapper {
    width: 100%;
    text-align: center;
    margin-top: 20px;

    @include media-breakpoint-up(lg) {
      width: 30%;
      margin-top: 0;
      margin-left: 10px;
    }

    @media all and (min-width: 1600px) {
      width: 45%;
      margin-left: 20px;
    }

    svg {
      width: 100%;
    }
  }

  &__text {
    display: block;

    @include media-breakpoint-up(lg) {
      width: 70%;
    }

    @media all and (min-width: 1600px) {
      width: 55%;
    }

    &_title {
      font-size: 26px;
      font-weight: 600;
      margin: 0 0 15px;
      letter-spacing: 0.02em;
      color: #E27826;
      text-transform: uppercase;

      @include media-breakpoint-down(sm) {
        font-size: 22px;
      }
    }

    &_p {
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.02em;

      @media all and (min-width: 1600px) {
        font-size: 20px;
      }
    }
  }
}

.mission__boxes {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -25px;

  .mission-box {
    width: 100%;
    padding: 10px;
    overflow: visible;
    perspective: 55vw;
    border: none;

    @include media-breakpoint-up(lg) {
      width: 50%;
    }

    @include media-breakpoint-up(xl) {
      width: 33%;
    }

    @media all and (min-width: 1600px) {
      padding: 18px;
    }

    &:hover,
    &.active,
    &:focus {
      transition: border 0s;
      border-left-color: transparent;

      & ~ .mission-box {
        transition: border 0s;
        border-left-color: transparent;
      }

      .mission-box__front-part {
        opacity: 0;
        transform: translateY(-110px) rotateX(90deg);
      }

      .mission-box__back-part {
        opacity: 1;
        transform: rotateX(0);
      }
    }

    &__inner {
      position: relative;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      height: 100%;
      padding: 0;
    }

    &__front-part,
    &__back-part {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      height: 100%;
      width: 100%;
      overflow: hidden;
      transition: all .5s;
      box-shadow: 0 0 25px 10px rgba(35, 17, 1, 0.3);

      @include media-breakpoint-down(lg) {
        padding: 15px;
      }
    }

    &__front-part {
      opacity: 1;
      padding: 50px 25px;
      box-shadow: 0 0 25px 10px rgba(35, 17, 1, 0.3);
      position: relative;

      @include media-breakpoint-up(sm) {
        padding: 40px 25px;
      }

      @media all and (min-width: 1600px) {
        padding: 60px;
      }

      &:before {
        content: '';
        position: absolute;
        left: 1px;
        top: 1px;
        z-index: 2;
        border-radius: 2px;
        bottom: 1px;
        right: 1px;
        width: calc(100% - 2px);
        height: calc(100% - 2px);
        background: linear-gradient(101.87deg, #313131 6.37%, #242424 88.98%);
      }

      &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        z-index: 0;
        opacity: 1;
        border-radius: 2px;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        transform: rotate(0);
        background: linear-gradient(165.96deg, #2C2C2C 0.92%, #E7841B 52.52%, #222222 100%);
        transition: 0.5s;
      }
    }

    &__back-part {
      opacity: 0;
      background-color: #252525;
      transform: translateY(110px) rotateX(-90deg);
      padding: 12px;

      @include media-breakpoint-up(lg) {
        padding: 8px;
      }

      @media all and (min-width: 1600px) {
        padding: 15px;
      }
    }

    &__content {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &__icon {
      position: relative;
      margin: 0 40px 0 0;
      z-index: 3;
    }

    &__text {
      position: relative;
      z-index: 3;
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 0.02em;
      color: #E2E2E2;
      margin: 0;

      @include media-breakpoint-up(lg) {
        font-size: 24px;
      }

      &_info {
        font-size: 14px;
        font-weight: 400;
        margin: 0;

        @include media-breakpoint-up(lg) {
          font-size: 12px;
        }

        @media all and (min-width: 1600px) {
          font-size: 16px;
        }
      }
    }
  }
}

