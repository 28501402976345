// styles for section why-we-are
.expertise-internal {
  section.why-we-are {

    .text-note {
      color: $accent;

      a {
        color: $accent;
        text-decoration: underline;
      }
    }

    .section-title {
      @include media-breakpoint-down(sm) {
        text-align:left !important;
      }
    }

    .why-we-are-list {
      display: flex;
      flex-wrap: wrap;

      .list-item {
        display: flex;
        align-items: flex-start;
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;

        @include media-breakpoint-down(sm) {
          flex-direction: column;
          align-items: center;
          text-align: center;
          width: 100%;
        }

        .item-img {
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          background-color: $accent;
          width: 76px;
          min-width: 76px;
          height: 76px;
          padding: 16px;
          margin-right: 2rem;

          @include media-breakpoint-down(sm) {
            margin-right: 0;
          }

          span {
            color: #fff;
            font-size: 40px;
          }
        }

        .item-text {
          .text-title {
            font-weight: 600;
            font-size: 18px;
            margin-top: 0;

            @include media-breakpoint-down(sm) {
              margin: 1rem 0;
              text-align:center;
            }
          }

          .text-description {
            @include media-breakpoint-down(sm) {
              text-align: left;
            }
          }
        }
      }
    }
  }
}
