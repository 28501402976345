@import '../../mixins/bs_breakpoints.scss';
@import '../../mixins/adjustable-font-size';

.blog-internal {
  .page-content {
    overflow: unset;
  }

  .page-content__wrapper {
    overflow: unset;
  }
}

.blog-int {
  background-color: #252525;
  padding: 0 30px;

  @include media-breakpoint-down(md) {
    padding: 0 15px;
  }

  .main-section__content {
    position: relative;
    display: flex;
    flex-direction: column;
  }

  &__video {
    &_about {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;

      &-wrapper {
        width: 100%;
        position: relative;
        padding-bottom: 56.25%;
        margin-bottom: 10px;
        margin-top: 10px;
      }
    }
  }

  &__flex-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 30px;

    @include media-breakpoint-down(md) {
      order: 3;
      flex-direction: column;
    }
  }

  &__recent-posts-title {
    color: $accent;
    @include interpolate(font-size, 992px, 1920px, 20px, 25px);
    font-weight: 400;
    margin-left: 15px;
  }

  &__left {
    position: sticky;
    top: 20px;
    flex-shrink: 0;
    width: 13%;
    min-width: 190px;

    @include media-breakpoint-down(md) {
      position: relative;
      width: 100%;
    }

    @include media-breakpoint-between(sm, md) {
      display: grid;
      grid-column-gap: 30px;
      grid-template-columns: 1fr 1fr;
      align-items: flex-start;
    }
  }

  &__center {
    position: relative;
    flex: 1 1 100%;
    padding-left: 30px;
    padding-right: 30px;

    @include media-breakpoint-down(md) {
      width: 100%;
      margin-top: 45px;
      padding-left: 0;
      padding-right: 0;
    }

    .well {
      a.btn.btn-bordered {
        min-width: 140px;
        border: 2px solid #ffffff;
        border-radius: 3px;
        text-decoration: none;
        padding: 10px 22px;
        line-height: 1.22;
      }
    }
  }

  &__right {
    width: 20%;
    flex-shrink: 0;
    position: sticky;
    top: 20px;

    &.relative-bar {
      position: relative;
    }

    @include media-breakpoint-down(md) {
      width: 100%;
    }

    @include media-breakpoint-down(sm) {
      display: none;
    }

    .blog-box {
      @include media-breakpoint-down(md) {
        &::after {
          content: '';
          display: none;
          padding-bottom: 100%;
        }
      }
    }

    .blog-box__wrapper {
      width: 100%;
      min-width: 130px;
      display: flex;
      justify-content: center;
      margin-bottom: 28px;
      height: calc(23vh - 28px);

      @include media-breakpoint-down(md) {
        flex-wrap: wrap;
        padding-left: 15px;
        padding-right: 15px;
        height: 50vw;
        max-height: 270px;
      }

      &:last-child {
        .blog-box {
          margin-bottom: 0;
        }
      }
    }

    .blog-box {
      margin-top: 0;

      &:hover {
        .blog-box__content {
          @media (max-width: 1650px) {
            padding: 5% 5% 0 5%;
          }
        }
      }

      &__content {
        @media (max-width: 1650px) {
          padding: 5% 5% 0 5%;
        }
      }

      &__title {
        font-size: 16px;
      }

      &__button {
        height: 25px;
        font-size: 14px;

        @media (max-width: 1650px) {
          display: none;
        }
      }

      &__tag {
        height: 25px;
        color: #fff;
        margin-bottom: 15px;
        padding: 2px 3px;
        display: inline-block;

        a {
          line-height: 21px;
        }
      }
    }
  }

  &__content {
    p {
      color: #fff;
      font-size: 16px;
      font-weight: 400;
      line-height: 1.666;
    }

    > p {
      &:first-child {
        &:first-letter {
          /* Style for "L" */
          color: $accent;
          font-family: 'Philosopher', serif;
          @include interpolate(font-size, 768px, 1920px, 44px, 56px);
          vertical-align: bottom;
          font-weight: 700;
          line-height: 0.98;
          padding-right: 3px;
          text-transform: uppercase;
        }
      }
    }

    p.blog-int__pros_num,
    p.blog-int__cons_num {
      font-weight: 200;
      font-size: 60px;
      line-height: 1.2;
      vertical-align: bottom;
      margin-bottom: 0;
      transform: translateY(20%);
    }

    p.blog-int__cons_num {
      &:first-child &:first-letter {
        @extend .blog-int__cons_num;
      }
    }

    p.blog-int__pros_num {
      &:first-child &:first-letter {
        @extend .blog-int__pros_num;
      }
    }

    h1,
    .h1,
    h2,
    .h2,
    h3,
    .h3,
    h4,
    .h4,
    h5,
    .h5,
    h6,
    .h6 {
      color: #e27826;
    }

    h1,
    .h1 {
      font-size: 36px;
      margin-top: 35px;
      margin-bottom: 15px;
    }

    h2,
    .h2 {
      font-size: 30px;
      margin-top: 35px;
      margin-bottom: 15px;
    }

    h3,
    .h3 {
      font-size: 24px;
      margin-top: 0;
      margin-bottom: 15px;
    }

    h4,
    .h4 {
      font-size: 18px;
      margin-top: 0;
    }

    h5,
    .h5 {
      font-size: 14px;
      margin-top: 0;
    }

    h6,
    .h6 {
      font-size: 12px;
      margin-top: 0;
    }

    iframe {
      position: relative;
      display: none;

      &[src*='youtube'] {
        position: absolute;
        display: block;
        width: 100%;
        max-width: 100%;
        height: 100%;
      }
    }

    a {
      color: #efa13c;
      text-decoration: underline;
      font-size: 16px;

      &:hover,
      &:active,
      &:focus {
        text-decoration: none;
      }
    }

    ul {
      font-size: 16px;
      line-height: 1.666;
    }

    li {
      padding: 7px 0 7px 15px;
      font-size: 16px;
      line-height: 1.666;
    }

    img {
      max-width: 100%;
      width: auto;
    }

    table {
      border-collapse: collapse;
      width: 100%;
      border-spacing: 0;
      margin-bottom: 20px;
      background: transparent;
      color: #fff;
    }

    * + table {
      margin-top: 20px;
    }

    table caption {
      text-align: left;
    }

    table td,
    table th {
      text-align: left;
      border: 1px solid #fff;
      padding: 16px;
      vertical-align: top;
    }

    .table-shrink {
      width: 1px;
    }

    .table-expand {
      min-width: 60px;
    }

    .video-wrap {
      position: relative;
      width: 100%;
      margin: 40px 0;
      padding-bottom: 56.25%;
    }
  }

  &__title {
    color: #fffefe;
    font-weight: 400;
    @include interpolate(font-size, 992px, 1920px, 25px, 25px);
    line-height: 1;
    padding: 30px 0;
    margin-top: 0;
    margin-bottom: 0;
    text-align: center;

    @include media-breakpoint-down(md) {
      order: 2;
      padding-top: 18px;
      padding-bottom: 30px;
      text-align: left;
      margin-top: 80px;
      z-index: 3;
      @include interpolate(font-size, 320px, 991px, 32px, 45px);
    }

    @include media-breakpoint-down(sm) {
      margin-top: 0;
    }

    &_orange {
      color: #e27826;
      font-size: 24px;
      margin-top: 35px;
      margin-bottom: 15px;

      @include media-breakpoint-down(md) {
        @include interpolate(font-size, 320px, 991px, 30px, 45px);
      }
    }
  }

  &__hero-section {
    width: 100%;
    height: 24.64vw;
    position: relative;
    margin-top: 60px;

    @include media-breakpoint-down(sm) {
      order: 1;
      height: auto;
      min-height: 200px;
      margin-top: 20px;

      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(
          bottom,
          rgba(#000, 0.8),
          rgba(#000, 0.4) 20%,
          transparent 100%
        );
      }
    }

    @include media-breakpoint-down(sm) {
      width: calc(100% + 30px);
      margin: 0 -15px;
    }

    img {
      width: 100%;
      height: inherit;
      min-height: 200px;
      object-fit: cover;
    }

    &_welcome-to-bekey-blog {
      @include media-breakpoint-down(sm) {
        margin-top: 0;
      }

      &:after {
        content: '';
        background-image: url('/static/images/robot.webp');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: 50%;
        display: block;
        position: absolute;
        bottom: 0;
        right: 13vw;
        height: 49%;
        width: 9%;

        @include media-breakpoint-down(md) {
          right: 5px;
          left: auto;
          top: auto;
          bottom: 0;
          background-size: contain;
          width: 25%;
        }
      }
    }

    &_dedicated-team-structure {
      @include media-breakpoint-down(sm) {
        background-image: url(/static/images/blog-int__hero-bg_dedicated-mob.webp) !important;
      }
    }

    &_building-the-best-team-of-developers {
      @include media-breakpoint-down(sm) {
        background-image: url(/static/images/blog-int__hero-bg_4-mob.webp) !important;
      }
    }

    &_things {
      @include media-breakpoint-down(sm) {
        background-image: url(/static/images/blog-int__hero-bg_7-mobile.webp) !important;
      }
    }

    &_outstaffing {
      @include media-breakpoint-down(sm) {
        background-image: url(/static/images/blog-int__hero-bg_8-mobile.webp) !important;
      }
    }
  }

  &__flex-container {
    display: flex;
  }

  &__text {
    /* Style for "i, I’m BeK" */
    color: #ffffff;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.666;

    @include media-breakpoint-down(sm) {
      line-height: 1.666;
    }
  }

  &_4 {
    .blog-int__text {
      @include media-breakpoint-down(sm) {
        margin-bottom: 30px;
      }
    }
  }

  &__go-up {
    /* Style for "Rectangle" */
    width: 111px;
    height: 34px;
    margin: 30px 0;
    left: 50%;
    position: relative;
    transform: translateX(-50%);
  }

  &__go-up.btn {
    text-decoration: none;
    color: #fff;

    &:hover,
    &:active,
    &:focus {
      color: #efa13c;
    }
  }

  &__list {
    font-size: 16px;
    line-height: 1.666;

    &_3 {
      align-items: center;

      @include media-breakpoint-down(sm) {
        flex-direction: column;
      }
    }

    &_4 {
      @include media-breakpoint-down(sm) {
        li {
          margin-bottom: 30px;
        }
      }
    }

    li {
      padding: 7px 0 7px 15px;

      @include media-breakpoint-down(sm) {
        padding-left: 7px;
      }
    }
  }

  &__pros,
  &__cons {
    margin-top: 50px;

    @include media-breakpoint-down(md) {
      display: none;
    }

    &_mob {
      display: none;

      @include media-breakpoint-down(md) {
        display: block;
        margin-top: 30px;
        margin-bottom: 20px;
        ol {
          list-style-type: none;
          text-align: center;
          padding-left: 0;
          font-size: 16px;
          line-height: 1.666;
          counter-reset: list;

          li {
            &:before {
              content: counter(list) '.';
              counter-increment: list;
              display: block;
              font-weight: 200;
              font-size: 60px;
              transform: translateY(30%);
            }
          }
        }
      }
    }

    &_d-flex {
      display: flex;

      &-child {
        flex-shrink: 0;
      }
    }

    .justify-center {
      display: flex;
      justify-content: center;
    }

    &_image {
      align-self: center;
      flex-grow: 0;

      img {
        width: 100%;
      }
    }

    p {
      text-align: center;
    }
  }

  &__caption {
    margin-bottom: 10px;
    color: #e27826;
    @include interpolate(font-size, 768px, 1920px, 18px, 25px);

    @include media-breakpoint-down(md) {
      @include interpolate(font-size, 320px, 767px, 20px, 25px);
    }
  }

  &__image {
    width: 100%;

    &_float {
      width: auto;
      max-width: 40%;
      float: right;
      padding-left: 15px;
      padding-bottom: 15px;

      @include media-breakpoint-down(md) {
        float: none;
        width: 100%;
        max-width: 450px;
        left: 50%;
        position: relative;
        transform: translateX(-50%);
      }
    }

    &_schema {
      padding-left: 15px;
      padding-right: 15px;

      @include media-breakpoint-down(sm) {
        max-width: 400px;
        left: 50%;
        position: relative;
        transform: translateX(-50%);
        margin-top: 30px;
        margin-bottom: 30px;
        padding-left: 15px;
        padding-right: 15px;
      }
    }

    &-link-wrapper {
      margin-top: 30px;
      margin-bottom: 30px;
      display: block;
    }

    &_3 {
      &-wrapper {
        width: 40%;
        flex-shrink: 0;
        padding-left: 5%;

        @include media-breakpoint-down(sm) {
          width: 100%;
          padding-left: 0;
          max-width: 400px;
        }
      }
    }

    &_startup {
      width: auto;
      max-width: 50%;
      max-height: 250px;

      @include media-breakpoint-down(sm) {
        width: 60%;
        max-width: 350px;
        min-height: 100%;
      }

      &-wrapper {
        width: 40%;
        flex-shrink: 0;
        justify-content: center;
        display: flex;
        align-items: center;

        @include media-breakpoint-down(sm) {
          width: 100%;
        }
      }
    }

    &_7 {
      width: 100%;
      max-width: 100%;

      @include media-breakpoint-down(sm) {
        width: 100%;
        min-height: 100%;
      }

      &-wrapper {
        width: 40%;
        flex-shrink: 0;
        justify-content: center;
        display: flex;
        align-items: center;
        margin-left: 40px;
        margin-right: 40px;
        float: left;

        @include media-breakpoint-down(sm) {
          width: 100%;
        }
      }
    }

    &_8 {
      width: auto;
      flex-shrink: 0;
    }

    &-wrapper_8 {
      width: 170px;
      flex-shrink: 0;

      @include media-breakpoint-down(md) {
        display: flex;
        justify-content: center;
        margin-bottom: 20px;
      }
    }
  }

  &__list_startup.flex-container,
  &__list_7.flex-container {
    & > .blog-int__title_orange {
      display: none;

      @include media-breakpoint-down(sm) {
        display: block;
        order: 1;
      }
    }

    .blog-int__image_startup-wrapper,
    .blog-int__image_7-wrapper {
      @include media-breakpoint-down(sm) {
        order: 2;
        margin-bottom: 30px;
        margin-top: 20px;
      }
    }

    .blog-int__flex-text-wrapper {
      @include media-breakpoint-down(sm) {
        order: 3;
      }

      .blog-int__title_orange {
        display: block;

        @include media-breakpoint-down(sm) {
          display: none;
        }
      }
    }

    &:nth-child(even) {
      flex-direction: row-reverse;
    }

    @include media-breakpoint-down(sm) {
      &,
      &:nth-child(even) {
        flex-direction: column;
        align-items: center;
      }
    }
  }

  &__share-social-wrapper {
    margin-top: 20px;
    align-self: center;
    display: flex;
    width: 100%;
    flex-direction: column;

    @include media-breakpoint-down(md) {
      padding: 0;
      margin-top: 0;
    }
  }

  &__list_7.flex-container {
    display: block;
    margin-bottom: 30px;

    @include media-breakpoint-down(lg) {
      margin-bottom: 25px;
    }

    @include media-breakpoint-down(md) {
      margin-bottom: 45px;
    }

    @include media-breakpoint-down(sm) {
      margin-bottom: 25px;
    }

    .blog-int__title_orange {
      margin-top: 0;
    }

    .blog-int__image_7-wrapper {
      padding-bottom: 10px;
    }

    &:nth-child(odd) {
      .blog-int__image_7-wrapper {
        float: right;
        margin-right: 0;
      }
    }

    &:nth-child(even) {
      .blog-int__image_7-wrapper {
        margin-left: 0;
      }
    }

    .blog-int__title_orange {
      margin-top: 0;
      font-size: 22px;

      @include media-breakpoint-down(md) {
        @include interpolate(font-size, 320px, 991px, 30px, 45px);
      }
    }
  }

  .agree label {
    margin-top: 5px;
  }
}

.blog-int_7 {
  .blog-int__text {
    line-height: 1.5;

    &_7-top {
      margin-bottom: 25px;
    }
  }
}

.blog-int-8 {
  .blog-int__text {
    @include media-breakpoint-down(sm) {
      line-height: 1.666;
    }
  }
}

.calendar {
  background-image: linear-gradient(
    to top,
    rgba(0, 0, 0, 0.11) 0%,
    rgba(255, 255, 255, 0.11) 67%,
    rgba(255, 255, 255, 0.11) 100%
  );
  background-color: #000000;
  width: 100%;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  margin-bottom: 40px;

  @include media-breakpoint-down(md) {
    height: auto;
    margin-bottom: 20px;
  }

  @include media-breakpoint-down(sm) {
    height: 150px;
  }

  &__date {
    color: #ffffff;
    @include interpolate(font-size, 992px, 1920px, 28px, 36px);
    font-weight: 600;
  }

  &__year {
    opacity: 0.5;
    color: #ffffff;
    @include interpolate(font-size, 992px, 1920px, 18px, 22px);
    font-weight: 600;
  }

  &:before,
  &:after {
    content: '';
    display: block;
    position: absolute;
    background-image: url(/static/images/blog-int__calendar-clip.webp);
    background-size: cover;
    background-repeat: no-repeat;
    width: 7px;
    height: 24px;
    top: 0;
    transform: translateY(-50%);
  }

  &:before {
    left: 34px;
  }

  &:after {
    right: 34px;
  }
}

.pros-mob,
.cons-mob {
  width: 100%;
  max-width: 380px;
  padding-left: 15%;
  padding-right: 15%;
  left: 50%;
  position: relative;
  transform: translateX(-50%);
}

/* Blog navigation section */
.blog-nav {
  margin-bottom: 40px;

  @include media-breakpoint-between(sm, md) {
    grid-row-start: 1;
    grid-column-start: 2;
    grid-row-end: 4;
    margin-bottom: 0;
  }

  @include media-breakpoint-down(sm) {
    margin-bottom: 20px;
  }

  &__list {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  &__item {
    &#{ + &} {
      margin-top: 10px;

      @include media-breakpoint-down(md) {
        margin-top: 12px;
      }
    }
  }

  &__link {
    display: block;
    color: #fff;
    font-size: 14px;
    line-height: 1.3;
    margin: 10px 0;
    cursor: pointer;
    transition: all 0.3s ease-in-out;

    &:hover {
      color: #e27826;
    }

    &:focus {
      color: #fff;
      font-weight: 600;
    }
  }
}

/* Blog social section */
.blog-social {
  margin-bottom: 40px;

  @include media-breakpoint-down(md) {
    margin-bottom: 20px;
  }

  &__list {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  &__item {
    &#{ + &} {
      margin-left: 20px;
    }
  }

  &__link {
    display: block;
    width: 100%;
    line-height: 1;
    opacity: 1;

    span {
      font-size: 32px;
      color: #929292;
      transition: color 0.4s ease-in-out;
    }

    &:hover {
      span {
        color: #fff;
      }
    }
  }
}

/* Blog tags section */
.blog-tags {
  &__inner {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @include media-breakpoint-down(sm) {
      flex-direction: row;
      flex-wrap: wrap;
    }
  }

  &__item {
    min-height: 25px;
    margin-bottom: 10px;
    text-align: left;
    background: transparent;
    padding: 0;

    &.active-tag {
      a {
        color: #ffffff;
        border-color: #ffffff;
      }
    }

    @include media-breakpoint-down(md) {
      margin-right: 15px;
      height: auto;
    }

    a {
      transition: all 0.4s ease-in-out;
      color: #929292;
      text-align: left;
      width: 100%;
      display: block;
      font-weight: 600;
      line-height: 15px;
      letter-spacing: 0.02em;
      text-transform: lowercase;
      background: transparent;
      white-space: normal;
      padding: 9px 10px;
      border: 2px solid #929292;
      border-radius: 3px;

      @include media-breakpoint-down(md) {
        @include interpolate(font-size, 320px, 991px, 22px, 28px);
        line-height: 1;
      }
    }

    &:hover {
      a {
        color: #ffffff;
        border-color: #ffffff;
      }
    }
  }
}

/* Blog authors section */
.blog-authors {
  margin-top: 20px;
  padding-top: 40px;
  border-top: 1px solid transparentize(#fff, 0.9);

  &__title {
    margin-bottom: 0 !important;
    margin-top: 20px;
    color: #e27826;
    font-weight: 400;
    font-size: 25px;
    line-height: 30px;
    text-align: center;
  }

  &__row {
    display: flex;
    align-items: center;
    padding: 20px 0;

    &#{ + &} {
      border-top: 1px solid transparentize(#fff, 0.9);
    }
  }

  &__avatar {
    flex: 0 0 auto;
    width: 60px;
    height: 60px;
    margin-right: 20px;
    border-radius: 50%;
    overflow: hidden;

    img {
      max-width: 100%;
      max-height: 100%;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;

    }
  }

  &__desc {
    font-size: 14px;
    line-height: 20px;
  }

  &__name {
    text-decoration: underline;

    a {
      color: #fff;
    }
  }
}

/* Blog relation posts section */
.related-posts {
  margin-top: 60px;

  &__title {
    margin-bottom: 26px;
    color: #e27826;
    font-weight: 400;
    font-size: 25px;
    line-height: 30px;
    text-align: center;
  }

  &__inner {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 30px;

    @media screen and (max-width: 992px) {
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 30px;
    }

    @media screen and (max-width: 576px) {
      grid-template-columns: 1fr;
      padding: 0 15px;
    }
  }

  &__link {
    position: relative;
    padding-bottom: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    cursor: pointer;
    overflow: hidden;

    @media screen and (max-width: 576px) {
      height: 200px;
      padding-bottom: 0;
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.8) 0,
        transparent
      );
    }

    &:hover {
      .related-posts__info {
        height: 100%;
        transition: height 0.3s ease;

        &::after {
          opacity: 1;
          transition: opacity 0.5s ease 0.1s;
        }
      }

      .related-posts__date {
        opacity: 1;
        transform: translateY(0);
        transition: opacity 0.3s ease 0.3s, transform 0.3s ease 0.3s;
      }

      .related-posts__button {
        opacity: 1;
        transform: translateY(0);
        transition: opacity 0.3s ease 0.5s, transform 0.3s ease 0.5s;
      }
    }
  }

  &__info {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 2;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc(120px + 15%);
    padding: 10%;
    transition: height 0.3s ease 0.4s;

    @media screen and (max-width: 576px) {
      flex-direction: row;
      align-items: flex-end;
      height: 100%;
      padding: 5%;
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
      width: 100%;
      height: 100%;
      background-image: linear-gradient(270deg, #d96600 0, #f39f33);
      opacity: 0;
      transition: opacity 0.3s ease 0.3s;

      @media screen and (max-width: 576px) {
        display: none;
      }
    }
  }

  &__name {
    z-index: 1;
    flex-shrink: 0;
    width: 100%;
    color: #fff;
    font-weight: 200;
    font-size: 16px;
    line-height: 1.2;
    text-transform: uppercase;
  }

  &__date {
    z-index: 1;
    flex-grow: 1;
    color: #fff;
    margin-top: 5px;
    font-weight: 200;
    font-size: 13px;
    transform: translateY(30px);
    opacity: 0;
    transition: opacity 0.3s ease 0.2s, transform 0.3s ease 0.2s;

    @media screen and (max-width: 576px) {
      display: none;
    }
  }

  &__button {
    z-index: 1;
    width: max-content;
    padding: 4px;
    @include interpolate(font-size, 992px, 1920px, 11px, 14px);
    transform: translateY(30px);
    opacity: 0;
    transition: opacity 0.3s ease, transform 0.3s ease;

    @media screen and (max-width: 576px) {
      display: none;
    }
  }
}

/* BLog form discuss */
.blog-form-discuss {
  width: 100%;

  .blog-form__wrapper {
    display: flex;
    width: 100%;

    @media all and (max-width: 1400px) {
      flex-wrap: wrap;
    }

    .blog-form__wrapper-item {
      position: relative;
      width: 50%;
      padding: 35px 40px;

      @media all and (max-width: 1400px) {
        width: 100%;
      }

      @media all and (max-width: 375px) {
        padding: 15px;
      }

      &.wrapper-left-side {
        background: #3e3e3e;
        display: flex;
        position: relative;

        img {
          position: absolute;
          right: -30px;
          z-index: 1;
          bottom: 0;
          width: 275px;
          height: auto;

          @media all and (max-width: 1400px) {
            width: auto;
            max-height: 90%;
            right: 0;
          }

          @media all and (max-width: 375px) {
            display: none;
          }
        }

        .wrapper-left-side_item {
          position: relative;
          z-index: 2;
          width: 70%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          @media all and (max-width: 375px) {
            width: 100%;
          }

          .blog-form__title {
            margin:20px 0 17px 0;
            font-size: 30px;
            font-weight: 600;
            line-height: 1.2;
            letter-spacing: 0.02em;
            color: #e27826;
            text-transform: uppercase;
          }

          .blog-form__description-text {
            font-size: 16px;
            line-height: 1.2;
            letter-spacing: 0.02em;
          }

          .blog-form__contacts {
            @media all and (max-width: 1400px) {
              margin-bottom: 30px;
            }
          }

          a {
            display: block;
            font-size: 20px;
            line-height: 1.2;
            letter-spacing: 0.02em;
            color: #ffffff;
            transition: color 0.4s ease-in-out;

            &:hover {
              color: #e27826;
            }

            &:not(a:last-child) {
              margin-bottom: 20px;
            }
          }

          .blog-social {
            margin-bottom: 0;
          }

          .blog-social__item {
            & + .blog-social__item {
              margin-left: 28px;
            }

            .blog-social__link {
              opacity: 1;

              span {
                font-size: 37px;
                color: #ffffff;
                transition: color 0.4s ease-in-out;
              }

              &:hover {
                span {
                  color: #e27826;
                }
              }
            }
          }
        }
      }

      &.wrapper-right-side {
        background: #191919;

        @media all and (max-width: 375px) {
          padding: 15px 0;
        }

        .form-group {
          position: relative;
          width: 100%;
          margin-bottom: 20px;
        }

        .placeholder-text {
          position: absolute;
          color: #999999;
          top: 12px;
          left: 16px;

          span {
            color: red;
          }
        }

        .form-input {
          width: 100%;
          padding: 10px 16px;
          border-radius: 3px;
          border: 1.5px solid #444444;
          color: #999999;
          background: #252525;

          &:hover {
            border: 1.5px solid #ffffff;
          }
        }

        textarea {
          height: 130px;
        }

        .form-input::placeholder {
          color: #999999;
        }
      }
    }
  }
}

/* Feedback Form*/

.blog-feedback-form {
  padding: 20px 1.55vw 50px;
  background: #191919;

  .blog-form__title {
    max-width: 230px;
    margin: 0 auto 25px;
    font-size: 26px;
    font-weight: 600;
    color: #e27826;
    line-height: 1.2;
    text-transform: uppercase;
  }

  @media all and (max-width: 375px) {
    padding: 15px 0;
  }

  .form-group {
    position: relative;
    width: 100%;
    margin-bottom: 20px;
  }

  .form-input {
    letter-spacing: 0.02em;
    line-height: 1.2;
  }

  .placeholder-text {
    position: absolute;
    color: #999999;
    top: 12px;
    left: 16px;

    span {
      color: red;
    }
  }

  .form-input {
    width: 100%;
    padding: 10px 16px;
    border-radius: 3px;
    border: 1.5px solid #444444;
    color: #999999;
    background: #252525;

    &:hover {
      border: 1.5px solid #ffffff;
    }
  }

  textarea {
    height: 90px;
  }

  .form-input::placeholder {
    color: #999999;
  }

  .btn {
    margin: 0 auto;

    @media all and (max-width: 1200px) {
      height: auto;
      white-space: normal;
    }
  }

  &.information-block {
    text-align: center;

    .image-wrapper {
      margin: 0 0 17px 0;

      img {
        width: 100%;
      }
    }

    .description-text {
      margin: 0 0 20px;
      font-size: 16px;
      font-weight: 700;
      letter-spacing: 0.02em;
      line-height: 1.5;

      span {
        color: #e27826;
      }
    }

    .btn {
      display: inline-flex;
    }
  }
}

form .agree-wrapper {
  & .form-group.agree {
    font-weight: 400;
    display: inline-block;
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    color:#fff;
    user-select: none;
    line-height: 23px;
    letter-spacing: 0.02em;
    font-size: 14px;

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;

      &:checked {
        ~ .checkmark:after {
          display: block;
        }
      }
    }

    .checkmark {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      height: 18px;
      width: 18px;
      border-radius: 2px;
      background: #fff;

      &::after {
        content: "";
        position: absolute;
        display: none;
        left: 6px;
        top: 3px;
        width: 6px;
        height: 10px;
        border: solid #f39f33;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }

    label {
      span {
        cursor: pointer;
        color: #fff !important;
        text-decoration: underline;
      }
    }
  }
}

.popup__wrapper_contacts .form-group.agree, .popup__wrapper.popup__wrapper_default .form-group.agree {
  margin: 20px 0 0 0;
}

.terms {
  font-family: 'Montserrat' sans-serif;

  &_title {
  text-align: center;

    h1 {
      color: #e27826;
    }

    p {
      color: #444;
    }
  }

  &_table {
    a {
      color: #fff;
      text-decoration: underline;
    }
  }

  &_head {
    font-size: 24px;
  }

  ul {
    li {
      margin-bottom: 9px;
    }
  }
}