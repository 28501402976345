//GENERAL FOR BREADCRUMBS
.breadcrumbs-wrapper {
  left: 0;
  top: 0;
  position: absolute;
  background: transparent;
  z-index: 5;

  &.breadcrumbs_landing_page {
    position: static;
    background-color: #252525;
    margin-bottom: 0;
    padding-bottom: 20px;

    li {
      display: inline;
    }

    @include media-breakpoint-down(sm) {
      margin-top: 0;
      padding-top: 80px;
    }
  }

  @include media-breakpoint-down(sm) {
    margin-top: 80px;
  }

  .breadcrumb {
    background-color: transparent;

    li.active {
      display: inline;
    }
  }

  .breadcrumb > li + li:before {
    content: "›";
    padding: 0 5px;
    color: rgba(#fff, .7);
  }

  .breadcrumb {
    li,
    li * {
      color: rgba(#fff, .7);

      &.active,
      &.active > * {
        color: #e27826;
      }
    }
  }
}
//GENERAL END

//ORANGE BACKGROUND PAGES
.breadcrumbs-wrapper__departments,
.breadcrumbs-wrapper__about-us{

  .breadcrumb {
    background-color: transparent;

    li,
    li * {
      color: rgba(#252525, .8);

      &.active,
      &.active > * {
        color: #fff;
      }
    }
  }

  .breadcrumb > li + li:before {
    color: #252525;
  }
}

//ORANGE BACKGROUND PAGES END

//INDIVIDUAL BREADCRUMBS
.breadcrumbs-wrapper__contacts {
  .breadcrumb {
    background-color: rgba(31, 31, 31, .9);
    margin-left: 15px;
  }
}
//INDIVIDUAL BREADCRUMBS END
