// styles for section facts
.hipaa-landing section.who-guide {
  @media all and (max-width: 768px) {
    padding-left: 0;
    padding-right: 0;
  }

  .item-text_wrapper-item {
    display: flex;
    margin-bottom: 22px;
    min-height: 50px;

    p {
      margin-bottom: 0;
    }

    .icon-check {
      color: #E27826;
      margin-right: 20px;

      &:before {
        font-size: 24px;
      }
    }

    &:last-child {
      margin-bottom: 0;

      @include media-breakpoint-down(md) {
        margin-bottom: 22px;
      }
    }
  }

  p {
    font-size: 14px;
    line-height: 1.214;
    position: relative;
  }

  &.not-guide {
    .item-text_wrapper-item {
      margin-bottom: 10px;
      min-height: auto;

      &:last-child {
        margin-bottom: 20px;
      }

      &.out-icon {
        margin-bottom: 20px;
      }
    }

    .text-description {
      font-size: 16px;
    }
  }
}