.animation-banner {
    height: 300px;
    position: relative;
    overflow: hidden;

    @include media-breakpoint-down(xs) {
        height: 475px;
    }

    a,
    a:hover {
        text-decoration: none !important;
    }

    &_bg {
        position: absolute;
        left: 0;
        top: -1px;
        right: -1px;
        bottom: 0;
        width: 100%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        z-index: 1;

        @include media-breakpoint-down(xs) {
            background-position: 50% 23%;
        }
    }

    &_stripe {
        position: absolute;
        width: 200px;
        z-index: 3;
        top: -100px;
        animation-name: stripe;
        animation-duration: 4s;
        animation-iteration-count: infinite;
    }

    &_line {
        width: 180px;
        transform: rotate(-145deg);
        height: 500px;
        margin: 0 auto;
        background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.27) 50%, rgba(255, 255, 255, 0) 100%);

        @include media-breakpoint-down(xs) {
            height: 680px;
        }
    }

    &_fg {
        position: relative;
        height: 100%;
        z-index: 4;
    }

    &_wrap {
        width: 100%;
        height: inherit;
        max-width: 800px;
        padding: 0 20px;
        margin: auto;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        @include media-breakpoint-down(xs) {
            justify-content: center;
        }
    }

    &_inner {
        display: flex;
        flex-direction: column;
        align-items: center;

        @include media-breakpoint-down(xs) {
            align-self: flex-end;
            margin-bottom: 30px;
            max-width: 300px;
        }
    }

    .h2 {
        text-transform: uppercase;
        color: #fff;
        font-weight: 500;
        font-size: 24px;

        @include media-breakpoint-down(sm) {
            font-size: 20px;
            text-align: center;
        }
    }

    &_img {
        position: relative;
        display: flex;
        padding-top: 30px;

        .banner_circle {
            position: absolute;
            top: 12px;
            left: -3px;
            animation-name: pulse-circle;
            animation-duration: 4s;
            animation-iteration-count: infinite;
        }

        .banner_finger {
            z-index: 2;
            animation-name: pulse-finger;
            animation-duration: 4s;
            animation-iteration-count: infinite;
        }
    }
}

.healthcare-banner {
    .animation-banner_bg {
        background-image: url('/static/images/healthcare/banner-link.jpg');

        @include media-breakpoint-down(xs) {
            background-image: url('/static/images/healthcare/banner_mobile.jpg');
        }
    }
}

.laravel-banner {
    .animation-banner {
        &_bg {
            background-image: url('/static/images/laravel_banner.webp');
            
            @include media-breakpoint-down(lg) {
                background-position:  30%;
            }

            @include media-breakpoint-down(xs) {
                background-image: url('/static/images/laravel_banner_mobile.webp');
                background-position:  50% 25%;
            }
        }

        &_wrap {
            max-width: 1000px;
        }

        &_inner {
            @include media-breakpoint-down(lg) {
                max-width: 350px;
                text-align: center;
            }
        }
    }
}

.offshore-banner {
    .animation-banner {
        &_bg {
            background-image: url('/static/images/offshore/offshore_banner.jpg');
            
            @include media-breakpoint-down(xs) {
                background-image: url('/static/images/offshore/offshore_banner_mobile.jpg');
            }
        }

        &_wrap {
            max-width: 1000px;
        }

        &_inner {
            max-width: 350px;
            text-align: center;
        }
    }
}

.security-banner {
    .animation-banner {
        &_bg {
            background-image: url('/static/images/security/security_banner.jpg');
            
            @include media-breakpoint-down(xs) {
                background-image: url('/static/images/security/security_banner_mobile.jpg');
            }
        }

        &_wrap {
            max-width: 1000px;
        }

        &_inner {
            max-width: 350px;
            text-align: center;
        }
    }
}

.telemedicine-banner {
    .animation-banner {
        &_bg {
            background-image: url('/static/images/telemedicine/telemedicine_banner.jpg');
            
            @include media-breakpoint-down(xs) {
                background-image: url('/static/images/telemedicine/telemedicine_banner_mobile.jpg');
            }
        }

        &_wrap {
            max-width: 1000px;
        }

        &_inner {
            max-width: 350px;
            text-align: center;
        }
    }
}

@keyframes pulse-circle {
    0% {
        transform: scale(0)
    }

    50% {
        transform: scale(0.8)
    }

    100% {
        transform: scale(0)
    }
}

@keyframes pulse-finger {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(0.9);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes stripe {
    0% {
        right: -300px;
    }

    100% {
        right: 130%;
    }
}