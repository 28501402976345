// styles for section facts
.hipaa-landing .hipaa-cybersecurity-description {
  padding: 50px 20px;
  background: #fff;
  margin-bottom: 30px;
  @media all and (max-width: 768px) {
    padding-left: 0;
    padding-right: 0;
  }

  h2.section-title {
    color: #252525;
    font-size: 25px;
    font-weight: 500;
    line-height: 1.2;
  }

  .text-description {
    font-size: 16px;
    font-weight: 400;
    color: #252525;
    line-height: 1.25;
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}