.departments {
  overflow-x: hidden;
  position: relative;
  width: 100%;
  min-height: 100vh;
  background-image: linear-gradient(to left, #e27826 0%, #f5a535 100%);
  background-size: 300% 100%;
  -webkit-animation: Gradient 5s ease infinite;
  -moz-animation: Gradient 5s ease infinite;
  animation: Gradient 5s ease infinite;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;

  &:before {
    content: '';
    width: 100%;
    height: 100%;
    background-image: url(/static/images/figures.webp);
    background-position: 100px 0;
    display: block;
    background-size: contain;
    opacity: 0.1;
    position: absolute;
    top: 0;
    left: 0;
  }

  @include media-breakpoint-down(md) {
    height: 100%;
  }

  &_head {
    margin-top: 30px;

    .h1 {
      text-transform: uppercase;
      font-size: 20px;
      font-weight: 600;

      @include media-breakpoint-up(md) {
        font-size: 30px;
      }
    }

    @include media-breakpoint-down(sm) {
      margin-top: 120px;
      margin-bottom: 30px;
    }
  }

  &_content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    max-width: 1024px;
    margin: auto;
  }

  &_item {
    width: calc(100% / 3);
    padding: 20px;

    @include media-breakpoint-down(md) {
      width: 50%;
      padding: 10px;
    }
  }

  &_bottom {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

.department {
  position: relative;
  cursor: pointer;
  z-index: 4;
  height: 200px;
  width: 200px;
  min-width: 200px;
  box-shadow: 0 0 26px 10px rgba(84, 42, 5, 0.53);
  background: transparent;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: background-color .3s ease, transform .3s ease;
  color: #fff;
  transform: scale(1.1);

  @include media-breakpoint-down(md) {
    height: 175px;
    width: 175px;
    min-width: 175px;
    transform: scale(1);
  }

  @include media-breakpoint-down(xs) {
    transform: scale(0.9);
  }

  &__wrapper {
    display: flex;
    justify-content: center;
    position: relative;
    z-index: 1;

    .icon-pm,
    .icon-backend,
    .icon-design,
    .icon-frontend,
    .icon-qa,
    .icon-marketing {
      font-size: 35px;
    }
  }

  .orbit__icon {
    height: 26px;
    position: absolute;
    display: block;
    z-index: 4;
    visibility: hidden;
    transform: translate(0, 0);
  
    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  &:hover {
    background-color: #fff;
    transform: scale(1.3);

    @include media-breakpoint-down(md) {
      transform: scale(1.2);
    }

    span[class^=icon-] {
      color: #e27826;
      transition: fill .3s ease;
    }

    .department__title {
      color: #333;
      backface-visibility: hidden;
    }

    @include media-breakpoint-down(md) {
      .department__orbit {
        display: none;
      }

      .orbit__icon {
        display: none;
      }
    }

    @include media-breakpoint-up(md) {
      .department__orbit {
        opacity: 1;
      }

      .department__orbit_1 {
        transform: scale(1.3);
      }

      .department__orbit_2 {
        transform: scale(1.6);
      }

      .orbit__icon {
        z-index: 3;
        position: absolute;
        visibility: visible;

        @for $i from 1 through length($orbit-start-point) {
          &:nth-of-type(#{$i}) {
    
            @include myOrbit(nth($orbit-start-point, $i), $i);
          }
        }
      }
    }
  }

  &:nth-child(3n) {
    margin-right: 0;
  }

  &__title {
    font-size: 14px;
    font-weight: 200;
    max-width: 80%;
    text-align: center;
    transition: color .3s ease, font-size .3s ease;
    backface-visibility: hidden;
    margin-top: 10px;
  }

  &__svg {
    height: 50px;

    &_main {
      @include interpolate(height, 768px, 1920px, 35px, 50px);
    }

    @include media-breakpoint-down(xs) {
      height: 40px;
    }
  }

  &__orbit {
    position: absolute;
    background-color: transparent;
    border: 1px solid #fff;
    border-radius: 50%;
    width: 100%;
    height: 100%;
    transform: scale(.7);
    z-index: 1;
    opacity: 0;
    transition: transform .3s ease, opacity .3s ease;

    &_2 {
      transition: transform ease .6s, opacity .3s ease;
    }
  }

  span[class^=icon-] {
    font-size: 48px;
  }

  [class^="icon-"] {
    color: #fff;
  }

  i[class^="icon-"] {
    font-size: 34px;
  }

  i.icon-java {
    font-size: 36px;
  }
}

@keyframes Gradient {
  0% {
    background-position: 0 50%
  }

  50% {
    background-position: 100% 50%
  }

  100% {
    background-position: 0 50%
  }
}

.departments {
  &_info {
    z-index: 1;
    margin-bottom: 15px;
    
    a {
      color: #fff !important;
      border-bottom: 1px dashed #dbdada;
      margin: 10px;
      cursor: pointer;
      font-size: 16px;
    }
  }

  &-additional {
    h1 {
      text-align: center;
    }
  }
}

.breadcrumbs-wrapper__departments-of-our-company {
  & .breadcrumb li * {
    color: #252525;
  }

  & .breadcrumb li.active {
    &:before {
      color: #252525;
    }

    &>span {
      color: #fff;
    }
  }
}