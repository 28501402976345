.page-content__wrapper__healthcare-software-development-services {
    .content-inner section.well {
        max-width: 100%;
        margin: 0;
    }

    .content-inner .section-title {
        text-transform: uppercase;
        font-weight: 500;
    }

    .testimonials-slider-wrapper h2 {
        display: none;
    }
}