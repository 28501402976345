@mixin myOrbit($start-position, $i) {
  animation: Scale_#{$i} .5s ease-in-out, myOrbit_#{$i} 14s linear infinite .5s;
  -webkit-animation: Scale_#{$i} .5s ease-in-out, myOrbit_#{$i} 14s linear infinite .5s;

  @keyframes myOrbit_#{$i} {
    0% {
      transform: rotate(0deg) $start-position rotate(0deg);
      -webkit-transform: rotate(0deg) $start-position rotate(0deg);
    }
    100% {
      transform: rotate(360deg) $start-position rotate(-360deg);
      -webkit-transform: rotate(360deg) $start-position rotate(-360deg);
    }
  }

  @-webkit-keyframes myOrbit_#{$i} {
    0% {
      -webkit-transform: rotate(0deg) $start-position rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg) $start-position rotate(-360deg);
    }
  }

  @keyframes Scale_#{$i} {
    0% {
      opacity: 0;
      transform: scale(0) $start-position;
    }
    50% {
      opacity: 0.3;
      transform:  scale(0.5) $start-position;
    }
    100% {
      opacity: 1;
      transform: scale(1) $start-position;
    }
  }

  @-webkit-keyframes Scale_#{$i} {
    0% {
      opacity: 0;
      -webkit-transform: scale(0) $start-position;
    }
    50% {
      opacity: 0.3;
      -webkit-transform: scale(0.5) $start-position;
    }
    100% {
      opacity: 1;
      -webkit-transform: scale(1) $start-position;
    }
  }
}