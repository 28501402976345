.pagination {
  position: absolute;
  bottom: 0;
  right: 0;
  margin-right: -10px;

  li {
    background-color: transparent;
    text-align: center;
    font-weight: 300;

    a, span {
      background-color: transparent;
      border: 2px solid #db7425;
      color: #db7425;
      font-weight: 600;
      font-size: 14px;
      opacity: .5;
      border-radius: 0;
      margin-right: 5px;
      margin-left: 5px;
      transition: opacity .3s ease;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      @include interpolate(height, 768px, 1920px, 25px, 35px);
      @include interpolate(width, 768px, 1920px, 25px, 35px);

      span {
        margin: 0;
        border: none;
      }

      &:hover {
        opacity: .9;
        background-color: rgba(217, 102, 0, 0.2);
        border-color: #db7425 !important;
        color: #db7425 !important;
      }
    }

    &.active {
      a, span {
        border-radius: 0;
        background-color: rgba(217, 102, 0, 0.4);
        border: 2px solid #db7425;
        color: #db7425;

        span {
          margin: 0;
          border: none;
        }
      }

      &:hover {
        span {
          background: rgba(217, 102, 0, 0.2) !important;
        }
      }
    }

    &.prev, &.next {
      & > a, & > span {
        border-radius: 0;
        background: transparent;
        border: 2px solid #db7425;
        color: #db7425;

        span {
          margin: 0;
          border: none;
        }
      }
    }

    &.disabled {
      a, span {
        opacity: .4;
        cursor: default !important;

        &:hover {
          background: transparent;
        }

        span {
          margin: 0;
          border: none;
        }
      }
    }

    &.active {
      span {
        opacity: 1;
        margin: 0 5px;
      }
    }
  }
}

.search-result__paginator {
  z-index: 2;
}

//related to back

.journal-content .paginator {
  margin-top: 30px;
}
