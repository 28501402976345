@use "sass:math";

@function calc-interpolation($min-screen, $min-value, $max-screen, $max-value, $important: false) {
  $a: math.div(($max-value - $min-value), ($max-screen - $min-screen));
  $b: $min-value - $a * $min-screen;

  $sign: "+";
  @if ($b < 0) {
    $sign: "-";
    $b: abs($b);
  }

  $res: calc(#{$a*100}vw #{$sign} #{$b});

  @if ($important == true) {
    $res: #{$res} #{' !important'};
  }

  @return $res;
}

@mixin interpolate($properties, $min-screen, $max-screen, $min-value, $max-value, $important: false) {
  & {
    @each $property in $properties {
      #{$property}: $min-value;
    }

    @media screen and (min-width: $min-screen) {
      @each $property in $properties {
        #{$property}: calc-interpolation($min-screen, $min-value, $max-screen, $max-value, $important);
      }
    }

    @media screen and (min-width: $max-screen) {
      @each $property in $properties {
        @if ($important == true) {
          #{$property} : #{$max-value} #{' !important'};
        } @else {
          #{$property}: $max-value;
        }
      }
    }
  }
}
