.futered-post-footer {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #1f1f1f;

  &__title {
    @include interpolate(font-size, 768px, 1920px, 20px, 25px);
    text-align: center;
    font-weight: 200;

    @include media-breakpoint-down(sm) {
      @include interpolate(font-size, 320px, 767px, 27px, 45px);
      margin-top: 0;
      margin-bottom: 15px;
    }
  }

  &__wrapper {
    width: 100%;
    padding-bottom: 30px;

    &__wrapper {
      width: 100%;
      padding-bottom: 30px;
    }
  }

  &__flex-container {
    display: flex;
    height: 100%;
    justify-content: center;
    margin-bottom: 30px;
    padding-left: 55px;
    padding-right: 55px;
    margin-right: -15px;
    margin-left: -15px;

    @include media-breakpoint-down(sm) {
      flex-direction: column;
      align-items: center;
    }
  }

  &__button {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
  .blog-box__wrapper {
    width: 33.333%;
    padding-left: 15px;
    padding-right: 15px;
    @include interpolate(height, 768px, 1920px, 200px, 350px);

    @include media-breakpoint-down(sm) {
      width: 100vw;
      max-width: 767px;
      height: auto;
    }
  }

  .blog-box {
    margin-top: 15px;
    max-height: 100%;
    width: 100%;
    display: flex;

    &::after {
      content: "";
      display: block;
      padding-bottom: 100%;
    }
  }
}

@include media-breakpoint-down(sm) {
  .futered-post-footer {
    padding-left: 20px;
    padding-right: 20px;

    .blog-box__read-more_mobile {
      width: 100%;
      position: absolute;
      height: 100%;
      display: block;
      opacity: 0;
      z-index: 3;
    }

    .blog__content {
      height: auto;
    }

    .blog__grid {
      margin-left: 0;
      margin-right: 0;
      height: auto;
    }

    .blog-box {
      background-size: 50% 100%;
      background-position: 0 0;
      background-repeat: no-repeat;

      &:before {
        display: none;
      }

      &:after {
        padding-bottom: 50%;
      }

      &:hover {

        .blog-box__content {
          max-height: 100% !important;
          @include interpolate(padding, 320px, 767px, 5px, 20px);
          transition: max-height .3s ease;
        }

        .blog-box__button {
          display: none;
        }
      }
    }

    .blog__box_wrapper {
      width: 100%;
      padding: 0;
    }

    .blog-box__content {
      display: flex;
      justify-content: space-around;
      width: 50%;
      margin-left: 50%;
      max-height: 100% !important;
      bottom: auto;
      @include interpolate(padding, 320px, 767px, 7px, 20px);
      height: auto;
      border: 1px solid #fff;

      &_image {
        background: transparent;
      }
    }

    .blog-box__date {
      opacity: 1;
      max-height: 100%;
      transform: translateY(0);
    }

    .blog-box__tag {
      opacity: 1;
      max-height: 100%;
      transform: translateY(0);
      @include interpolate(font-size, 320px, 767px, 12px, 14px);
      @include interpolate(padding-top, 320px, 767px, 3px, 9px);
      @include interpolate(padding-bottom, 320px, 767px, 3px, 9px);
      @include interpolate(padding-right, 320px, 767px, 4px, 14px);
      @include interpolate(padding-left, 320px, 767px, 4px, 14px);
    }
  }

  .blog-box__button {
    display: none;
  }

  .blog-box_int .blog-box__button {
    display: flex;
  }

  .blog-tags-list {
    display: none;
  }

  .search-result__paginator {
    width: 100%;
    margin-top: 20px;
    padding-left: 0;
  }
}
