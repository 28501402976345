// styles for section testimonials
.expertise-internal {
  section.testimonials {
    background-color: #fff;

    @include media-breakpoint-down(sm) {
      padding-bottom: 0;
    }

    > .section-title {
      color: #000;

      @include media-breakpoint-down(sm) {
        font-size: calc(4.9217vw + 10.25056px);
      }
    }

    section.testimonials-slider {
      padding: 0;

      @include media-breakpoint-down(sm) {
        padding: 1rem 0 3rem;
      }
    }

    .lang {
      text-transform: uppercase;
      font-weight: 500;
    }
  }
}
