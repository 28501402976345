// styles for section facts
.hipaa-landing {
  .facts {
    padding-left: 30px;
    padding-right: 30px;
    background-color: #2C3462;
    @media all and (max-width: 768px) {
      padding-left: 0;
      padding-right: 0;
    }

    .section-subtitle {
      @media all and (max-width: 480px) {
        text-align: left;
      }
    }

    .text-note {
      color: #EFA13C;
      font-weight: 600;

      @media all and (max-width: 480px) {
        text-align: left;
      }

      a {
        color: $accent;
        text-decoration: underline;
      }
    }

    .facts-list {
      display: flex;
      flex-wrap: wrap;

      .list-item {
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;

        @media all and (max-width: 480px) {
          display: inline-block;
        }

        .list-item-layout {
          display: flex;
          align-items: flex-start;

          img {
            min-width: 40px;
            width: 40px;
            margin-right: 20px;
            margin-top: 6px;
          }

          .text-title {
            font-weight: 600;
          }

          .icon-elephant {
            font-size: 40px;
            margin-right: 20px;
            color: #e27826;
          }
        }
      }
    }
  }
}