@import "../../mixins/bs_breakpoints.scss";
@import "../../mixins/adjustable-font-size";

.blog {
  background-color: #242424;
  width: 100%;
  padding: 0 6.2vw;
  overflow-y: hidden;
  position: relative;

  @include media-breakpoint-down(md) {
    overflow-y: auto;
  }

  &__not-found {
    font-size: 2em;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%);
    font-weight: 200;
  }

  .pagination {
    right: inherit !important;
    position: relative;
    margin: 20px 0 0 auto !important;
  }

  .blog-title{
    text-align: center;
    text-transform: uppercase;
    font-size: 30px;
    font-weight: 600;
  }

  .main-section__content {
    height: 100%;
    padding-top: 23px;
    padding-bottom: 23px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    @include media-breakpoint-down(md) {
      height: auto;
      min-height: 100vh;
    }
    @include media-breakpoint-down(sm) {
      padding-top: 128px;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: calc(100% - 50px);
    height: auto;
    position: relative;
  }

  &-search {
    display: flex;
    align-items: center;

    &.focused {
      align-items: flex-end;

      .blog-search__input {
        padding-left: 0;
      }

      .blog-search__before {
        display: none !important;
      }

      .blog-search__after {
        display: inline-block !important;
        cursor: pointer;
      }
    }

    &__input {
      border: none;
      border-bottom: 1px solid rgba(#fff, .5);
      outline: none;
      line-height: 44px;
      width: 65%;
      font-size: 17px;
      background-color: transparent;
      padding-left: 15px;

    }

    &__before {
      position: absolute;
      opacity: .5;
    }

    &__after {
      display: none;
      border: 1px solid white;
      padding: 11px;
      font-size: 18px;
      height: 44px !important;
      width: 44px !important;
      margin-left: 10px;
    }

  }

  &__grid {
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    @include media-breakpoint-down(sm) {
      margin-bottom: 100px;
    }
  }

  &-box {
    width: 100%;
    position: relative;
    margin-top: 28px;
    overflow: hidden;
    background-size: cover;

    &:before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      z-index: 0;
      top: 0;
      width: 100%;
      height: 102%;
      background-image: linear-gradient(to top, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 100%);
    }

    @include media-breakpoint-down(md) {
      &::after {
        content: "";
        display: block;
        padding-bottom: 100%;
      }
    }

    @include media-breakpoint-down(sm) {
      background: transparent;
    }

    &__image-mobile {
      display: none;

      @include media-breakpoint-down(sm) {
        display: block;
        height: 100%;
        width: 50%;
        position: absolute;
        background-size: cover;
        background-position: 50%;
        background-repeat: no-repeat;
      }
    }

    &__content {

      width: 100%;
      height: 100%;
      display: flex;
      padding: 10% 10% 0 10%;
      flex-direction: column;
      align-items: flex-start;
      cursor: pointer;
      position: absolute;
      bottom: 0;
      transition: max-height .3s ease .4s;

      @include media-breakpoint-down(md) {
        padding: 7% 7% 0 7%;
      }

      @include media-breakpoint-down(sm) {
        position: relative;

        min-height: calc(50vw - 20px);
      }

      &_image {
        opacity: 0;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        transition: opacity .3s ease .3s;
        background-image: linear-gradient(to left, #d96600 0%, #f39f33 100%);
      }

    }

    &__title {
      margin-top: 0;
      margin-bottom: 0;
      text-transform: uppercase;
      font-weight: 200;
      font-size: 16px;
      line-height: 1.2;
      z-index: 1;

      @include media-breakpoint-down(sm) {
        font-size: 13px;
      }
    }

    &__date {
      z-index: 1;
      opacity: 0;
      max-height: 0;
      transform: translateY(30px);
      transition: opacity .3s ease .2s, transform .3s ease .2s, max-height 0s .5s;
    }

    &__button {
      height: 36px;
      width: 113px;
      @include interpolate(width, 992px, 1920px, 90px, 110px);
      @include interpolate(height, 992px, 1920px, 25px, 30px);
      @include interpolate(font-size, 992px, 1920px, 11px, 14px);
      position: absolute;
      bottom: 8%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      z-index: 1;
      opacity: 0;
      max-height: 0;
      transform: translateY(30px);
      transition: opacity .3s ease, transform .3s ease, max-height 0s .3s;

      & > span {
        display: none;
      }

      @include media-breakpoint-down(md) {
        @include interpolate(width, 768px, 991px, 100px, 120px);
        @include interpolate(height, 768px, 991px, 30px, 40px);
        @include interpolate(font-size, 768px, 991px, 11px, 16px);

        bottom: 7%;
      }

    }

    &__tag.blog-tag {
      z-index: 1;
      margin-top: 10px;
      opacity: 0;
      max-height: 0;
      color: #fff;
      transform: translateY(30px);
      transition: opacity .3s ease .1s, transform .3s ease .1s, max-height 0s .4s;

      a {
        color: inherit;
      }
    }
  }
}

.blog-box:hover {

  .blog-box__content {
    max-height: 100% !important;
    padding: 10%;
    transition: max-height .3s ease;

    @include media-breakpoint-down(md) {
      padding: 7%;
    }

    &_image {
      opacity: 1;
      transition: opacity .5s ease .1s;
    }

    &:before {
      display: none;
    }
  }

  .blog-box__date {
    opacity: 1;
    transform: translateY(0);
    transition: opacity .3s ease .3s, transform .3s ease .3s;
    max-height: 100%;
  }

  .blog-box__tag {
    max-height: 100%;
    opacity: 1;
    transform: translateY(0);
    transition: opacity .3s ease .4s, transform .3s ease .4s;

  }

  .blog-box__button {
    max-height: 100%;
    opacity: 1;
    transform: translateY(0);
    transition: opacity .3s ease .5s, transform .3s ease .5s;
  }
}

.blog-tag {
  @include interpolate(padding-top, 992px, 1920px, 4px, 9px);
  @include interpolate(padding-bottom, 992px, 1920px, 4px, 9px);
  @include interpolate(padding-right, 992px, 1920px, 6px, 14px);
  @include interpolate(padding-left, 992px, 1920px, 6px, 14px);
  display: inline-block;
  background-color: transparent;
  font-weight: 200;

  @include media-breakpoint-down(md) {
    @include interpolate(padding-top, 768px, 991px, 4px, 9px);
    @include interpolate(padding-bottom, 768px, 991px, 4px, 9px);
    @include interpolate(padding-right, 768px, 991px, 6px, 14px);
    @include interpolate(padding-left, 768px, 991px, 6px, 14px);
  }
}

.blog-date {
  font-weight: 200;
  font-size: 13px;
  display: block;
  margin-bottom: 0;
  margin-top: 5px;
}

.blog-tags-list {
  width: 50%;
  padding-left: 0;
  margin-top: 10px;
  margin-bottom: 0;

  &__item {
    margin-right: 10px;
    padding: 0;

    a {
      color: #929292;
      transition: .3s ease-in-out;

      &:hover {
        color: #ffffff;
      }
    }
  }
}

.blog-footer {
  display: flex;
  align-items: center;
  margin-top: 20px;
  justify-content: space-between;
}

.blog__grid {
  width: auto;
  position: relative;
  margin-left: -15px;
  margin-right: -15px;

  .blog__box_wrapper {
    width: 33.333%;
    position: relative;
    height: calc(48.5vh - 100px);
    padding-left: 15px;
    padding-right: 15px;
    justify-content: center;
    display: flex;
    align-items: center;
    min-width: 220px;

    @include media-breakpoint-down(md) {
      width: 50%;
      height: auto;
    }

    @include media-breakpoint-down(sm) {
      height: 100%;
    }
  }

  .blog__box {
    height: calc(100% - 28px);
    width: 100%;
    background-position: center;
    background-size: cover;

    @include media-breakpoint-down(md) {
      width: 100%;
    }

    @include media-breakpoint-down(sm) {
      display: flex;
    }
  }
}

.blog-box__read-more_mobile-desktop {
  width: 100%;
  position: absolute;
  height: 100%;
  display: block;
  opacity: 0;
  z-index: 3;

  &:hover,
  &:active,
  &:focus {
    opacity: 0;
  }

  & > span {
    display: none;
  }
}

//ONLY FOR BLOG PAGE ON MOBILE
@include media-breakpoint-down(sm) {

  .blog {
    padding: 20px;

    .blog__content {
      height: auto;
    }

    .blog__grid {
      margin-left: 0;
      margin-right: 0;
      height: auto;

      @include media-breakpoint-down(sm) {
        margin-bottom: 50px;
      }
    }

    .blog-box {
      background-size: 50% 100%;
      background-position: 0 0;
      background-repeat: no-repeat;

      &:before {
        display: none;
      }

      &:after {
        padding-bottom: 50%;
      }

      &:hover {

        .blog-box__content {
          max-height: 100% !important;
          @include interpolate(padding, 320px, 767px, 5px, 20px);
          transition: max-height .3s ease;
        }
      }
    }

    .blog__box_wrapper {
      width: 100%;
      padding: 0;
    }

    .blog-box__content {
      display: flex;
      justify-content: space-around;
      width: 50%;
      margin-left: 50%;
      max-height: 100%;
      bottom: auto;
      @include interpolate(padding, 320px, 767px, 7px, 20px);
      height: auto;
      border: 1px solid #fff;

      &_image {
        background: transparent;
      }
    }

    .blog-box__date {
      opacity: 1;
      max-height: 100%;
      transform: translateY(0);
    }

    .blog-box__tag {
      opacity: 1;
      max-height: 100%;
      transform: translateY(0);
      @include interpolate(padding-top, 320px, 767px, 2px, 9px);
      @include interpolate(padding-bottom, 320px, 767px, 2px, 9px);
      @include interpolate(padding-right, 320px, 767px, 3px, 14px);
      @include interpolate(padding-left, 320px, 767px, 3px, 14px);
    }
  }

  .blog-box__button {
    display: none;
  }

  .blog-box_int .blog-box__button {
    display: flex;
  }

  .blog-tags-list {
    display: none;
  }

  .search-result__paginator {
    width: 100%;
    margin-top: 20px;
    padding-left: 0;
  }
}

//ANIMATED SEARCH ICON
.search-wrapper {
  position: relative;
  width: 65%;

  @include media-breakpoint-down(md) {
    width: calc(100% - 60px);
  }
}

.search-wrapper .input-holder {
  height: 38px;
  width: 38px;
  box-sizing: border-box;
  overflow: hidden;
  border: none;
  background: rgba(255, 255, 255, 0);
  border-radius: 6px;
  position: relative;
  transition: all 0.3s ease-in-out;
}

.search-wrapper.active .input-holder {
  width: 100%;
  background: #272727;
  border: none;
  border-radius: 0;
  transition: opacity .5s cubic-bezier(0.000, 0.105, 0.035, 1.570);
}

.search-wrapper .input-holder .search-input {
  width: 100%;
  height: 38px;
  padding: 0 50px 0 20px;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  background: transparent;
  box-sizing: border-box;
  border: 1.5px solid #444444;
  outline: none;
  font-family: "Open Sans", Arial, Verdana;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: #FFF;
  transform: none;
}

.search-wrapper.active .input-holder .search-input {
  opacity: 1;
  border: 1.5px solid #444444;
  transform: none;
  transition: opacity 0.3s;
}

.search-wrapper .input-holder .search-icon {
  width: 38px;
  height: 38px;
  border: none;
  border-radius: 0;
  background: transparent;
  padding: 0;
  outline: none;
  position: relative;
  z-index: 2;
  float: right;
  cursor: pointer;
  transition: all 0.3s ease-in;
}

.search-wrapper.active .input-holder .search-icon {
  width: 38px;
  height: 38px;
  margin: 10px;
  border-radius: 30px;
}

.search-wrapper .input-holder .search-icon span {
  width: 22px;
  height: 22px;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  transform: rotate(-45deg);
  transition: all .4s cubic-bezier(0.650, -0.600, 0.240, 1.650);
}

.search-wrapper.active .input-holder .search-icon span {
  transform: rotate(45deg);
}

.search-wrapper .input-holder .search-icon span::before, .search-wrapper .input-holder .search-icon span::after {
  position: absolute;
  content: '';
}

.search-wrapper .input-holder .search-icon span::before {
  width: 4px;
  height: 11px;
  left: 9px;
  top: 18px;
  border-radius: 1px;
  background: white;
}

.search-wrapper .input-holder .search-icon span::after {
  width: 20px;
  height: 20px;
  left: 0;
  top: 0;
  border-radius: 50%;
  border: 2px solid white;
}

.search-wrapper .close {
  display: none;
  position: absolute;
  z-index: 1;
  top: 24px;
  right: 20px;
  width: 25px;
  height: 25px;
  cursor: pointer;
  opacity: 0;
  transform: rotate(-180deg);
  transition: transform .3s cubic-bezier(0.285, -0.450, 0.935, 0.110) .2s, opacity 0s;

  @include media-breakpoint-down(sm) {
    width: 15px;
    height: 15px;
    top: 30px;
  }
}

.search-wrapper.active .close {
  right: -50px;
  transform: rotate(45deg);
  transition: transform .6s cubic-bezier(0.000, 0.105, 0.035, 1.570) .5s, opacity .3s ease .5s;
  opacity: 1;
}

.search-wrapper .close::before, .search-wrapper .close::after {
  position: absolute;
  content: '';
  background: #fff;
  border-radius: 2px;
}

.search-wrapper .close::before {
  width: 3px;
  height: 25px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.search-wrapper .close::after {
  width: 25px;
  height: 3px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

//TABS
.nav-wrapper {
  display: flex;
  margin-top: 30px;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;

  .tabs {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    order: 1;

    @include media-breakpoint-down(lg) {
      margin-bottom: 20px;
      order: 2;
      width: 100%;
      padding: 16px 0;
      overflow: auto;
      flex-wrap: nowrap !important;
    }

    .tab a {
      position: relative;
      display: inline-block;
      min-width: 150px;
      width: auto;
      padding: 5px;
      margin: 0 5px 10px 0;
      text-align: center;
      font-weight: 500;
      font-size: 14px;
      color: rgba(255, 255, 255, .54);
      line-height: 17px;
      letter-spacing: 0.75px;
      text-transform: uppercase;
      border-bottom: 2px solid transparent;

      @include media-breakpoint-down(lg) {
        min-width: 100px !important;
        white-space: nowrap;
      }

      &:after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        height: 2px;
        width: 0;
        background: #E27826;
        transition: 0.3s ease-in-out;
      }

      &:hover {
        cursor: pointer;
        color: #E27826;

        &:after {
          width: 100%;
        }
      }

      &.active {
        color: #E27826;

        &:after {
          width: 100%;
        }
      }

    }
  }

  .blog__search {
    width: 20%;
    order: 2;
    margin-left: auto;

    @include media-breakpoint-down(lg) {
      width: 100%;
      margin-bottom: 20px;
      order: 1;
    }

    .search-wrapper {
      width: 20%;
      margin: 0 0 0 auto;
      display: flex;
      justify-content: flex-end;

      &.active {
        width: 100%;
        margin: 0 0 0 auto;

        .search-icon {
          position: absolute;
          right: 0;
          bottom: 0;
          margin: 0;
          background: linear-gradient(269.72deg, #D96600 0.19%, #F39F33 99.7%);
          width: 38px;
          height: 38px;
          border-radius: 0;
        }

        .close {
          right: -20px;
        }
      }
    }
  }
}


